import { useTranslations } from "@properate/translations";
import { PageHeader } from "@ant-design/pro-layout";
import { useState } from "react";
import { ColumnsType } from "antd/lib/table/interface";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { AccessModules } from "@properate/common";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { CompactContent } from "@/components/CompactContent";
import { SearchInput } from "@/components/SearchIndex";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useAllOrganizations } from "@/pages/organizationAdmin/utils";
import { ExpandedBuildingModules } from "@/pages/organizationAdmin/ExpandedBuildingModules";
import { useOrganizations } from "@/services/organization";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { AccessModuleTags } from "@/pages/organizationAdmin/AccessModuleTags";

export const OrganizationAdmin = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { data, isLoading } = useOrganizations();

  const organizations = useAllOrganizations(data);

  useBuildingPageTitle(t("organization-admin.title"));

  const [search, setSearch] = useState<string>("");
  const searchTerms = search.trim().split(" ");

  const sortedOrganizations = organizations.filter((item) =>
    searchTerms.every((term: string) =>
      (item.name || "").toLowerCase().includes(term.toLowerCase()),
    ),
  );

  const sortModules = (a: AccessModules, b: AccessModules) =>
    t(`organization-admin.access.modules.${a}`).localeCompare(
      t(`organization-admin.access.modules.${b}`),
    );

  const columns: ColumnsType<(typeof sortedOrganizations)[number]> = [
    {
      key: "organization",
      title: t("organization-admin.columns.organization"),
      dataIndex: "name",
      defaultSortOrder: "ascend",
      width: 180,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name: string) => (
        <ProperateHighlighter
          searchWords={searchTerms}
          textToHighlight={name}
          title={name}
        />
      ),
    },
    {
      key: "modules",
      title: t("organization-admin.columns.active-modules"),
      render: ({
        modules,
        customizedModules,
      }: (typeof sortedOrganizations)[number]) => {
        const customModulesWithoutDefault = customizedModules.filter(
          (module) => !modules.includes(module),
        );

        const sortedModules = [
          ...[...modules].sort(sortModules),
          ...[...customModulesWithoutDefault].sort(sortModules),
        ];

        const isAllDeselected = sortedModules.length === 0;

        if (isAllDeselected) {
          return (
            <div className="leading-7 opacity-50">
              {t(`organization-admin.access.modules.none`)}
            </div>
          );
        }

        return (
          <AccessModuleTags
            sortedModules={sortedModules}
            customizedModules={customizedModules}
            modules={modules}
            customizedToolboxText={t(
              `organization-admin.access.some-buildings-customized`,
            )}
          />
        );
      },
    },
    {
      key: "actions",
      title: "",
      width: 80,
      render: (data) => {
        return (
          <div className="flex gap-2">
            <Button
              data-testid={`organization-edit/${data.name}`}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `organization-modules/${encodeURIComponent(data.name)}`,
                );
              }}
            >
              {t("organization-admin.columns.default-access")}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title={t("organization-admin.title")}
        className="site-page-header"
        extra={[
          <SearchInput
            key="search"
            allowClear
            placeholder={t("organization-admin.search")}
            onChange={(event) => setSearch(event.target.value)}
          />,
        ]}
      />
      <CompactContent>
        <TableWithoutDefaultSort
          loading={isLoading}
          pagination={false}
          columns={columns}
          dataSource={sortedOrganizations}
          rowKey="name"
          expandable={{
            expandRowByClick: true,
            expandedRowRender: (organization) => (
              <ExpandedBuildingModules {...organization} />
            ),
            rowExpandable: (organization) => !!organization.buildings.length,
          }}
        />
      </CompactContent>
    </>
  );
};
