import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { useSidebarActions, useSidebarValues } from "./hooks";
import { SidebarActionType } from "./types";

export function SidebarHeader() {
  const sidebarValues = useSidebarValues();
  const dispatch = useSidebarActions();

  return (
    <div className="flex justify-between md:justify-start gap-2 items-center pl-3 pr-5 py-2 z-10 shadow">
      <button
        className={
          "flex-none border-none bg-transparent hover:rounded-full hover:text-primary hover:border-primary hover:border-solid hover:border hover:bg-background-emphasised w-7 h-7 hidden md:flex justify-center items-center"
        }
        onClick={() => dispatch({ type: SidebarActionType.close })}
      >
        <RightOutlined />
      </button>
      {sidebarValues.title}
      <button
        className={
          "flex-none border-none bg-transparent hover:rounded-full hover:bg-gray-200 w-7 h-7 flex md:hidden justify-center items-center"
        }
        onClick={() => dispatch({ type: SidebarActionType.close })}
      >
        <CloseOutlined />
      </button>
    </div>
  );
}
