import { Avatar, Button, Popconfirm } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import * as React from "react";
import { useUser } from "@properate/auth";
import { useLoaderData } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { useTheme } from "styled-components";
import { AlertGroupLoader } from "@/features/alarms";

type Props = {
  onCancel: () => void;
  onDelete: () => void;
  isDeleting: boolean;
  isLoadingDependedData: boolean;
  isUpdating: boolean;
  isLoadingGroups: boolean;
  isShowingUpdateValidationWarning: boolean;
  onShowUpdateModal: () => void;
};

export const useGroupDetailsActionButtons = (props: Props) => {
  const t = useTranslations();
  const user = useUser();
  const pageData = useLoaderData() as AlertGroupLoader;
  const theme = useTheme();

  if (!user.isAdmin) {
    return [];
  }
  return [
    <Button
      key="new"
      onClick={props.onCancel}
      aria-label={t("alert-group.cancel")}
    >
      {t("alert-group.cancel")}
    </Button>,
    ...(pageData.id
      ? [
          <Popconfirm
            key="delete"
            title={t("alert-groups.delete-confirm")}
            onConfirm={props.onDelete}
          >
            <Button
              danger
              ghost
              loading={props.isDeleting}
              aria-label={t("alert-group.delete")}
            >
              {t("alert-group.delete")}
            </Button>
          </Popconfirm>,
          <Button
            key="save"
            ghost
            type="primary"
            aria-label={t("alert-group.save")}
            htmlType="submit"
            form="alert-group"
            disabled={props.isLoadingDependedData}
            loading={
              props.isUpdating ||
              props.isLoadingGroups ||
              props.isLoadingDependedData
            }
          >
            {t("alert-group.save")}
          </Button>,
        ]
      : [
          <Button
            key="create"
            type="primary"
            ghost
            htmlType="submit"
            form="alert-group"
            aria-label={t("alert-group.create")}
            loading={props.isUpdating || props.isLoadingGroups}
          >
            {t("alert-group.create")}
          </Button>,
        ]),
    props.isShowingUpdateValidationWarning && (
      <Avatar
        onClick={props.onShowUpdateModal}
        shape="square"
        style={{
          backgroundColor: theme.warningBg,
          color: theme.warningFg,
        }}
        size="default"
        icon={<WarningOutlined />}
      />
    ),
  ];
};
