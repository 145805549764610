import useSWR from "swr";
import { timeseriesExternalIdsIndex } from "@properate/api";

type ReturnType = {
  data: Record<string, number> | undefined;
  isLoading: boolean;
  error: Error | null;
};

export interface Filter {
  buildingId: number;
  system: string | null;
  subBuilding: string | null;
  translatedLabels: string[];
}

export function buildFilter(filter: string[] | string | null, name: string) {
  if (Array.isArray(filter)) {
    return filter.map((value) => ` AND ${name} = "${value}"`).join("");
  }
  return typeof filter === "string" ? ` AND ${name} = "${filter}"` : "";
}

export function buildFilterQuery(filter: Filter, currentBuildingId: number) {
  return `buildingId = ${currentBuildingId}${buildFilter(
    filter.translatedLabels,
    "translatedLabels",
  )}${buildFilter(filter.system, "system")}${buildFilter(
    filter.subBuilding,
    "subBuilding",
  )}`;
}

export function useFacets(
  facetName: "system" | "translatedLabels" | "subBuilding",
  filter: Filter,
  buildingId: number,
  search?: string,
  facetSearch?: string,
): ReturnType {
  const { data, error, isLoading } = useSWR(
    [facetName, facetSearch, filter, search],
    async ([facetName, facetSearch, filter, search]: [
      string,
      string | undefined,
      Filter,
      string | undefined,
    ]) => {
      const { facetHits: systemHits } =
        await timeseriesExternalIdsIndex.searchForFacetValues({
          facetName: facetName,
          facetQuery: facetSearch,
          filter: buildFilterQuery(filter, buildingId),
          q: search,
        });
      return systemHits.reduce((p, c) => ({ ...p, [c.value]: c.count }), {});
    },
  );

  return {
    data,
    isLoading,
    error,
  };
}
