import { AccessModules } from "@properate/common";

export const allAccessModules: AccessModules[] = [
  AccessModules.enum.alarms,
  AccessModules.enum.incidents,
  AccessModules.enum.energy,
  AccessModules.enum.calendar,
  AccessModules.enum.indoorClimate,
  AccessModules.enum.roomManagement,
  AccessModules.enum.roomManagementV2,
  AccessModules.enum.technicalSchema,
  AccessModules.enum.taskManagement,
  AccessModules.enum.components,
  AccessModules.enum.virtualSensors,
  AccessModules.enum.analysis,
  AccessModules.enum.waste,
  AccessModules.enum.serviceDesk,
  AccessModules.enum.cloudAutomation,
  AccessModules.enum.setPoints,
  AccessModules.enum.files,
  AccessModules.enum.thirdParty,
  AccessModules.enum.timeseries,
];
