import useSWR from "swr";
import { timeseriesIndex } from "@/eepApi";

import { useHandleApiError } from "@/utils/api";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

type Params = {
  buildingId?: number;
};
type SubBuildingList = {
  data: string[];
  isLoading: boolean;
};
export function useSubBuildings(
  { buildingId }: Params,
  enabled?: boolean,
): SubBuildingList {
  const currentBuildingId = useCurrentBuildingId(buildingId !== undefined);
  const handleAPIError = useHandleApiError();
  const {
    data = [],
    error,
    isLoading,
  } = useSWR(
    enabled === false ? null : ["useSubBuildings", buildingId],
    async (params) => {
      const { facetHits: subBuildingsFacets } =
        await timeseriesIndex.searchForFacetValues({
          facetName: "subBuilding",
          filter: `buildingId = "${params[1] || currentBuildingId}"`,
          limit: Number.MAX_SAFE_INTEGER,
        });
      return subBuildingsFacets.map(({ value }) => value);
    },
    {
      keepPreviousData: true,
      revalidateOnFocus: false,
    },
  );

  if (error) {
    handleAPIError(error);
  }
  return {
    data,
    isLoading,
  };
}
