import { useCallback, useEffect, useState } from "react";
import {
  Button,
  App,
  Popconfirm,
  Select,
  Space,
  Upload,
  Row,
  Col,
  Typography,
  Switch,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  ImportOutlined,
  RightOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { ASSET_DESCRIPTIONS, SchemaType, SchemaTypes } from "@properate/common";
import { useUser } from "@properate/auth";
import { ToggleSidebarButton } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import axios from "axios";
import { FileUploadResponse } from "@cognite/sdk";
import { ShowEdit } from "@/components/ShowEdit";
import { CompactContent } from "@/components/CompactContent";
import { SchemaTitle } from "@/components/SchemaTitle";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import SystemFacets from "@/components/SystemFacets/SystemFacets";
import { mutateUserSettings, useUserSettings } from "@/services/userSettings";
import { getImageDimensions } from "@/pages/floorPlan/v2/hooks/useFloorPlanBackgroundUpdate";
import { NextPrevious } from "../NextPrevious/NextPrevious";
import { SchemaKpis } from "../SchemaKpis/SchemaKpis";
import { getSchemaTitle } from "../utils";
import { TechnicalSchema } from "./TechnicalSchema";
import { SENSOR_QUERIES } from "./TechnicalSchema/sensorQueries";
import { StickyPageHeader } from "./elements";

function useLocalState<T>(
  remoteValue: { data: T; isLoading: boolean; error: unknown },
  setRemoteValue: (value: T) => Promise<void>,
) {
  const [localValue, setLocalValue] = useState<T>();

  useEffect(() => {
    if (!remoteValue.isLoading && !remoteValue.error) {
      setLocalValue(remoteValue.data);
    }
  }, [remoteValue.data, remoteValue.error, remoteValue.isLoading]);

  const setValue = useCallback(
    async (value: T) => {
      const previousValue = localValue;
      setLocalValue(value);
      try {
        await setRemoteValue(value);
      } catch (error) {
        setLocalValue(previousValue);
        throw error;
      }
    },
    [setRemoteValue, localValue],
  );

  return [localValue, setValue] as const;
}

export function getTextFile(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const processImage = (text: string) => {
  const parseXml = new window.DOMParser().parseFromString(
    text,
    "image/svg+xml",
  );

  const bg = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
    ...Array.from(parseXml.getElementsByTagName("circle")).filter(
      (el) => el.getAttribute("fill") === "#182334",
    ),
  ];

  bg.forEach((el) => el.classList.add("bg"));

  const bg1 = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#161E2D",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#161E2D",
    ),
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#111928",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#111928",
    ),
  ];

  bg1.forEach((el) => el.classList.add("bg1"));

  const bg2 = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#434E5C",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#434E5C",
    ),
  ];

  bg2.forEach((el) => el.classList.add("bg2"));

  const fg = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "white",
    ),
    ...Array.from(parseXml.getElementsByTagName("text")).filter(
      (el) => el.getAttribute("fill") === "white",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "white",
    ),
  ];

  fg.forEach((el) => el.classList.add("fg"));

  const fg1 = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("circle")).filter(
      (el) => el.getAttribute("fill") === "#DADEE3",
    ),
  ];

  fg1.forEach((el) => el.classList.add("fg1"));

  const stroke = [
    ...Array.from(parseXml.getElementsByTagName("line")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("stroke") === "#858C94",
    ),
  ];

  stroke.forEach((el) => el.classList.add("fgStroke"));

  const stroke1 = [
    ...Array.from(parseXml.getElementsByTagName("line")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("path")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("ellipse")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
    ...Array.from(parseXml.getElementsByTagName("circle")).filter(
      (el) => el.getAttribute("stroke") === "#DADEE3",
    ),
  ];

  stroke1.forEach((el) => el.classList.add("fgStroke1"));

  // settings
  const settings = [
    ...Array.from(parseXml.getElementsByTagName("rect")).filter(
      (el) => el.getAttribute("fill") === "#293647",
    ),
  ];

  settings.forEach((el) => el.classList.add("bgSettings"));

  Object.keys(SENSOR_QUERIES).forEach((key) => {
    const dem = Array.from(parseXml.getElementsByTagName("g")).filter((g) =>
      g.id.startsWith(key),
    );
    dem.forEach((el) => {
      el.dataset.type = key;

      const status = Array.from(el.getElementsByTagName("g")).filter((g) =>
        g.id.startsWith("add-"),
      );
      if (status.length > 0) {
        el.classList.add("item");

        let pos = "top";
        if (status[0].id.startsWith("add-right")) {
          pos = "right";
        } else if (status[0].id.startsWith("add-left")) {
          pos = "left";
        } else if (
          status[0].id.startsWith("add-down") ||
          status[0].id.startsWith("add-under")
        ) {
          pos = "bottom";
        }

        el.dataset.position = pos;
        el.classList.add(pos);
      }
      status.forEach((el) => {
        el.classList.add("status");
        if (key.startsWith("set-point-")) {
          el.classList.add("setPoint");
        }
      });
    });
  });

  return parseXml.documentElement;
};

type Props = {
  title: string;
  type: SchemaTypes;
  id: string;
  fileId: string;
  view: string;
  buildings: string[];
  availableSensors?: JSX.Element[];
  image?: HTMLElement;
  setImage: (image: HTMLElement) => void;
  schema: SchemaType;
  update: (schema: Partial<SchemaType>) => Promise<SchemaType>;
  archive: () => Promise<void>;
};

export const SchemaView = ({
  title,
  type,
  id,
  fileId,
  view,
  buildings,
  availableSensors,
  image,
  setImage,
  schema,
  update,
  archive,
}: Props) => {
  const { message } = App.useApp();
  const t = useTranslations();
  const { client } = useCogniteClient();
  const currentBuilding = useCurrentBuilding();
  const navigate = useNavigate();
  const user = useUser();
  const userSettings = useUserSettings();

  const [disableZoom = false, setDisableZoom] = useLocalState(
    {
      data: userSettings.data?.panZoom?.disableZoom,
      isLoading: userSettings.isLoading,
      error: userSettings.error,
    },
    (nextDisableZoom) =>
      mutateUserSettings({
        panZoom: {
          disableZoom: nextDisableZoom,
        },
      }),
  );

  const updateSystem = useCallback(
    async (selectedSystem: string | undefined) => {
      const system =
        selectedSystem && selectedSystem !== "-1" ? selectedSystem : undefined;
      await update({ system });
    },
    [update],
  );

  useEffect(() => {
    if (image && schema?.sensors) {
      const newImage = image.cloneNode(true) as HTMLElement;

      let changed = false;
      Object.keys(schema.sensors).forEach((key) => {
        const item = newImage.querySelector("#" + key) as SVGGraphicsElement;
        if (item && !item.classList.contains("value")) {
          changed = true;
          item.classList.add("value");
        }
      });
      newImage.querySelectorAll(".value").forEach((value) => {
        if (!schema.sensors[value.id]) {
          changed = true;
          value.classList.remove("value");
        }
      });

      if (changed) {
        setImage(newImage);
      }
    }
  }, [image, schema?.sensors, setImage]);

  const archiveFile = async () => {
    await archive();
    navigate(`/asset/${id}/${type}`);
  };

  function getSelectedSystemAndAssetDescription() {
    const { system } = schema;
    if (system) {
      const assetDescription = ASSET_DESCRIPTIONS[parseInt(system)];

      return assetDescription ? `${system} ${assetDescription}` : system;
    }
    return t("floor-plan.toolbar.select-all-systems-label");
  }

  return (
    <>
      <StickyPageHeader
        title={
          <Space>
            <Link to={`/asset/${id}/${type}`}>{title}</Link>
            <RightOutlined />
            {!user.isViewer && view === "edit" ? (
              <ShowEdit
                text={schema?.name}
                onFinish={async (text) => {
                  await update({
                    name: text,
                  });
                }}
              />
            ) : (
              <SchemaTitle>{getSchemaTitle(schema)}</SchemaTitle>
            )}
          </Space>
        }
        extra={
          <Space direction="vertical">
            <div className="flex">
              <Typography.Text className="mr-2">
                {t("floor-plan.toolbar.system-label")}:{" "}
                {getSelectedSystemAndAssetDescription()}
              </Typography.Text>
              {view === "edit" ? (
                <Space>
                  <Select
                    value={schema?.subBuilding}
                    style={{ width: "100px" }}
                    onSelect={async (val: string) => {
                      await update({
                        subBuilding: val,
                      });
                    }}
                  >
                    {buildings &&
                      buildings.map((building) => (
                        <Select.Option value={building} key={building}>
                          {building}
                        </Select.Option>
                      ))}
                  </Select>
                  <SystemFacets
                    onChangeSystemFilter={async (value) => {
                      try {
                        if (value) {
                          await updateSystem(value);
                        }
                      } catch (error) {
                        console.error("Failed to update system:", error);
                      }
                    }}
                    translatedLabelsFilter={[]}
                    subBuildingFilter={null}
                  />
                  {schema?.cogniteFileId && (
                    <Upload
                      accept="image/*"
                      showUploadList={false}
                      onChange={async (info: any) => {
                        if (info.file.status === "done") {
                          message.destroy();
                          await update({
                            ...schema,
                            cogniteFileId: info.file.response,
                          });
                        } else if (info.file.status === "error") {
                          message.destroy();
                          message.error(
                            `Klarte ikke å laste opp ${info.file.name}.`,
                          );
                        }
                      }}
                      multiple={false}
                      customRequest={async (fileInfo: any) => {
                        const fileName = fileInfo.file.name;
                        message.loading({
                          content: t("floor-plan.toolbar.uploading-schema", {
                            fileName,
                          }),
                          key: fileInfo.file.name,
                        });
                        try {
                          const fileUploadResponse = await client.files.upload({
                            name: fileInfo.file.name,
                            dataSetId: currentBuilding.dataSetId,
                            assetIds: [parseInt(id)],
                            mimeType: fileInfo.file.type,
                            labels: [
                              {
                                externalId: "internal_schema_background",
                              },
                            ],
                          });

                          await axios.put(
                            (fileUploadResponse as FileUploadResponse)
                              .uploadUrl,
                            fileInfo.file,
                          );

                          fileInfo.onSuccess(fileUploadResponse.id);
                        } catch (error: any) {
                          fileInfo.onError(error);
                        }
                      }}
                    >
                      <Button icon={<ImportOutlined />}>
                        {t("floor-plan.toolbar.upload")}
                      </Button>
                    </Upload>
                  )}
                  {!schema?.cogniteFileId && (
                    <Upload
                      showUploadList={false}
                      beforeUpload={(file) => {
                        const isSvg = file.type === "image/svg+xml";
                        if (!isSvg) {
                          message.error(
                            t("floor-plan.toolbar.error-upload-only-svg-files"),
                          );
                          return false;
                        }
                        return true;
                      }}
                      onChange={async (info: any) => {
                        if (info.file.status === "done") {
                          const fileName = info.file.name;
                          message.success(
                            t("floor-plan.toolbar.file-uploaded-successfully", {
                              fileName,
                            }),
                          );
                        } else if (info.file.status === "error") {
                          const fileName = info.file.name;
                          message.error(
                            t("floor-plan.toolbar.error-uploading-file", {
                              fileName,
                            }),
                          );
                        }
                      }}
                      multiple={false}
                      customRequest={async (fileInfo: any) => {
                        const fileName = fileInfo.file.name;
                        message.loading({
                          content: t("floor-plan.toolbar.uploading-schema", {
                            fileName,
                          }),
                          key: fileInfo.file.name,
                        });

                        const previousFileId = schema.background?.cogniteFileId;

                        try {
                          const fileUploadResponse = await client.files.upload({
                            name: fileInfo.file.name,
                            dataSetId: currentBuilding.dataSetId,
                            assetIds: [currentBuilding.id],
                            mimeType: fileInfo.file.type,
                            labels: [
                              {
                                externalId: "internal_schema_background",
                              },
                            ],
                          });

                          await axios.put(
                            (fileUploadResponse as FileUploadResponse)
                              .uploadUrl,
                            fileInfo.file,
                          );

                          const { width, height } = await getImageDimensions(
                            fileInfo.file,
                          );

                          await update({
                            image: "",
                            background: {
                              cogniteFileId: fileUploadResponse.id,
                              mimeType: fileInfo.file.type,
                              width,
                              height,
                            },
                          });

                          try {
                            if (previousFileId) {
                              await client.files.delete([
                                {
                                  id: previousFileId,
                                },
                              ]);
                            }
                          } catch {
                            // noop
                          }
                        } catch (error: unknown) {
                          console.error(error);
                          message.error(
                            t("floor-plan.toolbar.error-uploading-schema"),
                          );
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        {t("floor-plan.toolbar.upload")}
                      </Button>
                    </Upload>
                  )}
                  <Popconfirm
                    placement="top"
                    title={t("floor-plan.toolbar.popconfirm-delete-label")}
                    onConfirm={archiveFile}
                    okText={t("floor-plan.toolbar.popconfirm-on-ok")}
                    cancelText={t("floor-plan.toolbar.popconfirm-on-cancel")}
                  >
                    <Button
                      type="primary"
                      icon={<DeleteOutlined />}
                      danger
                      style={{
                        marginRight: "0px",
                        backgroundColor: "#db0657",
                      }}
                    >
                      {t("floor-plan.toolbar.delete-button-label")}
                    </Button>
                  </Popconfirm>
                  <Button
                    onClick={() => {
                      navigate(`/asset/${id}/${type}/${fileId}/view`);
                    }}
                  >
                    {t("floor-plan.toolbar.save-button-label")}
                  </Button>
                </Space>
              ) : (
                <Button
                  style={{ marginLeft: "auto" }}
                  disabled={user.isViewer}
                  onClick={() => {
                    navigate(`/asset/${id}/${type}/${fileId}/edit`);
                  }}
                >
                  {t("floor-plan.toolbar.edit-button-label")}
                </Button>
              )}
              <ToggleSidebarButton
                hiddenWhenSidebarVisible
                className="ml-2"
                sidebarHiddenContent={t("notes.show-notes-button")}
              />
            </div>
          </Space>
        }
      >
        <Row justify="space-between">
          <Col>{view === "edit" && <div>{availableSensors}</div>}</Col>
          <Col>
            <label className="inline-flex gap-2 mr-4">
              {t("pan-zoom-area.disable-zoom")}
              <Switch
                checked={disableZoom}
                onChange={(checked) => setDisableZoom(checked)}
              />
            </label>
            {schema && (
              <NextPrevious
                buildingId={schema.rootAssetId}
                id={fileId}
                type={type}
              />
            )}
          </Col>
        </Row>
      </StickyPageHeader>
      <CompactContent>
        {schema && (
          <SchemaKpis
            snapshotId={fileId}
            type={type}
            schema={schema}
            update={update}
          />
        )}
        {schema && image && (
          <TechnicalSchema
            image={image}
            edit={view === "edit"}
            snapshotId={fileId}
            disableZoom={disableZoom}
            selectedBuilding={schema.subBuilding ?? buildings[0]}
            selectedSystem={schema.system}
            technicalSchema={schema}
            onChangeTechnicalSchema={(data) => update({ ...schema, ...data })}
            type={type}
          />
        )}
      </CompactContent>
    </>
  );
};
