import { useEffect, useMemo, useState } from "react";
import { Step } from "react-joyride";
import Color from "color";
import { TimeSpan } from "@properate/common";
import { Timeseries } from "@cognite/sdk";
import { useNavigate, useParams, useSubmit } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useOnboarding } from "@/utils/onboarding";
import { useHelp } from "@/context/HelpContext";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import {
  Analysis,
  AnalysisHeader,
  useRemoveSettingsTimeseriesHiddenRootAsset,
  AnalysisContent,
  NO_UNIT,
  useGetImmutableTimeSpan,
  isScatterplotAnalysisWithSettingsTimeseriesX,
  isScatterplotAnalysis,
  useAnalysisTypeRoute,
  SelectedAnalysisPoints,
} from "@/features/analysis";
import { deleteAnalysis } from "@/services/analysis";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { COLOR_PALETTE } from "@/utils/ProperateColors";
import { useActionOrLoaderData } from "@/hooks/useActionOrLoaderData";
import {
  removeWidget,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { useRootAssets } from "@/hooks/useRootAssets";
import { TranslationFunction } from "@/utils/types";
function getRandomAvailableThemeColor(usedColors: string[]) {
  const availableColors = COLOR_PALETTE.flatMap(({ code }) => [
    code,
    Color(code).darken(0.1).hex(),
    Color(code).darken(0.2).hex(),
  ]).filter((color) => !usedColors.includes(color));
  return availableColors[Math.floor(Math.random() * availableColors.length)];
}

const getSteps = (t: TranslationFunction): Step[] => [
  {
    placement: "top",
    target: ".selectMeasurementType",
    content: t("analysis.joyride.select-type-measurements"),
  },
  {
    placement: "top",
    target: ".analysis-share",
    content: t("analysis.joyride.press-to-share"),
  },
];

export function PageAnalysisDetails() {
  const t = useTranslations();

  useBuildingPageTitle(t("analysis.details.title"));
  const analysis = useActionOrLoaderData<Analysis>();
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();
  const submit = useSubmit();
  const navigate = useNavigate();
  const currentBuildingId = useCurrentBuildingId();
  const { snapshotId } = useParams() as {
    snapshotId: string;
  };
  const rootAssets = useRootAssets();

  const { overrideUnits } = useTimeseriesSettings(currentBuildingId);
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(
    getSteps(t),
  );
  const { setHelp } = useHelp();

  const settingsTimeseriesXId = isScatterplotAnalysis(analysis)
    ? analysis?.settingsTimeseriesX?.id ?? null
    : null;

  const timeseriesIds = [
    ...(settingsTimeseriesXId !== null ? [settingsTimeseriesXId] : []),
    ...analysis.settingsTimeseriesList.map(({ id }) => id),
  ] as number[];

  const { timeseriesList } = useGetTimeseriesList(timeseriesIds) || {};

  const [selectedPoints, setSelectedPoints] =
    useState<SelectedAnalysisPoints>(null);

  const selectedBuildings = useMemo(
    () =>
      rootAssets.filter((building) =>
        timeseriesList.some(
          (timeseries) => timeseries.dataSetId === building.dataSetId,
        ),
      ),
    [rootAssets, timeseriesList],
  );
  const analysisWithOverrideUnits = useMemo(() => {
    if (!overrideUnits) {
      return analysis;
    }

    function getTimeseries(id: number) {
      return timeseriesList?.find((timeseries) => timeseries.id === id);
    }
    const timeseriesX = isScatterplotAnalysisWithSettingsTimeseriesX(analysis)
      ? getTimeseries(analysis.settingsTimeseriesX.id)
      : null;

    return {
      ...analysis,
      settingsTimeseriesList: analysis.settingsTimeseriesList.map(
        (settingsTimeseries) => {
          const timeseries = getTimeseries(settingsTimeseries.id);
          return {
            ...settingsTimeseries,
            unitSelected:
              (timeseries &&
                overrideUnits &&
                overrideUnits[timeseries.externalId!]?.unit) ||
              timeseries?.unit ||
              NO_UNIT,
          };
        },
      ),
      ...(isScatterplotAnalysisWithSettingsTimeseriesX(analysis)
        ? {
            settingsTimeseriesX: {
              ...analysis.settingsTimeseriesX,
              unitSelected:
                (timeseriesX &&
                  overrideUnits &&
                  overrideUnits[timeseriesX.externalId!]?.unit) ||
                timeseriesX?.unit,
            },
          }
        : null),
    };
  }, [analysis, overrideUnits, timeseriesList]);

  const settingsTimeseriesList = useRemoveSettingsTimeseriesHiddenRootAsset(
    analysisWithOverrideUnits.settingsTimeseriesList,
  );
  const timeSpan = useGetImmutableTimeSpan(settingsTimeseriesList);
  const [zoomedTimeSpan, setZoomedTimeSpan] = useState(timeSpan);
  const [timeseriesSelectionModalStatus, setTimeseriesSelectionModalStatus] =
    useState<"closed" | "openForTimeseriesYAxis" | "openForTimeseriesXAxis">(
      "closed",
    );
  const type = useAnalysisTypeRoute();

  function getSelectedIds() {
    if (timeseriesSelectionModalStatus === "openForTimeseriesXAxis") {
      return isScatterplotAnalysisWithSettingsTimeseriesX(analysis)
        ? [analysis.settingsTimeseriesX.id]
        : [];
    }
    return timeseriesSelectionModalStatus === "openForTimeseriesYAxis"
      ? analysis.settingsTimeseriesList.map(({ id }) => id)
      : [];
  }

  async function handleDeleteAnalysis() {
    const widgets = userSettingsForBuilding?.dashboard?.widgets;
    if (widgets) {
      removeWidget(currentBuildingId, widgets, snapshotId);
    }
    await deleteAnalysis(snapshotId);
    navigate(`/asset/${currentBuildingId}/analysis/${type}`);
  }

  function handleChangeZoomedTimeSpan(zoomedTimeSpan: TimeSpan) {
    setZoomedTimeSpan(zoomedTimeSpan);
  }

  async function handleChangeAnalysis(update: Partial<Analysis>) {
    submit(
      {
        snapshotId,
        updatedDoc: JSON.stringify({
          ...analysis,
          ...update,
        }),
      },
      {
        method: "post",
      },
    );
  }

  function handleOk(timeseriesList: Timeseries[]) {
    if (timeseriesSelectionModalStatus === "openForTimeseriesXAxis") {
      const [timeseries] = timeseriesList;
      return handleChangeAnalysis({
        settingsTimeseriesX: {
          id: timeseries.id,
          color: getRandomAvailableThemeColor(
            analysis.settingsTimeseriesList.map(
              (settingsTimeseries) => settingsTimeseries.color,
            ),
          ),
          aggregate: "average",
          unitSelected:
            (timeseries &&
              overrideUnits &&
              overrideUnits[timeseries.externalId!]?.unit) ||
            timeseries.unit ||
            NO_UNIT,
          valueLimit: undefined,
        },
      });
    }
    const existingSettingsTimeseriesList =
      analysisWithOverrideUnits.settingsTimeseriesList.filter(
        (settingsTimeseries) =>
          timeseriesList.some(({ id }) => id === settingsTimeseries.id),
      );
    const newTimeseriesList = timeseriesList.filter((timeseries) =>
      existingSettingsTimeseriesList.every(({ id }) => id !== timeseries.id),
    );
    return handleChangeAnalysis({
      settingsTimeseriesList: existingSettingsTimeseriesList.concat(
        newTimeseriesList.map((timeseries) => ({
          id: timeseries.id,
          color: getRandomAvailableThemeColor(
            existingSettingsTimeseriesList
              .map(({ color }) => color)
              .concat(
                ...(isScatterplotAnalysisWithSettingsTimeseriesX(
                  analysisWithOverrideUnits,
                )
                  ? [analysisWithOverrideUnits.settingsTimeseriesX.color]
                  : []),
              ),
          ),
          hidden: false,
          unitSelected: timeseries.unit || NO_UNIT,
          ...(isScatterplotAnalysis(analysis)
            ? {
                aggregate: "average",
              }
            : null),
        })),
      ),
    });
  }

  useEffect(() => {
    setHelp({
      title: t("analysis.details.title"),
      content: <p>{t("analysis.details.help")}</p>,
    });
  }, [setHelp, t]);

  return (
    <>
      <AnalysisHeader
        analysis={analysisWithOverrideUnits}
        buildingId={currentBuildingId}
        timeSpan={timeSpan}
        zoomedTimeSpan={zoomedTimeSpan}
        onConfirmDeletion={handleDeleteAnalysis}
        onChangeZoomedTimeSpan={handleChangeZoomedTimeSpan}
        onChangeAnalysis={handleChangeAnalysis}
        selectedPoints={selectedPoints}
        setSelectedPoints={setSelectedPoints}
      />
      <AnalysisContent
        analysis={analysisWithOverrideUnits}
        timeSpan={timeSpan}
        zoomedTimeSpan={zoomedTimeSpan}
        onChangeAnalysis={handleChangeAnalysis}
        settingsTimeseriesList={settingsTimeseriesList}
        onClickAddTimeseriesY={() =>
          setTimeseriesSelectionModalStatus("openForTimeseriesYAxis")
        }
        onClickChangeTimeseriesX={() => {
          setTimeseriesSelectionModalStatus("openForTimeseriesXAxis");
        }}
        onChangeZoomedTimeSpan={handleChangeZoomedTimeSpan}
        settingsTimeseriesXId={settingsTimeseriesXId}
        selectedBuildings={selectedBuildings}
        selectedPoints={selectedPoints}
        setSelectedPoints={setSelectedPoints}
      />
      <TimeseriesSelectionModal
        open={timeseriesSelectionModalStatus !== "closed"}
        onHide={() => setTimeseriesSelectionModalStatus("closed")}
        selectedIds={getSelectedIds()}
        onOk={handleOk}
        disableClear={
          timeseriesSelectionModalStatus === "openForTimeseriesXAxis"
        }
        max={
          timeseriesSelectionModalStatus === "openForTimeseriesXAxis"
            ? 1
            : undefined
        }
        {...(isScatterplotAnalysisWithSettingsTimeseriesX(
          analysisWithOverrideUnits,
        ) && timeseriesSelectionModalStatus === "openForTimeseriesYAxis"
          ? { disabledIds: [analysisWithOverrideUnits.settingsTimeseriesX.id] }
          : null)}
      />
      {onboardingSteps && onboardingSteps.length > 0 && (
        <JoyrideWrapper
          content={onboardingSteps[0]}
          onClose={setOnboardingStepCompleted}
        />
      )}
    </>
  );
}
