import { AlertGroupClientSide } from "@properate/common";
import { parsePhoneNumber } from "libphonenumber-js";
import { z } from "zod";

export const AlertGroupMemberUI = z.object({
  id: z.string(),
  defaultUserEmail: z.string(),
  notifyByEmail: z.boolean(),
  notifyByPhone: z.boolean(),
  notificationEmail: z.string(),
  notificationPhone: z.string(),
  name: z.string(),
});

export type AlertGroupMemberUI = z.infer<typeof AlertGroupMemberUI>;

const alertGroupTransformer = (input: AlertGroupClientSide) => ({
  ...input,
  id: input.id || "",
  buildingExternalId: input.building_external_id,
  members: input.members.map((member) => ({
    id: member.properate_user_email,
    defaultUserEmail: member.properate_user_email,
    notifyByEmail: member.notify_by_email,
    notifyByPhone: member.notify_by_phone,
    notificationEmail: member.email || "",
    notificationPhone: member.phone
      ? parsePhoneNumber(member.phone).formatInternational()
      : "",
    name: member.name || "",
  })),
});

export const AlertGroupUI = AlertGroupClientSide.transform(
  alertGroupTransformer,
);

export type AlertGroupUI = z.infer<typeof AlertGroupUI>;

export const AlertGroupAPI = z
  .object({
    buildingExternalId: z.string().nullable(),
    owner: z.string().optional(),
    id: z.string().optional(),
    members: z
      .object({
        id: z.string(),
        defaultUserEmail: z.string(),
        name: z.string().optional(),
        notifyByEmail: z.boolean(),
        notifyByPhone: z.boolean(),
        notificationEmail: z.string(),
        notificationPhone: z.string(),
      })
      .array(),
    name: z.string(),
  })
  .transform((input) => ({
    ...(input.id && { id: input.id }),
    building_external_id: input.buildingExternalId,
    owner: input.owner || undefined,
    name: input.name,
    members: input.members.map((member) => ({
      properate_user_email: member.defaultUserEmail,
      notify_by_email: member.notifyByEmail,
      notify_by_phone: member.notifyByPhone,
      email: member.notificationEmail || undefined,
      name: member.name || undefined,
      phone: member.notificationPhone
        ? (parsePhoneNumber(member.notificationPhone).number as string)
        : undefined,
    })),
  }));

export type AlertGroupAPI = z.infer<typeof AlertGroupAPI>;
