import useSWR from "swr";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { useRootAssets } from "@/hooks/useRootAssets";
import { getOrganizationModules } from "@/eepApi";
import { allAccessModules } from "@/features/organizationAdmin";

export const useBuildingModulesAccess = () => {
  const currentBuilding = useCurrentBuilding();
  const { data, isLoading, error } = useSWR(
    ["modules", currentBuilding.externalId],
    () => getOrganizationModules(currentBuilding.externalId!),
    { revalidateOnFocus: false },
  );

  if (error) {
    console.error(error);
  }

  return {
    isLoading: isLoading,
    accessModules: data || [],
    error,
  };
};
export const useOrganizationModulesAccess = () => {
  const rootAssets = useRootAssets();
  const organizations = Object.keys(
    Object.groupBy(rootAssets, (item) => item.metadata?.owner || ""),
  );

  const { data, isLoading, error } = useSWR(
    ["allModules", ...organizations],
    async () => {
      if (organizations.length === 0) return [];
      if (organizations.length === 1) {
        return getOrganizationModules(organizations[0]);
      }
      return allAccessModules;
    },
    { revalidateOnFocus: false },
  );

  if (error) {
    console.error(error);
  }
  return {
    accessModules: data || [],
    isLoading,
    error,
  };
};
