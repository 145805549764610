import { useUser } from "@properate/auth";
import { Node, useReactFlow } from "reactflow";
import { Select, Form, InputNumber } from "antd";
import dayjs from "@properate/dayjs";
import { TimeSpan } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { RangePicker } from "@/components/RangePicker/RangePicker";
import {
  getNodeId,
  updateReactFlowNodeData,
  updateReactFlowNodeDataPartial,
} from "./helpers/Utils";
import {
  LargeNode,
  NodeOutput,
  InputHeader,
  Body,
  NodeOutputLabel,
} from "./helpers/NodeComponents";

const { Option } = Select;
const FormItem = Form.Item;

const signalTypes = ["square", "linear"] as const; // Activate other types once implemented in backend ["square", "triangle", "sawtooth"];
const frequencyUnitOptions = ["seconds", "minutes", "hours", "days"] as const;

interface SignalGeneratorNodeProps {
  operationId: string;
  signalStart: string;
  signalEnd: string;
  signalType: string;
  signalFrequency: number;
  signalFrequencyUnit: string;
}

export const getEmptySignalGeneratorNode =
  (): Node<SignalGeneratorNodeProps> => {
    const start = dayjs().subtract(7, "days").toISOString();
    const end = dayjs().toISOString();
    return {
      id: getNodeId("signalGenerator"),
      type: "signalGenerator",
      data: {
        operationId: "signal_generator",
        signalStart: start,
        signalEnd: end,
        signalType: "square",
        signalFrequency: 1,
        signalFrequencyUnit: "days",
      },
      position: {
        x: 0,
        y: 0,
      },
    };
  };

function SignalGeneratorNode(params: {
  id: string;
  data: SignalGeneratorNodeProps;
}) {
  const t = useTranslations();
  const reactFlowInstance = useReactFlow();
  const user = useUser();

  const handleChangeSignalType = (signalType: string) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "signalType",
      signalType,
    );
  };

  const handleSignalRangeChange = (values: TimeSpan) => {
    updateReactFlowNodeDataPartial(reactFlowInstance, params.id, {
      signalStart: dayjs(values[0]).toISOString(),
      signalEnd: dayjs(values[1]).toISOString(),
    });
  };

  const handleSignalFrequencyChange = (signalFrequency: number | null) => {
    if (signalFrequency)
      updateReactFlowNodeData(
        reactFlowInstance,
        params.id,
        "signalFrequency",
        signalFrequency,
      );
  };

  const handleSignalFrequencyUnitChange = (signalFrequencyUnit: string) => {
    updateReactFlowNodeData(
      reactFlowInstance,
      params.id,
      "signalFrequencyUnit",
      signalFrequencyUnit,
    );
  };

  return (
    <LargeNode>
      <InputHeader>
        {t("calculation-flow.node-types.signal-generator")}
      </InputHeader>
      <Body>
        <NodeOutput>
          <NodeOutputLabel style={{ width: "100%" }}>
            <Form layout="vertical" disabled={user.isViewer}>
              <FormItem label={t("calculation-flow.node-types.signal-type")}>
                <Select
                  showAction={["focus", "click"]}
                  value={params.data.signalType}
                  onChange={handleChangeSignalType}
                >
                  {signalTypes.map((size) => (
                    <Option key={size} value={size}>
                      {t(`calculation-flow.signal-type.${size}`)}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem label={t("calculation-flow.node-types.time-period")}>
                <RangePicker
                  withTime
                  onChange={handleSignalRangeChange}
                  value={[
                    dayjs(params.data.signalStart).valueOf(),
                    dayjs(params.data.signalEnd).valueOf(),
                  ]}
                />
              </FormItem>
              {params.data.signalType !== "linear" && (
                <FormItem label={t("calculation-flow.node-types.frequency")}>
                  <InputNumber
                    value={params.data.signalFrequency}
                    min={1}
                    onChange={handleSignalFrequencyChange}
                    controls={false}
                    decimalSeparator=","
                  />
                  <Select
                    showAction={["focus", "click"]}
                    value={params.data.signalFrequencyUnit}
                    style={{ width: "30%" }}
                    onChange={handleSignalFrequencyUnitChange}
                  >
                    {frequencyUnitOptions.map((unit) => (
                      <Option key={unit} value={unit}>
                        {t(`calculation-flow.time-units.${unit}`)}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              )}
            </Form>
          </NodeOutputLabel>
        </NodeOutput>
      </Body>
    </LargeNode>
  );
}

export default SignalGeneratorNode;
