import useSWR from "swr";
import { Button, Spin, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { CogniteClient } from "@cognite/sdk";
import { BuildingSpec } from "@properate/common";
import { sortNumber, sortString } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { getTimeseriesWithLabels } from "@/utils/helpers";
import { mutateBuildingSpec } from "@/services/buildingSpec";
import IndoorClimateSettings, {
  getSubBuildingAndSystemCode,
  IntakeOuttakeType,
} from "@/components/Dashboard/TechnicalFacilityKeyFigures/IndoorClimateSettings";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useWindowSize } from "@/hooks/useWindowSize";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { WhiteModal } from "@/components/MeterSelectionModal/elements";
import { WidgetHeader } from "../widget-components/WidgetHeader";
import { DraggableCard } from "../draggable/DraggableCard";

interface Props {
  id: number;
  width: number;
  height: number;
  spec?: BuildingSpec;
  onClickRemoveButton: () => unknown;
}

export function IndoorClimateWidget({
  id,
  width,
  height,
  spec,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();
  const { client } = useCogniteClient();

  const [intakeSeries, setIntakeSeries] = useState<IntakeOuttakeType[]>([]);
  const [outtakeSeries, setOuttakeSeries] = useState<IntakeOuttakeType[]>([]);
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    const load = async (client: CogniteClient) => {
      const intakeSeriesList = spec?.indoorClimate?.intake || [];
      const outtakeSeriesList = spec?.indoorClimate?.outtake || [];

      if (intakeSeriesList && intakeSeriesList.length > 0) {
        setIntakeSeries(intakeSeriesList);
      } else {
        const it = await getTimeseriesWithLabels(client, id, [
          "real_value",
          "temperature",
          "supply",
        ]);
        const intake = it?.map((ts) => ({
          id: ts.id,
          externalId: ts.externalId,
          name: ts?.name,
          description: ts?.description,
          intakeOuttake: "intake",
        }));
        const intakeSeriesFilteredRT401 = intake?.filter(
          (ts) =>
            ts.externalId!.includes("=360.") &&
            ts.externalId!.endsWith("RT401") &&
            !ts.externalId!.includes("OE901-RT401") &&
            !ts.externalId!.includes("OE902-RT401"),
        );
        const intakeSeriesFilteredRT400 = intake?.filter(
          (ts) =>
            ts.externalId!.includes("=360.") &&
            ts.externalId!.endsWith("RT400"),
        );
        const intakeSeriesFiltered =
          intakeSeriesFilteredRT401 && intakeSeriesFilteredRT401.length > 0
            ? intakeSeriesFilteredRT401
            : intakeSeriesFilteredRT400;

        setIntakeSeries(intakeSeriesFiltered);
      }

      if (outtakeSeriesList && outtakeSeriesList.length > 0) {
        setOuttakeSeries(outtakeSeriesList);
      } else {
        const ot = await getTimeseriesWithLabels(client, id, [
          "real_value",
          "temperature",
          "extract",
        ]);
        const outtake = ot?.map((ts) => ({
          id: ts.id,
          externalId: ts.externalId,
          name: ts?.name,
          description: ts?.description,
          intakeOuttake: "outtake",
        }));
        const outtakeSeriesFilteredRT501 = outtake?.filter(
          (ts) =>
            ts.externalId!.includes("=360.") &&
            ts.externalId!.endsWith("RT501") &&
            !ts.externalId!.includes("OE901-RT501") &&
            !ts.externalId!.includes("OE902-RT501"),
        );
        const outtakeSeriesFilteredRT500 = outtake?.filter(
          (ts) =>
            ts.externalId!.includes("=360.") &&
            ts.externalId!.endsWith("RT500"),
        );

        const outtakeSeriesFiltered =
          outtakeSeriesFilteredRT501 && outtakeSeriesFilteredRT501.length > 0
            ? outtakeSeriesFilteredRT501
            : outtakeSeriesFilteredRT500;
        setOuttakeSeries(outtakeSeriesFiltered);
      }
      setLoading(false);
    };
    if (client) {
      load(client);
    }
  }, [client, id, spec, loading]);

  const intakeOuttakeObj =
    intakeSeries &&
    outtakeSeries &&
    [...(intakeSeries || []), ...(outtakeSeries || [])].reduce(
      (prev: any, current: any): Record<string, any> => {
        const subBuildingAndSystemCode = getSubBuildingAndSystemCode(
          current.externalId!,
        );
        return {
          ...prev,
          [subBuildingAndSystemCode]:
            current.intakeOuttake === "intake"
              ? { ...prev[subBuildingAndSystemCode], intake: current }
              : {
                  ...prev[subBuildingAndSystemCode],
                  outtake: current,
                },
        };
      },
      {} as Record<string, any>,
    );

  const { data: intake } = useSWR(
    (intakeSeries &&
      intakeSeries
        .map((ts) => ts.id)
        .sort()
        .join(",")) ||
      null,
    async (params) => {
      const latest = await client.datapoints.retrieveLatest(
        params.split(",").map((ts: string) => ({ id: Number(ts) })),
      );
      return latest.reduce(
        (prev: any, current: any): Record<string, any> => {
          const subBuildingAndSystemCode = getSubBuildingAndSystemCode(
            current.externalId!,
          );
          return { ...prev, [subBuildingAndSystemCode]: current.datapoints[0] };
        },
        {} as Record<string, any>,
      );
    },
    {
      dedupingInterval: 60000,
      focusThrottleInterval: 60000,
    },
  );

  const { data: outtake } = useSWR(
    (outtakeSeries &&
      outtakeSeries
        .map((ts) => ts.id)
        .sort()
        .join(",")) ||
      null,
    async (params) => {
      const latest = await client.datapoints.retrieveLatest(
        params.split(",").map((ts: string) => ({ id: Number(ts) })),
      );
      return latest.reduce(
        (prev: any, current: any): Record<string, any> => {
          const subBuildingAndSystemCode = getSubBuildingAndSystemCode(
            current.externalId!,
          );
          return { ...prev, [subBuildingAndSystemCode]: current.datapoints[0] };
        },
        {} as Record<string, any>,
      );
    },
    {
      dedupingInterval: 60000,
      focusThrottleInterval: 60000,
    },
  );

  const numberFormat = new Intl.NumberFormat("nb-NO", {
    maximumFractionDigits: 1,
  });

  const dataSource = Object.entries(intakeOuttakeObj || []).map(([key, ts]) => {
    const intakePoint = intake && intake[key];

    const splitSubBuildingAndSystemCode = key.split("=");
    const subBuilding = splitSubBuildingAndSystemCode[0];
    const systemCode = splitSubBuildingAndSystemCode[1];

    const outtakePoint = (outtake && outtake[key]) || {};
    const intakeName =
      ts.intake && ts.intake.description + " " + ts.intake.name;
    const outtakeName =
      ts.outtake && ts.outtake.description + " " + ts.outtake.name;

    return {
      key: (ts.intake && ts.intake.id) || (ts.outtake && ts.outtake.id),
      systemCode: systemCode,
      subBuilding: subBuilding,
      intakeTime: intakePoint?.timestamp,
      intake: intakePoint?.value as number | undefined,
      hideIntake: ((ts.intake && ts.intake?.hideIntake) as boolean) || false,
      outtakeTime: outtakePoint?.timestamp,
      outtake: outtakePoint?.value as number | undefined,
      hideOuttake:
        ((ts.outtake && ts.outtake?.hideOuttake) as boolean) || false,
      externalIdOuttake: ts.outtake && ts.outtake?.externalId,
      externalIdIntake: ts.intake && ts.intake.externalId,
      intakeOuttake: ts.intakeOuttake,
      intakeName,
      outtakeName,
    };
  });

  const tableHeight =
    windowHeight - 188 - 200 >= 800
      ? 800
      : windowHeight - 188 - 200 < 300
      ? 300
      : windowHeight - 188 - 200;

  const columns: TableColumnsType<(typeof dataSource)[number]> = [
    {
      title: t("dashboard.widgets.indoor-climate.sub-building"),
      key: "subBuilding",
      dataIndex: "subBuilding",
      sorter: (
        { subBuilding: subBuildingOne },
        { subBuilding: subBuildingTwo },
      ) => sortString(subBuildingOne, subBuildingTwo),
    },
    {
      title: t("dashboard.widgets.indoor-climate.system-code"),
      dataIndex: "systemCode",
      key: "systemCode",
      sorter: ({ systemCode: systemCodeOne }, { systemCode: systemCodeTwo }) =>
        sortString(systemCodeOne, systemCodeTwo),
      defaultSortOrder: "ascend",
    },
    {
      title: t("dashboard.widgets.indoor-climate.supply-air"),
      dataIndex: "intake",
      key: "intake",
      sorter: (
        { intake: intakeOne, hideIntake: hideIntakeOne = false },
        { intake: intakeTwo, hideIntake: hideIntakeTwo = false },
        sortOrder,
      ) => {
        if (hideIntakeOne || intakeOne === undefined) {
          if (!hideIntakeTwo && typeof intakeTwo === "number") {
            return sortOrder === "ascend" ? 1 : -1;
          }
          return 0;
        }
        if (hideIntakeTwo || intakeTwo === undefined) {
          return sortOrder === "descend" ? 1 : -1;
        }
        return sortNumber(intakeOne, intakeTwo);
      },
      render: (val, { hideIntake = false }) => {
        if (hideIntake) {
          return null;
        }
        return typeof val === "number" ? `${numberFormat.format(val)}°` : null;
      },
    },
    {
      title: t("dashboard.widgets.indoor-climate.extract-air"),
      dataIndex: "outtake",
      key: "outtake",
      sorter: (
        { outtake: outtakeOne, hideOuttake: hideOuttakeOne = false },
        { outtake: outtakeTwo, hideOuttake: hideOuttakeTwo = false },
        sortOrder,
      ) => {
        if (hideOuttakeOne || outtakeOne === undefined) {
          if (!hideOuttakeTwo && typeof outtakeTwo === "number") {
            return sortOrder === "ascend" ? 1 : -1;
          }
          return 0;
        }
        if (hideOuttakeTwo || outtakeTwo === undefined) {
          return sortOrder === "descend" ? 1 : -1;
        }
        return sortNumber(outtakeOne, outtakeTwo);
      },
      render: (val, { hideOuttake = false }) => {
        if (hideOuttake) {
          return null;
        }
        return typeof val === "number" ? `${numberFormat.format(val)}°` : null;
      },
    },
  ];

  return (
    <DraggableCard
      style={{ width, height }}
      title={
        <WidgetHeader
          text={t("dashboard.widgets.indoor-climate.title")}
          onClickRemoveButton={onClickRemoveButton}
          onClickSettingsButton={() => setShowSettings(true)}
          isDraggable
        />
      }
    >
      <TableWithoutDefaultSort
        pagination={
          dataSource.length > 8
            ? {
                pageSize: 8,
                showSizeChanger: false,
              }
            : false
        }
        rowKey="key"
        dataSource={dataSource}
        size="small"
        columns={columns}
      />
      {showSettings && (
        <WhiteModal
          width={1000}
          title={t(
            "dashboard.widgets.indoor-climate.settings-for-indoor-climate",
          )}
          open
          onOk={() => setShowSettings(false)}
          onCancel={() => setShowSettings(false)}
          footer={[
            <Button
              key="cancel"
              type="default"
              onClick={() => setShowSettings(false)}
            >
              {t("dashboard.widgets.indoor-climate.cancel")}
            </Button>,
            <Button
              key="reset"
              danger
              type="primary"
              onClick={async () => {
                await mutateBuildingSpec(id, {
                  indoorClimate: {},
                });
                setLoading(true);
              }}
            >
              {t("dashboard.widgets.indoor-climate.reset")}
            </Button>,
            <Button
              key="ok"
              type="default"
              onClick={() => setShowSettings(false)}
            >
              {t("dashboard.widgets.indoor-climate.ok")}
            </Button>,
          ]}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: tableHeight,
              }}
            >
              <Spin />
            </div>
          ) : (
            <IndoorClimateSettings
              id={id}
              data={dataSource}
              intakeSeries={intakeSeries}
              outtakeSeries={outtakeSeries}
              tableHeight={tableHeight}
            />
          )}
        </WhiteModal>
      )}
    </DraggableCard>
  );
}
