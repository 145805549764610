import { PageHeader } from "@ant-design/pro-layout";
import { App, Form, Space, Tag } from "antd";
import { useTranslations } from "@properate/translations";
import {
  Link,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import * as React from "react";
import { useState } from "react";
import { useTheme } from "styled-components";
import useSWR from "swr";
import { CompactContent } from "@/components/CompactContent";
import { AlertGroupAPI, AlertGroupLoader } from "@/features/alarms";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import {
  createGroup,
  deleteGroup,
  getGroupDependedData,
  updateGroup,
} from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  FormValues,
  useUnsavedChangesAlertGroups,
  useAlertGroupMembers,
  useAlertGroupNames,
} from "@/pages/alarmSystem/groupDetails/utils";
import { GroupUpdateModal } from "@/pages/alarmSystem/groupDetails/GroupUpdateModal";
import { GroupDetailsForm } from "@/pages/alarmSystem/groupDetails/GroupDetailsForm";
import { useGroupDetailsActionButtons } from "@/pages/alarmSystem/groupDetails/useGroupDetailsActionButtons";

export const AlertGroup = () => {
  const t = useTranslations();
  const theme = useTheme();
  const currentBuilding = useCurrentBuilding();
  useBuildingPageTitle(t("alert-group.title"));
  const { message } = App.useApp();

  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const pageData = useLoaderData() as AlertGroupLoader;

  const [form] = Form.useForm<FormValues>();
  const name = Form.useWatch("name", form);
  const owner = Form.useWatch("owner", form);

  const { groupNames, isLoadingGroups } = useAlertGroupNames(
    owner ? null : currentBuilding.externalId!,
    owner,
    pageData.id,
  );
  const { members, setMembers, validateMembers } = useAlertGroupMembers(
    pageData.members,
  );

  const { data: dependedData, isLoading: isLoadingDependedData } = useSWR(
    ["getDependedData", pageData.id],
    () =>
      pageData.id
        ? getGroupDependedData({
            building_external_id: currentBuilding.externalId!,
            id: pageData.id,
          })
        : undefined,
  );
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const hasUnsavedChanges = useUnsavedChangesAlertGroups(members, name);

  const onCancel = () => {
    navigate("../alertGroups");
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async () => {
    if (!pageData.id) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteGroup(pageData.id);
    } catch (e) {
      setIsDeleting(false);
      message.error(t("alert-group.delete-failed"));
      console.error("error", e);
      return;
    }

    navigate("../alertGroups");
  };

  const [isUpdating, setIsUpdating] = useState(false);

  const showUpdateValidationWarning =
    pageData.id &&
    (dependedData?.alert_configurations.length ||
      dependedData?.incidents.length);

  const onSubmit = async (values: FormValues) => {
    if (!validateMembers()) {
      return;
    }
    setIsUpdating(true);

    if (!currentBuilding.externalId) return;

    const isOwnerGroup = !!values.owner;

    if (pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          buildingExternalId: isOwnerGroup ? null : currentBuilding.externalId,
          owner: values.owner || undefined,
          members,
        });

        await updateGroup(pageData.id, data);

        message.success(t("alert-group.update-success"));
        revalidator.revalidate();
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.update-failed"));
        console.error("error", e);
        return;
      }
    }

    if (!pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          buildingExternalId: isOwnerGroup ? null : currentBuilding.externalId,
          owner: values.owner,
          members,
        });
        const group = await createGroup(data);

        message.success(t("alert-group.update-success"));
        navigate(`../alertGroups/${group.id}`);
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.create-failed"));
        console.error("error", e);
        return;
      }
    }

    setIsUpdating(false);
  };

  const getTitle = () => {
    if (!pageData.id) {
      return t("alert-group.new-group");
    }
    return pageData.name;
  };

  const actionButtons = useGroupDetailsActionButtons({
    onCancel,
    onDelete,
    isDeleting,
    isLoadingDependedData,
    isUpdating,
    isLoadingGroups,
    isShowingUpdateValidationWarning: !!showUpdateValidationWarning,
    onShowUpdateModal: () => setShowUpdateModal(true),
  });

  return (
    <div>
      <PageHeader
        title={
          <Space>
            <Link to={`../alertGroups`}>{t("alert-groups.title")}</Link>
            <RightOutlined />
            {getTitle()}
            {hasUnsavedChanges && (
              <Tag className="ml-2" color={theme.warning}>
                {t("alert-group.unsaved-changes")}
              </Tag>
            )}
          </Space>
        }
        extra={actionButtons}
      />
      <CompactContent>
        <GroupDetailsForm
          currentBuildingOwner={currentBuilding.metadata?.owner}
          form={form}
          onSubmit={onSubmit}
          groupNames={groupNames}
          membersData={{ members, setMembers, validateMembers }}
        />
      </CompactContent>
      {showUpdateModal && (
        <GroupUpdateModal
          onClose={() => {
            setShowUpdateModal(false);
            setIsUpdating(false);
          }}
          alertConfigurations={dependedData?.alert_configurations || []}
          incidents={dependedData?.incidents || []}
        />
      )}
    </div>
  );
};
