import { App } from "antd";
import { ReactNode } from "react";
import { AccessModules } from "@properate/common";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { ErrorResult } from "@/pages/Error/ErrorResult";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";

export const ProtectedRouteByBuildingModulesAccess = ({
  children,
  accessModule,
}: {
  children: ReactNode;
  accessModule: AccessModules | AccessModules[];
}) => {
  const { message } = App.useApp();
  const {
    accessModules: modules,
    isLoading,
    error,
  } = useBuildingModulesAccess();

  if (error) {
    message.error({
      content: error.message,
      key: "ProtectedRouteByBuildingModuleAccess",
    });
  }

  const hasAccess = Array.isArray(accessModule)
    ? accessModule.some((module) => modules?.includes(module))
    : modules?.includes(accessModule);

  if (isLoading)
    return (
      <SpinnerWithDelay
        className="flex items-center justify-center h-full"
        isLoading
      >
        <div />
      </SpinnerWithDelay>
    );

  return hasAccess ? <>{children}</> : <ErrorResult status={403} />;
};
