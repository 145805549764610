import { useEffect, useMemo, useState } from "react";
import { App, Grid } from "antd";
import { AccessModules, BuildingSpec } from "@properate/common";
import { arrayMoveImmutable } from "array-move";
import { deleteField } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { DashboardWidget } from "@/utils/types";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { mutateUserSettings } from "@/services/userSettings";
import { dashboardStandardWidgets } from "@/utils/dashboardWidgets";
import { isErrorWithMessage } from "@/utils/api";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import keycloak from "@/keycloak";
import { useGetDashboardData } from "@/hooks/useGetDashboardData";
import { useGetTotalEnergyAssets } from "../hooks/useGetTotalEnergyAssets";
import { useGetOrganizationsToTotalEnergyTimeseriesIds } from "../hooks/useGetOrganizationsToTotalEnergyTimeseries";
import { getOrganizations } from "../utils";
import { SortableList } from "./sortable/SortableList";

interface Props {
  selectedWidgets: DashboardWidget[];
  buildingSpec: BuildingSpec;
  buildingId: number;
}

export function DashboardWidgets({
  selectedWidgets,
  buildingSpec,
  buildingId,
}: Props) {
  const { notification } = App.useApp();
  const [selectedWidgetsSorted, setSelectedWidgetsSorted] = useState<
    DashboardWidget[]
  >([]);

  const url = useLocation();
  const userEmail = keycloak.idTokenParsed?.email ?? "";

  const { gauges, analyses, heatMaps } = useGetDashboardData({
    buildingId,
    email: userEmail,
    url: url.pathname,
  });
  useEffect(() => {
    setSelectedWidgetsSorted(selectedWidgets);
  }, [selectedWidgets]);
  const building = useCurrentBuilding();
  const { accessModules, isLoading } = useBuildingModulesAccess();
  const { sm = false } = Grid.useBreakpoint();
  const { totalEnergyAssets } = useGetTotalEnergyAssets();
  // Organizations with owner as the first organization
  const organizations = getOrganizations(totalEnergyAssets);
  const {
    organizationsToTotalEnergyTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedETModTimeseriesIds,
    organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds,
  } = useGetOrganizationsToTotalEnergyTimeseriesIds(totalEnergyAssets);
  const availableStandardWidgets = isLoading
    ? []
    : dashboardStandardWidgets.filter((dashboardStandardWidget) => {
        if (
          dashboardStandardWidget === "alarms" &&
          !accessModules.includes(AccessModules.enum.alarms)
        ) {
          return false;
        }
        if (
          dashboardStandardWidget === "incidents" &&
          !accessModules.includes(AccessModules.enum.incidents)
        ) {
          return false;
        }
        return selectedWidgetsSorted.every(
          ({ id }) => id !== dashboardStandardWidget,
        );
      });
  const availableGauges = useMemo(
    () =>
      gauges.filter((gauge) =>
        selectedWidgetsSorted.every(({ id }) => id !== gauge.snapshotId),
      ),
    [gauges, selectedWidgetsSorted],
  );
  const availableAnalyses = useMemo(
    () =>
      analyses.filter((analysis) =>
        selectedWidgetsSorted.every(({ id }) => id !== analysis.snapshotId),
      ),
    [analyses, selectedWidgetsSorted],
  );
  const availableHeatMaps = useMemo(
    () =>
      heatMaps?.filter((heatMap) =>
        selectedWidgetsSorted.every(({ id }) => id !== heatMap.snapshotId),
      ),
    [heatMaps, selectedWidgetsSorted],
  );
  const selectedGauges = useMemo(
    () =>
      gauges.filter((gauge) =>
        selectedWidgetsSorted.some(
          ({ type, id }) => type === "gauge" && id === gauge.snapshotId,
        ),
      ),
    [gauges, selectedWidgetsSorted],
  );
  const selectedAnalyses = useMemo(
    () =>
      analyses.filter((analysis) =>
        selectedWidgetsSorted.some(
          ({ type, id }) => type === "analysis" && id === analysis.snapshotId,
        ),
      ),
    [analyses, selectedWidgetsSorted],
  );
  const selectedHeatMaps = useMemo(
    () =>
      heatMaps?.filter((heatMap) =>
        selectedWidgetsSorted.some(
          ({ type, id }) => type === "heatMap" && id === heatMap.snapshotId,
        ),
      ),
    [heatMaps, selectedWidgetsSorted],
  );
  const widgetHeight = sm ? 380 : null;

  async function handleSortEnd({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) {
    document.body.style.cursor = "auto";
    if (oldIndex === newIndex) {
      return;
    }
    const widgetsReSorted = arrayMoveImmutable(
      selectedWidgetsSorted,
      oldIndex,
      newIndex,
    );
    setSelectedWidgetsSorted(widgetsReSorted);
    await mutateUserSettings({
      buildings: {
        [building.id]: {
          dashboard: {
            widgets: widgetsReSorted,
          },
        },
      },
    });
  }

  function handleAddWidgets(widgets: DashboardWidget[]) {
    const widgetsWithNewWidget = selectedWidgetsSorted.concat(widgets);
    setSelectedWidgetsSorted(widgetsWithNewWidget);
    mutateUserSettings({
      buildings: {
        [building.id]: {
          dashboard: {
            widgets: widgetsWithNewWidget,
          },
        },
      },
    });
  }

  async function handleRemoveWidget(widgetToRemove: DashboardWidget) {
    const widgetsWithoutWidgetToRemove = selectedWidgetsSorted.filter(
      (widget) => widget !== widgetToRemove,
    );
    setSelectedWidgetsSorted(widgetsWithoutWidgetToRemove);
    try {
      await mutateUserSettings({
        buildings: {
          [building.id]: {
            dashboard: {
              widgets: widgetsWithoutWidgetToRemove,
            },
          },
        },
      });
      await removeWidgetSettings(widgetToRemove);
    } catch (error) {
      if (isErrorWithMessage(error)) {
        return notification.error({
          message: error.message,
        });
      }
      console.error(error);
    }
  }

  async function removeWidgetSettings(widgetToRemove: DashboardWidget) {
    if (widgetToRemove.id === "solarCellProduction") {
      return mutateUserSettings({
        // @ts-expect-error deleteField is not valid as a type but is the best way to remove a prop
        buildings: {
          [building.id]: {
            settingsSolarCellProduction: deleteField(),
          },
        },
      });
    }
    if (widgetToRemove.id === "spotPrice") {
      return mutateUserSettings({
        // @ts-expect-error deleteField is not valid as a type but is the best way to remove a prop
        buildings: {
          [building.id]: {
            settingsSpotPrice: deleteField(),
          },
        },
      });
    }
    if (widgetToRemove.type === "analysis") {
      return mutateUserSettings({
        // @ts-expect-error deleteField is not valid as a type but is the best way to remove a prop
        buildings: {
          [building.id]: {
            settingsAnalyses: {
              presetTimeSpanPerAnalysis: {
                [widgetToRemove.id]: deleteField(),
              },
            },
          },
        },
      });
    }
  }

  return typeof widgetHeight === "number" ? (
    <SortableList
      useDragHandle
      axis="xy"
      widgetHeight={widgetHeight}
      organizations={organizations}
      organizationsToTotalEnergyTimeseriesIds={
        organizationsToTotalEnergyTimeseriesIds
      }
      organizationsToTotalEnergyTempCorrectedETModTimeseriesIds={
        organizationsToTotalEnergyTempCorrectedETModTimeseriesIds
      }
      organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds={
        organizationsToTotalEnergyTempCorrectedGraddagTimeseriesIds
      }
      selectedWidgets={selectedWidgetsSorted}
      selectedGauges={selectedGauges}
      selectedAnalyses={selectedAnalyses}
      selectedHeatMaps={selectedHeatMaps}
      buildingSpec={buildingSpec}
      onSortStart={() => {
        document.body.style.cursor = "grabbing";
      }}
      availableStandardWidgets={availableStandardWidgets}
      availableGauges={availableGauges}
      availableAnalyses={availableAnalyses}
      availableHeatMaps={availableHeatMaps}
      onSortEnd={handleSortEnd}
      helperClass="moving"
      onRemoveWidget={handleRemoveWidget}
      onAddWidgets={handleAddWidgets}
    />
  ) : null;
}
