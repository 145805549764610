import { useLoaderData, useNavigate } from "react-router-dom";
import { Button, Input, Popconfirm, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useTranslations } from "@properate/translations";
import { TableProps } from "antd/es/table/InternalTable";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";
import AutoSizer from "react-virtualized-auto-sizer";
import { useTheme } from "styled-components";
import { AlertGroupsLoader, AlertGroupPageRecord } from "@/features/alarms";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { CompactContent } from "@/components/CompactContent";
import { Sort } from "@/pages/fileType/types";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import Highlight from "@/components/Highlight";
import { getUrl, useGroupsData } from "./utils";
import type { SorterResult } from "antd/es/table/interface";

export const AlertGroups = () => {
  const t = useTranslations();
  useBuildingPageTitle(t("alert-groups.title"));
  const pageData = useLoaderData() as AlertGroupsLoader;
  const navigate = useNavigate();
  const theme = useTheme();

  const { groups, onDelete, onLoadMore, isLoading } = useGroupsData();

  const doSearch = (search: string) => {
    navigate(getUrl(pageData.id, search, pageData.sort));
  };

  const doSort = (sort: Sort) => {
    navigate(
      getUrl(pageData.id, pageData.search, {
        property: sort.property,
        order: sort.order,
      }),
    );
  };

  const onEdit = (id: string) => {
    navigate(`${id}`);
  };

  const onAddNew = () => {
    navigate("new");
  };

  const columns: ColumnsType<AlertGroupPageRecord> = [
    {
      title: t("alert-groups.table.name"),
      key: "name",
      sorter: true,
      defaultSortOrder: "ascend",
      render: ({ name, highlight }) =>
        highlight?.name ? (
          <Highlight dangerouslySetInnerHTML={{ __html: highlight?.name }} />
        ) : (
          name
        ),
    },
    {
      key: "owner",
      dataIndex: "owner",
      width: 100,
      align: "center",
      render: (owner) => (
        <>
          {!!owner && (
            <Tag className="h-5 text-xs ml-2" color={theme.highlightBg}>
              {t("alert-groups.table.owner-group")}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: t("alert-groups.table.members"),
      dataIndex: "membersCount",
      key: "membersCount",
      width: 100,
      align: "center",
    },
    {
      key: "actions",
      width: 100,
      render: ({ id }) => (
        <div className="flex gap-2 items-center justify-center">
          <Popconfirm
            title={t("alert-groups.delete-confirm")}
            onConfirm={() => onDelete(id)}
          >
            <Button
              aria-label={t("alert-groups.table.delete")}
              icon={<DeleteOutlined />}
              danger
            />
          </Popconfirm>
          <Button
            onClick={() => onEdit(id)}
            icon={<EditOutlined />}
            aria-label={t("alert-groups.table.edit")}
          />
        </div>
      ),
    },
  ];

  const onChangeTable = (
    _: any,
    __: any,
    sorter: SorterResult<AlertGroupPageRecord>,
  ) => {
    if (!sorter.columnKey || !sorter.order) return;

    doSort({
      property: sorter.columnKey as string,
      order: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  return (
    <div className="h-full w-full flex flex-col">
      <PageHeader
        title={t("alert-groups.title")}
        extra={[
          <Input.Search
            defaultValue={pageData.search}
            data-testid="search-for-alert-groups"
            key="search"
            allowClear
            style={{ width: 300 }}
            onChange={(event) => doSearch(event.target.value)}
            aria-label={t("alert-groups.search")}
          />,
          <Button
            key="new"
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddNew}
            aria-label={t("alert-groups.add-group")}
          >
            {t("alert-groups.add-group")}
          </Button>,
        ]}
      />
      <CompactContent className="h-full flex-1">
        <div className="h-full flex">
          <AutoSizer className="flex-1">
            {({ height }) => (
              <TableInfiniteScroll<AlertGroupPageRecord>
                columns={columns}
                dataSource={groups}
                height={height - 64}
                onReachedEndOfPage={onLoadMore}
                rowKey="id"
                loading={isLoading}
                onChange={
                  onChangeTable as TableProps<AlertGroupPageRecord>["onChange"]
                }
              />
            )}
          </AutoSizer>
        </div>
      </CompactContent>
    </div>
  );
};
