"use client";

import isEqual from "lodash.isequal";
import { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import { SidebarAction, SidebarActionType, SidebarValues } from "./types";

export function sidebarReducer(
  values: SidebarValues<Record<string, unknown>>,
  action: SidebarAction,
) {
  switch (action.type) {
    case SidebarActionType.close:
      return {
        ...values,
        isOpen: false,
      };
    case SidebarActionType.open:
      return {
        ...values,
        isOpen: true,
      };
    case SidebarActionType.toggle:
      return {
        ...values,
        isOpen: !values.isOpen,
      };
    case SidebarActionType.setTitle:
      return {
        ...values,
        title: action.title,
      };
    case SidebarActionType.setContent:
      return {
        ...values,
        content: action.content,
      };
    case SidebarActionType.setData:
      return {
        ...values,
        data: action.data,
      };
    case SidebarActionType.mergeData:
      if (isEqual(values.data, action.data)) {
        return values;
      }

      return {
        ...values,
        data: {
          ...values.data,
          ...action.data,
        },
      };
    case SidebarActionType.setPortalDomID:
      return {
        ...values,
        portalDomId: action.portalDomId,
      };
  }
}

export const SidebarContext = createContext<SidebarValues<
  Record<string, unknown>
> | null>(null);
export const SidebarDispatchContext =
  createContext<Dispatch<SidebarAction> | null>(null);

export function SidebarProvider({ children }: PropsWithChildren) {
  const [values, dispatch] = useReducer(sidebarReducer, {
    isOpen: false,
    title: "",
    content: "",
    data: {},
    portalDomId: "default-sidebar-portal-location",
  });
  return (
    <SidebarContext.Provider value={values}>
      <SidebarDispatchContext.Provider value={dispatch}>
        {children}
      </SidebarDispatchContext.Provider>
    </SidebarContext.Provider>
  );
}
