import dayjs, { Dayjs } from "@properate/dayjs";
import { DAYS, getUserNameWithEmail, Period } from "@properate/common";

export const getFormatedStartDate = (start: Dayjs) => {
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const currentDay = dayjs().date();

  if (start.year() === currentYear) {
    if (start.month() === currentMonth) {
      if (start.date() === currentDay) {
        return start.format("HH:mm");
      }
      return start.format("DD/MM HH:mm");
    }
    return start.format("DD/MM HH:mm");
  }
  return start.format("DD/MM YYYY HH:mm");
};

export function getRangeAsString(start: Dayjs, end: Dayjs) {
  const currentYear = dayjs().year();
  const currentMonth = dayjs().month();
  const currentDay = dayjs().date();

  const startFormatted = getFormatedStartDate(start);

  if (start.year() === currentYear) {
    if (start.month() === currentMonth) {
      if (start.day() === currentDay) {
        return `${startFormatted} - ${end.format("HH:mm")}`;
      }
      return `${startFormatted} - ${end.format("DD/MM HH:mm")}`;
    }
    return `${startFormatted} - ${end.format("DD/MM HH:mm")}`;
  }

  return `${startFormatted} - ${end.format("DD/MM YYYY HH:mm")}`;
}

export function formatPeriods(periods: (Period & { translatedDay: string })[]) {
  if (periods.length === 1) {
    const periodStartDay = periods[0].translatedDay;
    const periodStartTime = periods[0].start;
    const periodEndTime = periods[0].end;
    return `${periodStartDay} ${periodStartTime} - ${periodEndTime}`;
  }
  const sortedPeriods = periods
    .slice()
    .sort(
      (periodOne, periodTwo) =>
        DAYS.indexOf(periodOne.day) - DAYS.indexOf(periodTwo.day),
    );

  const periodStartDay = sortedPeriods[0].translatedDay;
  const periodStartTime = sortedPeriods[0].start;
  const periodEndTime = sortedPeriods.at(-1)!.end;
  const arePeriodsConsecutiveDays = sortedPeriods.every((period, i) => {
    if (i === 0) {
      return true;
    }
    return (
      DAYS.indexOf(period.day) - DAYS.indexOf(sortedPeriods[i - 1].day) === 1
    );
  });
  if (arePeriodsConsecutiveDays) {
    const periodEndDay = sortedPeriods.at(-1)!.translatedDay;
    return `${periodStartDay} - ${periodEndDay} ${periodStartTime} - ${periodEndTime}`;
  }

  const periodDays = sortedPeriods
    .map((period) => period.translatedDay)
    .join(", ");
  return `${periodDays} ${periodStartTime} - ${periodEndTime}`;
}

export function combinePeriods(periods: Period[]): Period[][] {
  return periods.reduce<Period[][]>((combinedPeriodsAcc, period) => {
    const periodWithSameStartAndEndIndex = combinedPeriodsAcc.findIndex(
      ([{ start: combinedPeriodStart, end: combinedPeriodEnd }]) =>
        combinedPeriodStart === period.start &&
        combinedPeriodEnd === period.end,
    );
    if (periodWithSameStartAndEndIndex > -1) {
      return [
        ...combinedPeriodsAcc.slice(0, periodWithSameStartAndEndIndex),
        combinedPeriodsAcc[periodWithSameStartAndEndIndex].concat(period),
        ...combinedPeriodsAcc.slice(periodWithSameStartAndEndIndex + 1),
      ];
    }
    return [...combinedPeriodsAcc, [period]];
  }, []);
}

export function getDefaultPeriod(periods: Period[]) {
  const days = periods.map((p) => p.day);
  const includesWeekdays = !(
    days.includes("MONDAY") &&
    days.includes("TUESDAY") &&
    days.includes("WEDNESDAY") &&
    days.includes("THURSDAY") &&
    days.includes("FRIDAY")
  );
  return includesWeekdays ? "WEEKDAYS" : "MONDAY";
}

export const selectOptionRenderer = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) =>
  getUserNameWithEmail({
    name: label,
    email: value,
  });
