import { PageHeader } from "@ant-design/pro-layout";
import { App, Button, Divider, Form, Space, Tooltip } from "antd";
import { useTranslations } from "@properate/translations";
import { Link, useLoaderData } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import {
  AlarmRule,
  getUserNameWithEmail,
  IncidentClientSide,
} from "@properate/common";
import { useUser } from "@properate/auth";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { CenteredSpinner, ToggleSidebarButton } from "@properate/ui";
import { CompactContent } from "@/components/CompactContent";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { AlarmTimeseriesGraph } from "@/pages/alarms/details/components/AlarmTimeseriesGraph";
import { useAlarmRule } from "@/pages/alarms/details/hooks/useAlarmRules";
import {
  assignIncidentToUser,
  getIncident,
  sendIncidentToNextPerson,
  updateIncident,
} from "@/eepApi";
import {
  useHasNextPerson,
  useIncidentComments,
} from "@/pages/alarmSystem/incident/utils";
import { useHandleApiError } from "@/utils/api";
import { AlarmTypeSpecificNotesSidebar } from "@/pages/alarms/details/components/AlarmDetailsNotesSidebar";
import { AlarmDetailsTimespanSelector } from "@/pages/alarms/details/components/AlarmDetailsTimespanSelector";
import { IncidentInfo } from "./IncidentInfo";
import { IncidentAlarmInfo } from "./IncidentAlarmInfo";
import { IncidentAlarmContextWrapper } from "./IncidentAlarmContextWrapper";
import { IncidentAlarmComponentWrapper } from "./IncidentAlarmComponentWrapper";
import SomethingWrong from "./SomethingWrong";

const queryClient = new QueryClient();

export const Incident = () => {
  const pageData = useLoaderData() as { id: string };
  const handleAPIError = useHandleApiError();

  const {
    data: incidentData,
    isLoading,
    error,
  } = useQuery(
    {
      queryKey: ["incident", pageData.id],
      queryFn: () => {
        return getIncident(pageData.id!);
      },
    },
    queryClient,
  );

  if (error) {
    handleAPIError(error);
  }

  const { alarmRule, error: alarmRuleError } = useAlarmRule(
    incidentData?.alarm_rule_id,
  );

  if (alarmRuleError) {
    return <SomethingWrong />;
  }

  if (incidentData === undefined || isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <IncidentAlarmContextWrapper
      alarmRule={alarmRule}
      deviationId={incidentData.alarm_event_id_list[0]}
      startTimespan={incidentData.start}
    >
      <IncidentAlarmComponentWrapper>
        <IncidentContent incidentData={incidentData} alarmRule={alarmRule} />
      </IncidentAlarmComponentWrapper>
    </IncidentAlarmContextWrapper>
  );
};

export const IncidentContent = ({
  incidentData,
  alarmRule,
}: {
  incidentData: IncidentClientSide;
  alarmRule?: AlarmRule;
}) => {
  const pageData = useLoaderData() as { id: string };
  const t = useTranslations();
  const currentUser = useUser();
  const handleAPIError = useHandleApiError();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const refetchIncident = () => {
    const incident = queryClient.getQueryData<IncidentClientSide>([
      "incident",
      pageData.id,
    ]);

    if (
      incident?.alert_next_at &&
      incident.alert_next_at <= Date.now() &&
      !incident.assigned?.user
    ) {
      setTimeout(async () => {
        await queryClient.invalidateQueries({
          queryKey: ["incident", pageData.id],
          refetchType: "all",
        });
        refetchIncident();
      }, 2000);
    }
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const commentsData = useIncidentComments(incidentData);
  const onAssignToMe = async () => {
    setIsUpdating(true);
    try {
      if (!pageData.id) {
        return;
      }
      const last_comment = t("incident.assigned-to-user-message", {
        assignedToUser: getUserNameWithEmail(currentUser),
        assignedByUser: getUserNameWithEmail(currentUser),
      });
      const incident = await assignIncidentToUser(pageData.id, {
        user: {
          name: currentUser.name,
          email: currentUser.email,
        },
        last_comment,
      });
      queryClient.setQueryData<IncidentClientSide>(
        ["incident", pageData.id],
        incident,
      );
      message.success(t("incident.success-assigned-to-me"));
      await commentsData.onSendComment(last_comment);
    } catch (error) {
      handleAPIError(error);
    }
    setIsUpdating(false);
  };

  const onSendToNext = async () => {
    setIsUpdating(true);
    try {
      if (!pageData.id) {
        return;
      }
      const incident = await sendIncidentToNextPerson(pageData.id);
      queryClient.setQueryData<IncidentClientSide>(
        ["incident", pageData.id],
        incident,
      );
      message.success(t("incident.success-send-to-next"));
    } catch (error) {
      handleAPIError(error);
    }
    setIsUpdating(false);
  };

  const onUpdateIncident = async (values: Partial<IncidentClientSide>) => {
    setIsUpdating(true);
    try {
      if (
        !pageData.id ||
        (!values.status && !values.assigned) ||
        !incidentData
      ) {
        return;
      }
      const incident = await updateIncident(pageData.id, {
        ...incidentData,
        ...values,
      });
      queryClient.setQueryData<IncidentClientSide>(
        ["incident", pageData.id],
        incident,
      );
      message.success(t("incident.success-incident-updated"));
    } catch (error) {
      handleAPIError(error);
    }
    setIsUpdating(false);
  };

  const title = incidentData?.name;

  const hasAlertConfiguration = incidentData?.alert_rule_id;
  const isAssignedToMe =
    incidentData?.assigned?.user.email === currentUser.email;
  const isAssigned = !!incidentData?.assigned?.user;
  const isResolved = incidentData?.status === "resolved";
  const isNotifyAll =
    !incidentData?.responsible && !!incidentData?.notified_people;

  const { hasNextPerson, isLoading: isLoadingHasNextPerson } = useHasNextPerson(
    {
      alertedGroupId: isNotifyAll ? undefined : incidentData?.alerted_group_id,
      currentResponsibleUserEmail: isNotifyAll
        ? undefined
        : incidentData?.responsible?.user.email,
    },
  );

  const sendToNextPersonTooltip = useMemo(() => {
    if (isResolved) {
      return t("incident.is-resolved");
    }
    if (!hasAlertConfiguration) {
      return t("incident.no-responsible-people");
    }
    if (isNotifyAll) {
      return t("incident.no-next-person-notify-all-is-on");
    }
    if (hasNextPerson) {
      return "";
    }
    return t("incident.no-next-person");
  }, [hasAlertConfiguration, hasNextPerson, isNotifyAll, t, isResolved]);

  useBuildingPageTitle(title || t("incident.title"));

  const assignButtons = [
    ...(!isAssignedToMe
      ? [
          <Button
            key="assign-to-me"
            type="primary"
            onClick={onAssignToMe}
            loading={isUpdating}
            aria-label={t("incident.assign-to-me")}
          >
            {t("incident.assign-to-me")}
          </Button>,
        ]
      : []),
    ...(!isAssigned
      ? [
          <Tooltip key="send-to-next" title={sendToNextPersonTooltip}>
            <Button
              key="send-to-next"
              type="primary"
              aria-label={t("incident.send-to-next")}
              onClick={onSendToNext}
              loading={isUpdating || isLoadingHasNextPerson}
              disabled={!hasNextPerson || isNotifyAll || isResolved}
            >
              {t("incident.send-to-next")}
            </Button>
          </Tooltip>,
        ]
      : []),
  ];

  return (
    <div className="h-full flex flex-col max-h-screen">
      <PageHeader
        title={
          <Space>
            <Link to={`../incidents`}>{t("incidents.title")}</Link>
            <RightOutlined />
            {title}
          </Space>
        }
        extra={[
          ...assignButtons,
          <Button
            key="save"
            ghost
            type="primary"
            onClick={() => form.submit()}
            aria-label={t("incident.save")}
            loading={isUpdating}
            form="incidents-form"
          >
            {t("incident.save")}
          </Button>,
          <ToggleSidebarButton
            key="notes"
            hiddenWhenSidebarVisible
            sidebarHiddenContent={t("notes.show-notes-button")}
          />,
        ]}
      />
      <CompactContent className="flex flex-col gap-4">
        <div className="col-span-2 box-border flex flex-col gap-4">
          <div className="ml-auto">
            <AlarmDetailsTimespanSelector />
          </div>
          <AlarmTimeseriesGraph />
        </div>
        <div className="rounded-lg bg-background-secondary overflow-auto p-4 mb-4 flex-1">
          <div className="flex flex-1 flex-col lg:flex-row">
            <div className="flex-1">
              <IncidentInfo
                assignButtons={assignButtons}
                data={incidentData}
                alarmRule={alarmRule}
                name={title || ""}
                refetchIncident={refetchIncident}
                form={form}
                onUpdateIncident={onUpdateIncident}
                commentsData={commentsData}
              />
            </div>
            <Divider type="vertical" className="mx-5 h-full" />
            <div className="lg:flex-1 flex overflow-y-hidden mt-2 lg:mt-0">
              <IncidentAlarmInfo
                alarmRuleId={incidentData.alarm_rule_id}
                deviationId={incidentData.alarm_event_id_list[0]}
              />
              <AlarmTypeSpecificNotesSidebar />
            </div>
          </div>
        </div>
      </CompactContent>
    </div>
  );
};
