import { formatSubBuildingFromExternalId } from "@properate/common";
import useSWR from "swr";
import { getAssetsWithLabels } from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";

export function useFloorPlanAssets() {
  const buildingId = useCurrentBuildingId();
  const { client } = useCogniteClient();

  return useSWR(
    ["assets", buildingId],
    async ([_, buildingId]) => {
      const [floors, ...assets] = await Promise.all([
        client.assets
          .list({
            filter: {
              assetSubtreeIds: [{ id: buildingId }],
              labels: {
                containsAny: [
                  { externalId: "sub_floor" },
                  { externalId: "floor" },
                ],
              },
            },
          })
          .autoPagingToArray({ limit: Infinity }),
        getAssetsWithLabels(client, buildingId, ["ventilation_unit"]),
        getAssetsWithLabels(client, buildingId, ["cooling_system"]),
        getAssetsWithLabels(client, buildingId, ["heating_system"]),
        getAssetsWithLabels(client, buildingId, ["sanity_system"]),
        getAssetsWithLabels(client, buildingId, ["process_cooling"]),
        getAssetsWithLabels(client, buildingId, ["outdoor_heating_system"]),
        getAssetsWithLabels(client, buildingId, ["low_voltage_supply"]),
        getAssetsWithLabels(client, buildingId, ["cooling_production"]),
      ]);

      const subBuildings = assets.flat().reduce<string[]>((acc, asset) => {
        if (asset.externalId) {
          const subBuilding = formatSubBuildingFromExternalId(asset.externalId);

          if (subBuilding !== "Common" && !acc.includes(subBuilding)) {
            acc.push(subBuilding);
          }
        }

        return acc;
      }, []);

      return {
        floors,
        subBuildings,
      };
    },
    {
      revalidateOnFocus: false,
    },
  );
}
