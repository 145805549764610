import { Button, Input, Select, SelectProps } from "antd";
import { MessageKey, useTranslations } from "@properate/translations";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { TimeseriesSearchable } from "@properate/api/src/types";
import { timeseriesIndex } from "@properate/api";
import {
  objectMerge,
  objectMergeSetArrayStrategy,
  type TimeseriesNewIndexFilterableAttributes,
} from "@properate/common";
import { SearchIndexProps, useSearchIndex } from "@/hooks/useSearchIndex";
import { useFormValue } from "../../FormContext/hooks";
import { FormContextItem } from "../../FormContext";
import { LabelDirection } from "../../FormContext/FormContextItem";
import { ResponsiveTable, ResponsiveTableColumn } from "../ResponsiveTable";
import { FormContextProviderEntries } from "../../FormContext/types";
import { useTimeseries } from "../../hooks";

const tBase = "alarm-details.select-timeseries.";
function tKey(key: string): MessageKey {
  return `${tBase}${key}` as MessageKey;
}

export enum SelectTimeseriesFormFields {
  Search = "SelectTimeseriesSearch",
  Category = "SelectTimeseriesCategory",
  CategorySearch = "SelectTimeseriesCategorySearch",
  Building = "SelectTimeseriesBuilding",
  BuildingSearch = "SelectTimeseriesBuildingSearch",
  System = "SelectTimeseriesSystem",
  SystemSearch = "SelectTimeseriesSystemSearch",
  Unit = "SelectTimeseriesUnit",
  UnitSearch = "SelectTimeseriesUnitSearch",
}

export function getSelectTimeseriesEntries(): FormContextProviderEntries {
  return {
    [SelectTimeseriesFormFields.Search]: {
      defaultValue: "",
    },
    [SelectTimeseriesFormFields.Category]: {
      defaultValue: undefined,
    },
    [SelectTimeseriesFormFields.CategorySearch]: {
      defaultValue: "",
    },
    [SelectTimeseriesFormFields.Building]: {
      defaultValue: undefined,
    },
    [SelectTimeseriesFormFields.BuildingSearch]: {
      defaultValue: "",
    },
    [SelectTimeseriesFormFields.System]: {
      defaultValue: undefined,
    },
    [SelectTimeseriesFormFields.SystemSearch]: {
      defaultValue: "",
    },
    [SelectTimeseriesFormFields.Unit]: {
      defaultValue: undefined,
    },
    [SelectTimeseriesFormFields.UnitSearch]: {
      defaultValue: "",
    },
  };
}

function SearchInput() {
  return (
    <FormContextItem
      labelKey={`${tBase}search`}
      id={SelectTimeseriesFormFields.Search}
    >
      <Input.Search
        enterButton={<Button ghost type="primary" icon={<SearchOutlined />} />}
      />
    </FormContextItem>
  );
}

function FacetHitSelect({
  options,
  searchFormId,
  selectedFormId,
  labelKey,
  popupMatchSelectWidth,
}: Readonly<{
  options?: SelectProps["options"];
  searchFormId: string;
  selectedFormId: string;
  labelKey: MessageKey;
  popupMatchSelectWidth?: number;
}>) {
  const [_search, setSearch] = useFormValue<string | null>(searchFormId);
  const [_selected, setSelected] = useFormValue<string | null>(selectedFormId);

  return (
    <FormContextItem
      labelKey={labelKey}
      labelDirection={LabelDirection.Vertical}
      id={searchFormId}
      antdInput
      inputProps={{
        showSearch: true,
        allowClear: true,
        options,
        optionFilterProp: "label",
        popupMatchSelectWidth,
        onSelect: (value: string) => {
          setSelected(value);
          setSearch(value);
        },
        onClear: () => {
          setSelected("");
          setSearch("");
        },
      }}
    >
      <Select />
    </FormContextItem>
  );
}

export function SelectTimeseries({
  close,
  name,
  label,
  overrideSearchFilter,
}: Readonly<{
  close: () => void;
  name: string;
  label: string;
  overrideSearchFilter?: Partial<
    SearchIndexProps<
      TimeseriesNewIndexFilterableAttributes,
      TimeseriesSearchable
    >
  >;
}>) {
  const t = useTranslations();
  const [timeseriesIdFromForm, setTimeseriesIdInForm] =
    useFormValue<number>(name);

  const { timeseries } = useTimeseries({
    timeseriesId: timeseriesIdFromForm,
  });

  const timeseriesSearchable = timeseries
    ? ({
        externalId: timeseries.externalId,
        name: timeseries.name,
      } as unknown as TimeseriesSearchable)
    : undefined;

  const [selectedTimeseries, setSelectedTimeseries] =
    useState(timeseriesSearchable);

  const [search] = useFormValue<string>(SelectTimeseriesFormFields.Search);

  const [category] = useFormValue<string>(SelectTimeseriesFormFields.Category);
  const [categorySearch] = useFormValue<string>(
    SelectTimeseriesFormFields.CategorySearch,
  );
  const [building] = useFormValue<string>(SelectTimeseriesFormFields.Building);
  const [buildingSearch] = useFormValue<string>(
    SelectTimeseriesFormFields.BuildingSearch,
  );
  const [system] = useFormValue<string>(SelectTimeseriesFormFields.System);
  const [systemSearch] = useFormValue<string>(
    SelectTimeseriesFormFields.SystemSearch,
  );
  const [unit] = useFormValue<string>(SelectTimeseriesFormFields.Unit);
  const [unitSearch] = useFormValue<string>(
    SelectTimeseriesFormFields.UnitSearch,
  );

  function getSearchFilter() {
    const searchFilter: SearchIndexProps<
      TimeseriesNewIndexFilterableAttributes,
      TimeseriesSearchable
    > = {
      index: timeseriesIndex,
      search,
      selectedFacets: {
        types: category,
        subBuilding: building,
        system,
        unit,
      },
      facetSearchInput: {
        types: categorySearch ?? "",
        subBuilding: buildingSearch ?? "",
        system: systemSearch ?? "",
        unit: unitSearch ?? "",
      },
    };
    if (overrideSearchFilter) {
      return objectMerge<
        SearchIndexProps<
          TimeseriesNewIndexFilterableAttributes,
          TimeseriesSearchable
        >
      >(searchFilter, overrideSearchFilter, objectMergeSetArrayStrategy);
    }
    return searchFilter;
  }

  const { facetHits, searchHits } = useSearchIndex<
    TimeseriesNewIndexFilterableAttributes,
    TimeseriesSearchable
  >(getSearchFilter());

  function handleSave() {
    if (!selectedTimeseries) {
      return;
    }
    setTimeseriesIdInForm(selectedTimeseries.id);
    close();
  }

  const tableColumns: ResponsiveTableColumn<TimeseriesSearchable>[] = [
    {
      labelKey: tKey(`subBuilding`),
      valueLookup: "subBuilding",
    },
    {
      labelKey: tKey(`system`),
      valueLookup: "system",
    },
    {
      labelKey: tKey(`name`),
      valueLookup: "name",
    },
    {
      labelKey: tKey(`label`),
      valueLookup: "translatedLabels",
      renderValue: (timeseries) => (
        <div className="flex flex-wrap gap-1">
          {timeseries.translatedLabels.map((label) => (
            <span
              key={`label_${label}`}
              className="px-1 py-0 bg-muted border border-solid border-border rounded"
            >
              {label}
            </span>
          ))}
        </div>
      ),
    },
    {
      labelKey: tKey(`unit`),
      valueLookup: "unit",
    },
  ];

  const categoryOptions = (facetHits?.types?.hits ?? []).map(({ value }) => ({
    label: t(
      `alarm-details.alarm-types.common.categories.${value}` as MessageKey,
    ),
    value,
  }));

  const subBuildingOptions = (facetHits?.subBuilding?.hits ?? []).map(
    ({ value }) => ({
      label: value,
      value,
    }),
  );

  const systemOptions = (facetHits?.system?.hits ?? []).map(({ value }) => ({
    label: `${value} ${t(
      `tfm.system.buildingPartNumber.${value.split(".")[0]}` as MessageKey,
    )}`,
    value,
  }));

  const unitOptions = (facetHits?.unit?.hits ?? []).map(({ value }) => ({
    label: value,
    value,
  }));

  return (
    <div className="w-full h-full flex flex-col gap-2">
      <h3 className="m-0">{label}</h3>
      <SearchInput />
      <div className="w-full flex flex-row gap-2 justify-between">
        <FacetHitSelect
          options={categoryOptions}
          searchFormId={SelectTimeseriesFormFields.CategorySearch}
          selectedFormId={SelectTimeseriesFormFields.Category}
          labelKey={tKey(`category`)}
        />
        <FacetHitSelect
          options={subBuildingOptions}
          searchFormId={SelectTimeseriesFormFields.BuildingSearch}
          selectedFormId={SelectTimeseriesFormFields.Building}
          labelKey={tKey(`subBuilding`)}
        />
        <FacetHitSelect
          options={systemOptions}
          searchFormId={SelectTimeseriesFormFields.SystemSearch}
          selectedFormId={SelectTimeseriesFormFields.System}
          labelKey={tKey(`system`)}
          popupMatchSelectWidth={300}
        />
        <FacetHitSelect
          options={unitOptions}
          searchFormId={SelectTimeseriesFormFields.UnitSearch}
          selectedFormId={SelectTimeseriesFormFields.Unit}
          labelKey={tKey(`unit`)}
        />
      </div>
      <div className={"w-full h-auto min-h-[30vh] overflow-y-scroll"}>
        <ResponsiveTable<TimeseriesSearchable>
          onSelect={setSelectedTimeseries}
          selected={selectedTimeseries}
          tableColumns={tableColumns}
          keyLookup="externalId"
          values={searchHits}
          scrollToSelected
        />
      </div>
      <div className="w-full flex flex-row justify-start gap-2">
        <Button size="small" ghost danger onClick={() => close()}>
          {t("ui.cancel")}
        </Button>
        <Button
          size="small"
          ghost
          type="primary"
          onClick={handleSave}
          disabled={selectedTimeseries === null}
        >
          {t(tKey(`select-button`))}
        </Button>
      </div>
    </div>
  );
}
