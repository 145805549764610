"use client";

import { Card, Checkbox, Col, Form, message, Row, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  tfm1SystemNumbers,
  tfm2SystemNumbers,
  tfm3SystemNumbers,
} from "@properate/common";
import { useTranslations, MessageKey } from "@properate/translations";
import { useFacets } from "../hooks/use-facets";

const FormItem = Form.Item;

interface Props {
  buildingId: number;
  value?: string;
  configuration?: {
    allLevelsSelectable?: boolean;
    showNonExistingSystemsCheckbox?: boolean;
    isRequired?: boolean;
  };
  subBuilding?: string | null;
}

export function MeterSystemSelect({
  value,
  configuration: {
    allLevelsSelectable = false,
    showNonExistingSystemsCheckbox = true,
    isRequired = true,
  } = {},
  subBuilding,
  buildingId,
}: Props) {
  const t = useTranslations();

  const [showNonExistingSystems, setShowNonExistingSystems] = useState(false);
  const [systemQuery, setSystemQuery] = useState<string>("");
  const { data: systemFacets, error } = useFacets(
    "system",
    {
      buildingId,
      system: null,
      translatedLabels: [],
      subBuilding: subBuilding || null,
    },
    buildingId,
    undefined,
    systemQuery,
  );

  useEffect(() => {
    if (error) {
      message.error({
        key: "MeterSystemSelect",
        content: t("common.error.something-failed-external-message", {
          message: error?.message ? error.message : "No error message",
        }),
      });
    }
  }, [error, t]);

  const handleNonExistingSystemsChange = (e: CheckboxChangeEvent) => {
    setShowNonExistingSystems(e.target.checked);
  };
  const filteredTfm1Options = tfm1SystemNumbers.filter(
    (tfm1) =>
      showNonExistingSystems ||
      (systemFacets &&
        Object.keys(systemFacets)
          .map((facet) => facet.substring(0, 1))
          .includes(tfm1)),
  );

  const tfmTreeData = filteredTfm1Options.map((tfm1) => {
    const filteredTfm2Options = tfm2SystemNumbers.filter(
      (tfm2) =>
        tfm2.substring(0, 1) === tfm1 &&
        (showNonExistingSystems ||
          (systemFacets &&
            Object.keys(systemFacets)
              .map((facet) => facet.substring(0, 2))
              .includes(tfm2))),
    );

    const title1 = t(`tfm.system.buildingPartNumber.${tfm1}` as MessageKey);

    return {
      title: `${tfm1}: ${title1}`,
      value: tfm1,
      selectable: allLevelsSelectable,
      children: filteredTfm2Options.map((tfm2) => {
        const filteredTfm3Options = tfm3SystemNumbers.filter(
          (tfm3) =>
            tfm3.substring(0, 2) === tfm2 &&
            (showNonExistingSystems ||
              (systemFacets &&
                Object.keys(systemFacets)
                  .map((facet) => facet.substring(0, 3))
                  .includes(tfm3))),
        );

        const title2 = t(`tfm.system.buildingPartNumber.${tfm2}` as MessageKey);

        return {
          title: `${tfm2}: ${title2}`,
          selectable: allLevelsSelectable,
          value: tfm2,
          children: filteredTfm3Options.map((tfm3) => {
            const title3 = t(
              `tfm.system.buildingPartNumber.${tfm3}` as MessageKey,
            );
            const tfm3Options = [...Array(200).keys()].map((nnn) => {
              return tfm3 + "." + String(nnn).padStart(3, "0");
            });
            const filteredTfm3Options = tfm3Options.filter(
              (code3) =>
                showNonExistingSystems ||
                (systemFacets && systemFacets[code3] !== undefined),
            );
            return {
              title: `${tfm3}: ${title3}`,
              selectable: allLevelsSelectable,
              value: tfm3,
              key: tfm3,
              children: filteredTfm3Options.map((value) => {
                return {
                  title: value,
                  value: value,
                  key: value,
                };
              }),
            };
          }),
        };
      }),
    };
  });

  return (
    <Row>
      <Col span={24}>
        <Card>
          {showNonExistingSystemsCheckbox && (
            <Row gutter={16}>
              <Col span={4} />
              <Col span={20}>
                <Checkbox onChange={handleNonExistingSystemsChange}>
                  {t("tfm.system.show-not-entered-systems")}
                </Checkbox>
              </Col>
            </Row>
          )}
          <FormItem
            label={t("tfm.system.system")}
            name="system"
            rules={[
              ...(isRequired
                ? [
                    {
                      required: true,
                      message: t("tfm.system.you-must-choose-a-system"),
                    },
                  ]
                : []),
            ]}
            style={{ marginTop: "12px" }}
            wrapperCol={{ span: 20 }}
          >
            <TreeSelect
              allowClear={!isRequired}
              defaultValue={value}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={tfmTreeData}
              placeholder={t("tfm.system.select-system")}
              showSearch
              onSearch={(text) => setSystemQuery(text)}
            />
          </FormItem>
        </Card>
      </Col>
    </Row>
  );
}
