import { useParams } from "react-router-dom";
import { AccessModules } from "@properate/common";
import { useOrganizationModules } from "@/services/organization";
import { OrganizationAdminModules } from "@/pages/organizationAdmin/OrganizationAdminModules";
import { allAccessModules } from "@/features/organizationAdmin";
import { updateOrganizationModules } from "@/eepApi";

export const OrganizationAdminOrganizationModules = () => {
  const { organizationId } = useParams();

  const organizationName = organizationId
    ? decodeURIComponent(organizationId)
    : "";

  const { data, isLoading } = useOrganizationModules(organizationName);

  const savedModules = data || allAccessModules;

  const onUpdate = async (values: AccessModules[]) => {
    await updateOrganizationModules(organizationName, values);
  };

  return (
    <OrganizationAdminModules
      breadcrumb={organizationName}
      savedModules={savedModules}
      organizationName={organizationName}
      isLoading={isLoading}
      onUpdate={onUpdate}
    />
  );
};
