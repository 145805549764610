import {
  QueryConstraint,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { browserFirestore } from "@properate/firebase";
import { useGetFirestoreCollection } from "@properate/ui";
import { useMemo } from "react";
import { Task } from "../schemas";
import { useTaskType } from "../contexts/task-type-context";
import { ensureFields } from "./use-get-task";

export async function getTasks(
  taskType: "tasks" | "taskTemplates" = "tasks",
  constraints?: {
    statuses?: Task["status"][];
    buildingId: number;
    pageSize?: number;
    cursor?: number;
  },
) {
  const { buildingId, cursor, pageSize, statuses = [] } = constraints ?? {};

  return getDocs(
    query(
      collection(browserFirestore, taskType),
      orderBy("createdAt"),
      ...([
        statuses.length ? where("status", "in", statuses) : null,
        buildingId && taskType === "tasks"
          ? where("buildingId", "==", buildingId)
          : null,
        pageSize ? limit(pageSize) : null,
        cursor ? startAfter(cursor) : null,
      ].filter((constraint) => constraint !== null) as QueryConstraint[]),
    ),
  ).then((result) =>
    result.docs.map((doc) => {
      const taskData = doc.data();
      taskData.snapshotId = doc.id;
      delete taskData.changelog;
      return taskData as Task;
    }),
  );
}

export function useGetTasks(serverData?: Task[]) {
  const taskType = useTaskType();

  const {
    data = [],
    isLoading,
    error,
  } = useGetFirestoreCollection<Task>(
    useMemo(() => collection(browserFirestore, taskType), [taskType]),
  );

  const usableData =
    serverData && serverData.length > data.length ? serverData : data;

  return {
    data: usableData?.map(ensureFields),
    isLoading,
    error,
  };
}

export function useGetTasksByEnabledKPIs(enabledKPIs?: string[]) {
  const taskType = useTaskType();

  const {
    data = [],
    isLoading,
    error,
  } = useGetFirestoreCollection<Task>(
    useMemo(
      () =>
        enabledKPIs?.length
          ? query(
              collection(browserFirestore, taskType),
              where("status", "==", "InProgress"),
            )
          : null,
      [enabledKPIs, taskType],
    ),
  );

  return {
    data: data?.map(ensureFields),
    isLoading,
    error,
  };
}

export function useGetInProgressTasks() {
  const taskType = useTaskType();

  const {
    data = [],
    isLoading,
    error,
  } = useGetFirestoreCollection<Task>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, taskType),
          where("status", "==", "InProgress"),
        ),
      [taskType],
    ),
  );

  return {
    data: data?.map(ensureFields),
    isLoading,
    error,
  };
}

export function useGetBuildingTasks(buildingIds: readonly number[]) {
  const taskType = useTaskType();

  const { data, isLoading, error } = useGetFirestoreCollection<Task>(
    useMemo(
      () =>
        buildingIds && buildingIds.length <= 30
          ? query(
              collection(browserFirestore, taskType),
              where("buildingId", "in", buildingIds),
            )
          : collection(browserFirestore, taskType),
      [buildingIds, taskType],
    ),
  );
  if (error) {
    console.error("error", error);
  }

  return useMemo(
    () => ({
      isLoading,
      error,
      data:
        buildingIds.length > 30
          ? data?.filter((task) => buildingIds.includes(task.buildingId))
          : data,
    }),
    [data, buildingIds, isLoading, error],
  );
}
