import useSWR from "swr";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useFloorPlanPinTimeseriesId } from "./useFloorPlanPinTimeseriesId";

export function useFloorPlanTimeseries(timeseriesId: number | null) {
  const { client } = useCogniteClient();

  return useSWR(
    timeseriesId ? ["timeseries", timeseriesId] : null,
    async ([_, timeseriesId]) =>
      client.timeseries.retrieve([{ id: timeseriesId }]).then((timeseries) => {
        if (timeseries.length > 0) {
          return timeseries[0];
        }
      }),
    {
      revalidateOnFocus: false,
    },
  );
}

export function useFloorPlanPinTimeseries() {
  const timeseriesId = useFloorPlanPinTimeseriesId();

  return useFloorPlanTimeseries(timeseriesId);
}
