import useSWR from "swr";
import { useMemo, useState } from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import { App } from "antd";
import { useTranslations } from "@properate/translations";
import { useLoaderData } from "react-router-dom";
import { useHandleApiError } from "@/utils/api";
import { listGroup } from "@/eepApi";
import { AlertGroupLoader } from "@/features/alarms";

export const useAlertGroupNames = (
  buildingExternalId: string | null,
  owner?: string | null,
  currentGroupId?: string,
) => {
  const handleAPIError = useHandleApiError();

  const {
    data: groups = [],
    error,
    isLoading: isLoadingGroups,
  } = useSWR(
    ["alertGroups", buildingExternalId, owner],
    async () => {
      if (!buildingExternalId && !owner) {
        return [];
      }
      const { items } = await listGroup({
        filter: {
          building_external_id: buildingExternalId,
          owner: owner || undefined,
        },
      });
      return items;
    },
    {
      keepPreviousData: true,
    },
  );

  if (error !== undefined) {
    handleAPIError(error);
  }

  const groupNames = (
    currentGroupId
      ? groups.filter((group) => group.id !== currentGroupId)
      : groups
  ).map((group) => group.name);

  return { groupNames, isLoadingGroups };
};

export type FormValues = {
  name: string;
  owner?: string;
};

export const useAlertGroupMembers = (
  initialMembers: AlertGroupLoader["members"] = [],
) => {
  const [members, setMembers] = useState(initialMembers);
  const { message } = App.useApp();
  const t = useTranslations();

  const validateMembers = () => {
    return !members.some((member) => {
      if (!member.defaultUserEmail) {
        message.error(t("alert-group.please-select-user"));
        return true;
      }

      if (!member.notifyByPhone && !member.notifyByEmail) {
        message.error(t("alert-group.please-select-notification-method"));
        return true;
      }

      if (member.notificationPhone) {
        try {
          const valueParsedAsPhone = parsePhoneNumber(
            member.notificationPhone,
            "NO",
          );

          if (!valueParsedAsPhone.isValid()) {
            message.error(t("alert-group.invalid-phone-number"));
            return true;
          }
        } catch (e) {
          message.error(t("alert-group.invalid-phone-number"));
          return true;
        }
      }

      if (member.notifyByPhone && !member.notificationPhone) {
        message.error(t("alert-group.please-enter-phone-number"));
        return true;
      }

      return false;
    });
  };

  return { members, setMembers, validateMembers };
};

export const useUnsavedChangesAlertGroups = (
  members: AlertGroupLoader["members"],
  name: string,
) => {
  const pageData = useLoaderData() as AlertGroupLoader;

  return useMemo(() => {
    const pageMemberEmails =
      pageData.members?.map((member) => member.defaultUserEmail) || [];
    const memberEmails = members.map((member) => member.defaultUserEmail);
    const isMembersSame =
      JSON.stringify(pageMemberEmails) === JSON.stringify(memberEmails);
    const isNameSame = pageData.name === name;
    return !isMembersSame || !isNameSame;
  }, [members, name, pageData.members, pageData.name]);
};
