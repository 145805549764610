import { useTranslations } from "@properate/translations";
import { App, Form, Tag } from "antd";
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { useState } from "react";
import * as React from "react";
import { useTheme } from "styled-components";
import FullLayout from "@/layout/FullLayout";
import usePageTitle from "@/hooks/usePageTitle";
import {
  AlertGroupAPI,
  AlertGroupUI,
  OrgAlertGroupLoader,
} from "@/features/alarms";
import { useGroupDetailsActionButtons } from "@/pages/alarmSystem/groupDetails/useGroupDetailsActionButtons";
import { createGroup, deleteGroup, getGroup, updateGroup } from "@/eepApi";
import { GroupDetailsForm } from "@/pages/alarmSystem/groupDetails/GroupDetailsForm";
import {
  FormValues,
  useAlertGroupMembers,
  useAlertGroupNames,
  useUnsavedChangesAlertGroups,
} from "@/pages/alarmSystem/groupDetails/utils";

export const OrgGroupDetailsPage = () => {
  const t = useTranslations();
  usePageTitle(t("org-group.title"));
  const pageData = useLoaderData() as OrgAlertGroupLoader;
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm<FormValues>();
  const theme = useTheme();
  const revalidator = useRevalidator();

  const owner = Form.useWatch("owner", form);
  const name = Form.useWatch("name", form);

  const onCancel = () => {
    navigate("../org/alertGroups");
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async () => {
    if (!pageData.id) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteGroup(pageData.id);
    } catch (e) {
      setIsDeleting(false);
      message.error(t("alert-group.delete-failed"));
      console.error("error", e);
      return;
    }

    navigate("../org/alertGroups");
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const { groupNames, isLoadingGroups } = useAlertGroupNames(
    null,
    owner,
    pageData.id,
  );

  const actionButtons = useGroupDetailsActionButtons({
    onCancel,
    onDelete,
    isDeleting,
    isLoadingDependedData: false, // TODO: implement
    isUpdating,
    isLoadingGroups,
    isShowingUpdateValidationWarning: false,
    onShowUpdateModal: () => {
      // ignore
    },
  });

  const onSubmit = async (values: FormValues) => {
    if (!validateMembers()) {
      return;
    }
    setIsUpdating(true);

    if (pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          owner: values.owner,
          buildingExternalId: null,
          members,
        });

        await updateGroup(pageData.id, data);
        message.success(t("alert-group.update-success"));
        revalidator.revalidate();
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.update-failed"));
        console.error("error", e);
        return;
      }
    }

    if (!pageData.id) {
      try {
        const data = AlertGroupAPI.parse({
          id: pageData.id,
          name: values.name,
          owner: values.owner,
          buildingExternalId: null,
          members,
        });
        const group = await createGroup(data);
        message.success(t("alert-group.update-success"));
        navigate(`../org/alertGroups/${group.id}`);
      } catch (e) {
        setIsUpdating(false);
        message.error(t("alert-group.create-failed"));
        console.error("error", e);
        return;
      }
    }

    setIsUpdating(false);
  };

  const { members, setMembers, validateMembers } = useAlertGroupMembers(
    pageData.members,
  );

  const hasUnsavedChanges = useUnsavedChangesAlertGroups(members, name);

  const getTitle = () => {
    if (!pageData.id) {
      return t("alert-group.new-group");
    }
    return pageData.name;
  };

  return (
    <FullLayout
      hideSearchInput
      pageName={getTitle()}
      headerRight={[
        ...(hasUnsavedChanges
          ? [
              <Tag key="warning" className="ml-2" color={theme.warning}>
                {t("alert-group.unsaved-changes")}
              </Tag>,
            ]
          : []),
        ...actionButtons,
      ]}
    >
      <div className="mx-6">
        <GroupDetailsForm
          form={form}
          onSubmit={onSubmit}
          groupNames={groupNames}
          membersData={{ members, setMembers, validateMembers }}
        />
      </div>
    </FullLayout>
  );
};

OrgGroupDetailsPage.loader = async ({
  params: { alertGroupId },
}: LoaderFunctionArgs) => {
  if (alertGroupId === "new") {
    return {
      owner: "",
    } as OrgAlertGroupLoader;
  }

  const group = await getGroup(alertGroupId!);

  return AlertGroupUI.parse(group);
};
