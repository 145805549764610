import { Form, Input, FormInstance, Select, Checkbox } from "antd";
import * as React from "react";
import { useLoaderData } from "react-router-dom";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { GroupDetailsTable } from "@/pages/alarmSystem/groupDetails/GroupDetailsTable";
import {
  AlertGroupLoader,
  AlertGroupMemberUI,
  OrgAlertGroupLoader,
} from "@/features/alarms";
import { useRootAssets } from "@/hooks/useRootAssets";
import { FormValues } from "./utils";

type Props = {
  form: FormInstance<FormValues>;
  onSubmit: (values: FormValues) => void;
  groupNames: string[];
  currentBuildingOwner?: string;
  membersData: {
    members: AlertGroupMemberUI[];
    setMembers: Dispatch<SetStateAction<AlertGroupMemberUI[]>>;
    validateMembers: () => boolean;
  };
};

export const GroupDetailsForm = (props: Props) => {
  const pageData = useLoaderData() as AlertGroupLoader | OrgAlertGroupLoader;
  const user = useUser();
  const t = useTranslations();
  const hasOwner = "owner" in pageData;
  const rootAssets = useRootAssets();
  const isOwnerLevel = !props.currentBuildingOwner;
  const ownerOptions = useMemo(
    () =>
      [...new Set(rootAssets.map((asset) => asset.metadata?.owner))]
        .filter(Boolean)
        .map((owner) => ({
          value: owner,
        })),
    [rootAssets],
  );

  const owner = Form.useWatch("owner", props.form);
  const name = Form.useWatch("name", props.form);

  useEffect(() => {
    if (name) {
      props.form.validateFields(["name"]);
    }
  }, [props.groupNames, name, props.form]);

  const onChangeGroupType = (isOwnerGroup: boolean) => {
    if (isOwnerGroup) {
      props.form.setFieldValue("owner", props.currentBuildingOwner);
    } else {
      props.form.setFieldValue("owner", undefined);
    }
  };

  return (
    <Form<FormValues>
      form={props.form}
      name={"alert-group"}
      layout="vertical"
      initialValues={{
        name: pageData.name,
        ...(hasOwner && { owner: pageData.owner }),
      }}
      onFinish={props.onSubmit}
      disabled={!user.isAdmin}
    >
      {isOwnerLevel && (
        <Form.Item
          name="owner"
          label={t("org-group.owner")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={ownerOptions} />
        </Form.Item>
      )}
      <Form.Item
        label={t("alert-group.name")}
        name="name"
        rules={[
          {
            required: true,
          },
          {
            validator: (_, value) => {
              if (props.groupNames.includes(value)) {
                return Promise.reject(t("alert-group.name-exists"));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input required />
      </Form.Item>
      {!isOwnerLevel && (
        <Form.Item name="owner">
          <Checkbox
            checked={!!owner}
            onChange={(e) => onChangeGroupType(e.target.checked)}
          >
            {t("alert-group.set-as-owner-group", {
              owner: props.currentBuildingOwner,
            })}
          </Checkbox>
        </Form.Item>
      )}
      <GroupDetailsTable
        members={props.membersData.members}
        setMembers={props.membersData.setMembers}
        validateMembers={props.membersData.validateMembers}
      />
    </Form>
  );
};
