import { browserFirestore } from "@properate/firebase";
import { useGetFirestoreCollection } from "@properate/ui";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { HeatMap } from "@/features/heatmap";
import { Gauge } from "@/features/gauges";
import { Analysis, isScatterplotAnalysis } from "@/features/analysis";

const collectionAnalysis = collection(browserFirestore, "analysis");
const collectionGauge = collection(browserFirestore, "gauge");
const collectionHeatMap = collection(browserFirestore, "heatMap");

interface HookParameters {
  buildingId: number;
  email: string;
  url?: string;
}

export function useGetGauge({ buildingId, email }: HookParameters) {
  const { data = [] } = useGetFirestoreCollection<Gauge>(
    useMemo(
      () =>
        query(
          collectionGauge,
          where("buildingId", "==", Number(buildingId)),
          where("owner", "==", email),
          orderBy("created", "desc"),
        ),
      [buildingId, email],
    ),
  );
  return data;
}

export function useGetAnalyses({ buildingId, email, url }: HookParameters) {
  const isScatterplotAnalysisRoute = url?.includes("scatterplot");
  const isSimpleAnalysisRoute = url?.includes("simple");
  const { data = [] } = useGetFirestoreCollection<Analysis>(
    useMemo(
      () =>
        query(
          collectionAnalysis,
          where("buildingId", "==", Number(buildingId)),
          where("owner", "==", email),
          orderBy("created", "desc"),
        ),
      [buildingId, email],
    ),
  );
  return data.filter((analysisDoc) => {
    if (isScatterplotAnalysisRoute) {
      return isScatterplotAnalysis(analysisDoc);
    }
    if (isSimpleAnalysisRoute) {
      return !isScatterplotAnalysis(analysisDoc);
    }
    return true;
  });
}

export function useGetHeatMaps({ buildingId, email }: HookParameters) {
  const { data = [] } = useGetFirestoreCollection<HeatMap>(
    useMemo(
      () =>
        query(
          collectionHeatMap,
          where("buildingId", "==", Number(buildingId)),
          where("owner", "==", email),
          orderBy("created", "desc"),
        ),
      [buildingId, email],
    ),
  );
  return data;
}

export function useGetDashboardData({
  buildingId,
  email,
  url,
}: HookParameters) {
  const gauges = useGetGauge({ buildingId, email });
  const analyses = useGetAnalyses({ buildingId, email, url });
  const heatMaps = useGetHeatMaps({ buildingId, email });
  return {
    gauges,
    analyses,
    heatMaps,
  };
}
