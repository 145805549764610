import { PageHeader } from "@ant-design/pro-layout";
import { useTranslations } from "@properate/translations";
import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table/interface";
import { App, Button, Checkbox, Space } from "antd";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { AccessModules } from "@properate/common";
import { SearchInput } from "@/components/SearchIndex";
import { CompactContent } from "@/components/CompactContent";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { allAccessModules } from "@/features/organizationAdmin";
import useDebounceFn from "@/hooks/useDebounceFn";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";

export const OrganizationAdminModules = ({
  organizationName,
  buildingContent,
  savedModules,
  isLoading,
  onUpdate,
  breadcrumb,
}: {
  organizationName: string;
  buildingContent?: React.ReactNode;
  savedModules: AccessModules[];
  isLoading: boolean;
  onUpdate: (values: AccessModules[]) => Promise<void>;
  breadcrumb: string;
}) => {
  const t = useTranslations();
  const currentBuildingId = useCurrentBuildingId();

  const { message } = App.useApp();

  const [selectedModules, setSelectedModules] = useState<
    AccessModules[] | null
  >(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && savedModules) {
      setSelectedModules(savedModules);
    }
  }, [isLoading, savedModules]);

  useBuildingPageTitle(t("organization-admin.access.title"));

  const modules = allAccessModules.map((moduleValue) => ({
    title: t(`organization-admin.access.modules.${moduleValue}`),
    value: moduleValue,
  }));

  const [search, setSearch] = useState<string>("");

  const searchTerms = search.trim().split(" ");

  const sortedModules = modules?.filter((item) =>
    searchTerms.every((term: string) =>
      (item.title || "").toLowerCase().includes(term.toLowerCase()),
    ),
  );

  const { run: onUpdateDebounced } = useDebounceFn(
    async (values) => {
      await onUpdate(values);
      message.success(t("organization-admin.access.update-success"));
      setIsUpdating(false);
    },
    2000,
    { cancelOnUnmount: false },
  );

  const onUpdateModules = (event: CheckboxChangeEvent) => {
    const { name, checked } = event.target;
    const value = name as AccessModules;
    setSelectedModules((modules = []) => {
      if (!modules) return modules;
      let newModules;
      if (checked) {
        newModules = [...modules, value];
      } else {
        newModules = modules.filter((module) => module !== value);
      }
      setIsUpdating(true);
      onUpdateDebounced(newModules);
      return newModules;
    });
  };

  const onSelectAll = () => {
    setSelectedModules((modules) => {
      const newModules = allAccessModules.every((module) =>
        (modules || []).includes(module),
      )
        ? []
        : allAccessModules;
      onUpdateDebounced(newModules);
      return newModules;
    });
  };

  const columns: ColumnsType<(typeof modules)[number]> = [
    {
      key: "active",
      title: () => {
        return (
          <Button onClick={onSelectAll} type="primary">
            {(selectedModules || []).length !== allAccessModules.length
              ? t("organization-admin.access.select-all")
              : t("organization-admin.access.deselect-all")}
          </Button>
        );
      },
      width: 120,
      dataIndex: "value",
      align: "center",
      render: (value: AccessModules) => {
        return (
          <Checkbox
            onChange={onUpdateModules}
            checked={(selectedModules || []).includes(value)}
            name={value}
          />
        );
      },
    },
    {
      key: "name",
      title: t("organization-admin.access.columns.name"),
      dataIndex: "title",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (name: string) => (
        <ProperateHighlighter
          searchWords={searchTerms}
          textToHighlight={name}
          title={name}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={
          <Space>
            <Link to={`/asset/${currentBuildingId}/organization-admin`}>
              {t("organization-admin.title")}
            </Link>
            <RightOutlined />
          </Space>
        }
        subTitle={breadcrumb}
        className="site-page-header"
        extra={[
          <SearchInput
            key="search"
            allowClear
            placeholder={t("organization-admin.access.search")}
            onChange={(event) => setSearch(event.target.value)}
          />,
        ]}
      />
      <CompactContent>
        <section className="mb-2">
          <dl className="flex">
            <dt
              className="text-xl font-bold"
              data-testid={`organization-name/${organizationName}`}
            >
              {organizationName.toUpperCase()}
              {isUpdating && (
                <span className="ml-2">
                  <SpinnerWithDelay isLoading>
                    <div />
                  </SpinnerWithDelay>
                </span>
              )}
            </dt>
          </dl>
          {buildingContent}
        </section>
        <TableWithoutDefaultSort
          loading={isLoading}
          pagination={false}
          columns={columns}
          dataSource={sortedModules}
          rowKey="value"
        />
      </CompactContent>
    </>
  );
};
