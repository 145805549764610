import { useLoaderData } from "react-router-dom";
import {
  InfiniteData,
  QueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { AlertGroupPage, OrgAlertGroupsLoader } from "@/features/alarms";
import { deleteGroup, listGroup } from "@/eepApi";
import type { OrderBy } from "@properate/common";

export const getUrl = (
  search?: string,
  sort?: { property: string; order: string },
  filter?: { owner?: string },
) => {
  const url = `/org/alertGroups`;
  const searchParams = new URLSearchParams();

  if (search) {
    searchParams.append("search", search);
  }
  if (sort) {
    searchParams.append("sort", sort.property);
    searchParams.append("order", sort.order);
  }
  if (filter?.owner) {
    searchParams.append("owner", filter.owner);
  }

  const searchParamsString = searchParams.toString();
  return searchParamsString ? `${url}?${searchParamsString}` : url;
};

const PAGE_LIMIT = 50;
const queryClient = new QueryClient();

const fetchGroups = async (
  pageData: OrgAlertGroupsLoader,
  pageParam?: string,
) => {
  {
    const order_by = pageData.sort
      ? (`${pageData.sort
          ?.property}:${pageData.sort?.order.toUpperCase()}` as OrderBy)
      : undefined;

    try {
      const groups = await listGroup({
        search: pageData.search || "",
        limit: PAGE_LIMIT,
        filter: {
          building_external_id: null,
          owner: pageData.filter?.owner,
        },
        order_by,
        cursor: pageParam,
      });

      const mappedItems = groups.items.map((item) => ({
        ...item,
        membersCount: item.members.length,
      }));

      return {
        items: mappedItems,
        nextCursor: groups.next_cursor,
      };
    } catch (error) {
      console.error("error", error);
    }

    return {
      items: [],
      nextCursor: undefined,
    };
  }
};

const getQuery = (pageData: OrgAlertGroupsLoader) => ({
  queryKey: ["alertGroups", pageData.search, pageData.sort, pageData.filter],
  queryFn: ({ pageParam }: { pageParam?: string }) =>
    fetchGroups(pageData, pageParam),
  initialPageParam: undefined,
  getNextPageParam: (lastPage?: { nextCursor: string | undefined }) => {
    return lastPage?.nextCursor;
  },
});

export const useGroupsData = () => {
  const pageData = useLoaderData() as OrgAlertGroupsLoader;
  const query = getQuery(pageData);
  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery(
    query,
    queryClient,
  );
  const dataTable = data?.pages.flatMap((page) => page.items) || [];
  const onDelete = async (id: string) => {
    await deleteGroup(id);
    queryClient.setQueryData<InfiniteData<AlertGroupPage>>(
      query.queryKey,
      (data) => {
        return data
          ? {
              ...data,
              pages: data.pages.map((page) => ({
                ...page,
                items: page.items.filter((item) => item.id !== id),
              })),
            }
          : data;
      },
    );
  };

  return {
    groups: dataTable,
    onDelete,
    isLoading: status === "pending" && !isFetchingNextPage,
    onLoadMore: fetchNextPage,
  };
};
