import { useState } from "react";
import { Button, Col, Modal, Row, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  AlarmWithSnapshotId,
  convertHoursToMS,
  formatSubBuildingFromExternalId,
  formatTimeseriesName,
  getParsedRefreshRate,
  getSchedule,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { Asset, Timeseries } from "@cognite/sdk";
import { useUser } from "@properate/auth";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import {
  getAssetById,
  getAssets,
  getAssetsWithLabels,
  getTimeseriesForAsset,
  getTimeseriesWithLabels,
} from "@/utils/helpers";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { BuildingSelectionModal } from "@/features/buildings";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { ALARM_TYPE_CONFIG } from "../common/SchemaGraph/AlarmUtils";
import { getAlarmTimeseries } from "./utils/getAlarmTimeseries";

interface Props {
  buildingId?: number;
  iconOnly?: boolean;
  onOpenAlarmModal: (alarm: AlarmWithSnapshotId) => void;
}
/* eslint react/jsx-no-literals:0 */
// component wll be removed
export const NewAlarmButton = ({
  buildingId,
  iconOnly = false,
  onOpenAlarmModal,
}: Props) => {
  const [subBuildingId, setSubBuildingId] = useState<number>();
  const [subBuildings, setSubBuildings] = useState<Asset[]>([]);
  const [buildingAddress, setBuildingAddress] = useState<string>();
  const [showBuildingSelect, setShowBuildingSelect] = useState(false);
  const [selectedBuildingId, setSelectedBuildingId] = useState(buildingId);
  const user = useUser();
  const { client } = useCogniteClient();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState<{
    epredTimeseriesMax: Timeseries[];
    epredTimeseriesMin: Timeseries[];
    mainMeterSchedule: { interval: string; hour?: string };
    mainMeterTimeseries: Timeseries[];
    rh002Norm: Timeseries | null;
    rt001Max: Timeseries | null;
    rt001Min: Timeseries | null;
    ry001Max: Timeseries | null;
    ry002Max: Timeseries | null;
  }>();
  const [selectTimeseries, setSelectTimeseries] = useState<{
    type: string;
    timeseries?: {
      ids: number[];
      name: string;
      unit: string;
      color: string;
    };
    schedule?: { interval: string; hour?: string };
    category?: "building";
  } | null>(null);

  const setAlarm = (alarmOverride: any) => {
    const alarm: AlarmWithSnapshotId = {
      buildingId: selectedBuildingId!,
      buildingAddress: buildingAddress || "",
      date: new Date(),
      owner: user.email,
      severity: "WARNING",
      alarmPeriods: [],
      archived: null,
      ...alarmOverride,
    };

    alarm.name = ALARM_TYPE_CONFIG[alarm.type].generateName
      ? ALARM_TYPE_CONFIG[alarm.type].generateName(alarm)
      : "";

    onOpenAlarmModal(alarm);
    setShowModal(undefined);
  };

  async function handleTimeseriesChange(subBuildingId: number) {
    setLoading(true);
    setSubBuildingId(subBuildingId);

    const alarmTimeseriesList = await getAlarmTimeseries(subBuildingId);
    const mainMeterTimeseries = (
      await getTimeseriesWithLabels(client!, selectedBuildingId!, [
        "oe_common_main_meter",
      ])
    )
      .sort((a, b) =>
        getSystemCodeFromExternalId(a.externalId!).localeCompare(
          getSystemCodeFromExternalId(b.externalId!),
        ),
      )
      .slice(0, 1);

    const mainMeterSchedule = mainMeterTimeseries.reduce(
      (prev, current) => {
        const rate = getParsedRefreshRate(current.metadata?.refresh_rate);
        if (prev.interval === "24h") {
          return prev;
        }
        if (rate[0] === "24h") {
          return { interval: rate[0], hour: rate[1] };
        }
        return prev;
      },
      { interval: "5m" },
    );

    const epredTimeseriesMax: Timeseries[] = (
      await Promise.all(
        mainMeterTimeseries.map(async (ts) => {
          const assets = (
            await getAssets({ client, parentId: ts.assetId })
          ).filter((a) => a.metadata?.specie === "RMSE-max");
          if (assets[0]) {
            return getTimeseriesForAsset(client!, assets[0].id);
          }
          return null;
        }),
      )
    ).filter((ts): ts is Timeseries => ts !== null);
    const epredTimeseriesMin: Timeseries[] = (
      await Promise.all(
        mainMeterTimeseries.map(async (ts) => {
          const assets = (
            await getAssets({ client, parentId: ts.assetId })
          ).filter((a) => a.metadata?.specie === "RMSE-min");

          if (assets[0]) {
            return getTimeseriesForAsset(client, assets[0].id);
          }
          return null;
        }),
      )
    ).filter((ts): ts is Timeseries => ts !== null);

    setShowModal({
      rt001Max:
        alarmTimeseriesList.find(
          (timeseries) => timeseries.name === "RT001 max",
        ) || null,
      rt001Min:
        alarmTimeseriesList.find(
          (timeseries) => timeseries.name === "RT001 min",
        ) || null,
      ry001Max:
        alarmTimeseriesList.find(
          (timeseries) => timeseries.name === "RY001 max",
        ) || null,
      ry002Max:
        alarmTimeseriesList.find(
          (timeseries) => timeseries.name === "RY002 max",
        ) || null,
      rh002Norm:
        alarmTimeseriesList.find(
          (timeseries) => timeseries.name === "RH002 norm",
        ) || null,
      epredTimeseriesMax,
      epredTimeseriesMin,
      mainMeterTimeseries,
      mainMeterSchedule: mainMeterSchedule || {},
    });
    setLoading(false);
  }

  async function startCreation(selectedBuildingId: number) {
    let subBuildingId = 0;

    const building = await getAssetById(client, selectedBuildingId);
    if (building) {
      setBuildingAddress(building.metadata?.Adresse || "");

      const subBuildings = await getAssetsWithLabels(
        client,
        selectedBuildingId,
        ["building"],
      );

      if (subBuildings.length > 0) {
        setSubBuildingId(subBuildings[0].id);
        subBuildingId = subBuildings[0].id;
      } else {
        setSubBuildingId(selectedBuildingId);
        subBuildingId = selectedBuildingId;
      }
      setSubBuildings(subBuildings);
    }

    await handleTimeseriesChange(subBuildingId);
    setShowBuildingSelect(false);
    setLoading(false);
  }

  const handleSelectTimeseries = ([timeseries, compareTo]: Timeseries[]) => {
    setAlarm({
      ...selectTimeseries,
      timeseries: {
        ids: [timeseries.id],
        name: formatTimeseriesName(timeseries),
        unit: timeseries.unit,
        color: DEFAULT_GLOBAL_THEME.color.ORANGE,
      },
      compareWithTsMax:
        selectTimeseries?.type === "CUSTOM_TIMESERIES_MAX"
          ? {
              ids: [compareTo.id],
              name: formatTimeseriesName(compareTo),
              schedule: getSchedule(compareTo),
            }
          : undefined,
      compareWithTsMin:
        selectTimeseries?.type === "CUSTOM_TIMESERIES_MIN"
          ? {
              ids: [compareTo.id],
              name: compareTo.name,
              schedule: getSchedule(compareTo),
            }
          : undefined,
      schedule: getSchedule(timeseries),
      category: "building",
      ...(selectTimeseries?.type === "NO_DATAPOINTS"
        ? { maxTimeSinceLatestDatapoint: convertHoursToMS(1) }
        : null),
    });
    setSelectTimeseries(null);
  };

  return (
    <>
      <Button
        disabled={user.isViewer}
        loading={loading}
        data-testid={"new-alarm"}
        onClick={() => {
          if (!selectedBuildingId) {
            return setShowBuildingSelect(true);
          }

          startCreation(selectedBuildingId);
        }}
        icon={<PlusOutlined />}
        type="primary"
      >
        {!iconOnly && "Ny"}
      </Button>
      {showBuildingSelect && (
        <BuildingSelectionModal
          onSelect={(buildingId) => {
            setSelectedBuildingId(buildingId);
            setShowBuildingSelect(false);
            startCreation(buildingId);
          }}
          onClose={() => {
            setSelectedBuildingId(undefined);
            setShowBuildingSelect(false);
            setLoading(false);
          }}
        />
      )}
      {showModal && (
        <Modal
          open
          onCancel={() => setShowModal(undefined)}
          okButtonProps={{ style: { display: "none" } }}
          destroyOnClose
        >
          <h2>Alarmer for enkeltbygg</h2>
          <Space direction="vertical" style={{ width: "100%" }}>
            {subBuildings.length > 1 && (
              <Row gutter={24}>
                <Col flex="none">Delbygg</Col>
                <Col flex="auto">
                  <Select
                    data-testid="select-subBuilding"
                    style={{ width: "100%" }}
                    value={subBuildingId}
                    onChange={handleTimeseriesChange}
                    options={subBuildings.map((subBuilding) => ({
                      ...(subBuilding.externalId
                        ? {
                            label: formatSubBuildingFromExternalId(
                              subBuilding.externalId,
                            ),
                          }
                        : null),
                      value: subBuilding.id,
                    }))}
                  />
                </Col>
              </Row>
            )}
            <SpinnerWithDelay
              isLoading={loading}
              style={{
                display: "grid",
                height: "50px",
                placeContent: "center",
              }}
            >
              <Button
                block
                disabled={showModal.rt001Max === null}
                data-testid={"room-temperature-over-XX-°C"}
                onClick={async () => {
                  const timeseries = showModal.rt001Max!;
                  const alarm = {
                    type: "ROOM_TEMPERATURE_MAX",
                    schedule: getSchedule(timeseries),
                    timeseries: {
                      ids: [timeseries.id],
                      name: `${timeseries.name} ${timeseries.description}`,
                      unit: timeseries.unit,
                      color: DEFAULT_GLOBAL_THEME.color.BLUE,
                    },
                    max: 26,
                    category: "temperature",
                  };
                  setAlarm(alarm);
                }}
              >
                Alarm ved rom temperatur over XX °C
              </Button>
              <Button
                block
                disabled={showModal.rt001Min === null}
                data-testid={"room-temperature-under-XX-°C"}
                onClick={async () => {
                  const timeseries = showModal.rt001Min!;
                  const alarm = {
                    type: "ROOM_TEMPERATURE_MIN",
                    schedule: getSchedule(timeseries),
                    timeseries: {
                      ids: [timeseries.id],
                      name: timeseries.name,
                      unit: timeseries.unit,
                      color: DEFAULT_GLOBAL_THEME.color.BLUE,
                    },
                    min: 19,
                    category: "temperature",
                  };
                  setAlarm(alarm);
                }}
              >
                Alarm ved rom temperatur under XX °C
              </Button>
              <Button
                block
                disabled={showModal.ry002Max === null}
                onClick={() => {
                  const timeseries = showModal.ry002Max!;
                  const alarm = {
                    type: "VOC_MAX",
                    schedule: getSchedule(timeseries),
                    timeseries: {
                      ids: [timeseries.id],
                      name: timeseries.name,
                      unit: timeseries.unit,
                      color: DEFAULT_GLOBAL_THEME.color.GREEN,
                    },
                    max: 1000,
                    category: "gass",
                  };
                  setAlarm(alarm);
                }}
              >
                Alarm når VOC er over XX ppb
              </Button>
              <Button
                block
                disabled={showModal.ry001Max === null}
                onClick={() => {
                  const timeseries = showModal.ry001Max!;
                  const alarm = {
                    type: "CO2_MAX",
                    schedule: getSchedule(timeseries),
                    timeseries: {
                      ids: [timeseries.id],
                      name: timeseries.name,
                      unit: timeseries.unit,
                      color: DEFAULT_GLOBAL_THEME.color.GREY,
                    },
                    max: 1000,
                    category: "gass",
                  };
                  setAlarm(alarm);
                }}
              >
                Alarm når Co2 er over XX ppm
              </Button>
              <Button
                block
                disabled={showModal.rh002Norm === null}
                onClick={() => {
                  const timeseries = showModal.rh002Norm!;
                  const alarm = {
                    type: "HUMIDITY_ALERT",
                    name: "Smart-varsel ved fukt på gulv (i hele bygget)",
                    schedule: getSchedule(timeseries),
                    timeseries: {
                      ids: [timeseries.id],
                      name: timeseries.name,
                      unit: timeseries.unit,
                      color: DEFAULT_GLOBAL_THEME.color.BLUE,
                    },
                    max: 50,
                    category: "humidity",
                  };
                  setAlarm(alarm);
                }}
              >
                Smart-varsel ved fukt på gulv (i hele bygget)
              </Button>
            </SpinnerWithDelay>
          </Space>
          <h2>Egendefinerte alarmer</h2>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              data-testid={"custom-max"}
              block
              onClick={() =>
                setSelectTimeseries({
                  type: "CUSTOM_MAX",
                })
              }
            >
              Alarm hvis verdien er høyere enn terskelverdi
            </Button>
            <Button
              block
              onClick={() =>
                setSelectTimeseries({
                  type: "CUSTOM_MIN",
                })
              }
            >
              Alarm hvis verdien er lavere enn terskelverdi
            </Button>
            <Button
              block
              onClick={() => {
                setSelectTimeseries({
                  type: "NO_DATAPOINTS",
                });
              }}
            >
              Alarm hvis ingen datapunkter har kommet inn
            </Button>
            <Button
              block
              onClick={() =>
                setSelectTimeseries({
                  type: "CUSTOM_TIMESERIES_MAX",
                })
              }
            >
              Alarm hvis verdien er høyere enn tidsserie
            </Button>
            <Button
              block
              onClick={() =>
                setSelectTimeseries({
                  type: "CUSTOM_TIMESERIES_MIN",
                })
              }
            >
              Alarm hvis verdien er lavere enn tidsserie
            </Button>
          </Space>
          <h2>Alarmer for alle bygg</h2>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button
              block
              onClick={() => {
                const schedule = showModal.mainMeterTimeseries.reduce(
                  (prev, current) => {
                    const rate = getParsedRefreshRate(
                      current.metadata?.refresh_rate,
                    );
                    if (prev.interval === "24h") {
                      return prev;
                    }
                    if (rate[0] === "24h") {
                      return { interval: rate[0], hour: rate[1] };
                    }
                    return prev;
                  },
                  { interval: "5m" },
                );

                const alarm = {
                  type: "EFFECT_GUARD",
                  name: "Effektvokter - Alarm ved maks effekt (kWh/h)",
                  schedule,
                  timeseries: {
                    ids: showModal.mainMeterTimeseries.map((ts) => ts.id),
                    name: "Total energibruk",
                    unit: "kWh",
                    color: DEFAULT_GLOBAL_THEME.color.GREEN,
                  },
                  max: 30,
                  category: "energy",
                };
                setAlarm(alarm);
              }}
            >
              Effektvokter - Alarm ved maks effekt (kWh/h)
            </Button>
            <Button
              block
              disabled={showModal.epredTimeseriesMax.length === 0}
              onClick={() =>
                setAlarm({
                  type: "EPRED_MAX",
                  name: "Alarm når forbruk overstiger beregnet maks forbruk",
                  schedule: showModal.mainMeterSchedule,
                  timeseries: {
                    ids: showModal.mainMeterTimeseries.map((ts) => ts.id),
                    name: "Totalt forbruk",
                    unit: "kWh",
                    color: DEFAULT_GLOBAL_THEME.color.BLUE,
                  },
                  compareWithTsMax: {
                    ids: showModal.epredTimeseriesMax.map((ts) => ts.id),
                    name: "Beregnet maks forbruk",
                    buffer: 0,
                  },
                  category: "energy",
                })
              }
            >
              Alarm når forbruk overstiger beregnet forbruk
            </Button>
            <Button
              block
              disabled={showModal.epredTimeseriesMin.length === 0}
              onClick={() =>
                setAlarm({
                  type: "EPRED_MIN",
                  name: "Alarm når forbruk er lavere enn beregnet forbruk",
                  schedule: showModal.mainMeterSchedule,
                  timeseries: {
                    ids: showModal.mainMeterTimeseries.map((ts) => ts.id),
                    name: "Totalt forbruk",
                    unit: "kWh",
                    color: DEFAULT_GLOBAL_THEME.color.BLUE,
                  },
                  compareWithTsMin: {
                    ids: showModal.epredTimeseriesMin.map((ts) => ts.id),
                    name: "Beregnet minimum forbruk",
                  },
                  category: "energy",
                })
              }
            >
              Alarm når forbruk er lavere enn beregnet forbruk
            </Button>
          </Space>
        </Modal>
      )}
      <TimeseriesSelectionModal
        initialFilters={{ buildingId: selectedBuildingId }}
        hiddenFilters={["building"]}
        open={Boolean(selectedBuildingId && selectTimeseries)}
        onOk={handleSelectTimeseries}
        onHide={() => {
          setSelectTimeseries(null);
        }}
        selectedIds={[]}
        min={
          selectTimeseries?.type === "CUSTOM_TIMESERIES_MAX" ||
          selectTimeseries?.type === "CUSTOM_TIMESERIES_MIN"
            ? 2
            : 1
        }
        max={
          selectTimeseries?.type === "CUSTOM_TIMESERIES_MAX" ||
          selectTimeseries?.type === "CUSTOM_TIMESERIES_MIN"
            ? 2
            : 1
        }
      />
    </>
  );
};
