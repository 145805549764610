import useSWR from "swr";
import { AccessModules } from "@properate/common";
import {
  getOrganization,
  getOrganizationModules,
  getOrganizations,
} from "@/eepApi";

export type OrganizationFirebase = {
  modules: AccessModules[];
  buildings: {
    [key: number]: {
      modules: AccessModules[];
    };
  };
} & { snapshotId?: string };

export type OrganizationsFirebase = Record<string, OrganizationFirebase>;

export function useOrganizations() {
  const { isLoading, error, data } = useSWR("useOrganizations", async () =>
    getOrganizations(),
  );

  if (error) {
    console.error("Error fetching organizations", error);
  }

  return { isLoading, error, data: data || {} };
}
export function useOrganizationModules(organizationName: string) {
  const { data, isLoading, error } = useSWR(
    ["modules", organizationName],
    async () => {
      return getOrganizationModules(organizationName);
    },
  );
  return {
    data: data || [],
    isLoading,
    error,
  };
}

export function useOrganization(organizationName: string) {
  const { data, isLoading, error } = useSWR(
    ["organization", organizationName],
    async () => {
      return getOrganization(organizationName);
    },
  );
  return {
    data: data || {},
    isLoading,
    error,
  };
}
