import { useTranslations } from "@properate/translations";
import { Button, Input, Popconfirm, Select } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { ColumnsType } from "antd/lib/table/interface";
import { TableProps } from "antd/es/table/InternalTable";
import { useMemo } from "react";
import FullLayout from "@/layout/FullLayout";
import usePageTitle from "@/hooks/usePageTitle";
import { AlertGroupPageRecord, OrgAlertGroupsLoader } from "@/features/alarms";
import { Sort } from "@/pages/fileType/types";
import Highlight from "@/components/Highlight";
import { useWindowSize } from "@/hooks/useWindowSize";
import { TableInfiniteScroll } from "@/components/TableInfiniteScroll/TableInfiniteScroll";
import { DEFAULT_SORT } from "@/routes/loaders";
import { useRootAssets } from "@/hooks/useRootAssets";
import { getUrl, useGroupsData } from "./utils";
import type { SorterResult } from "antd/es/table/interface";

export const OrgGroupsPage = () => {
  const t = useTranslations();
  usePageTitle(t("org-groups.title"));
  const pageData = useLoaderData() as OrgAlertGroupsLoader;
  const navigate = useNavigate();

  const { groups, onLoadMore, isLoading, onDelete } = useGroupsData();

  const doSearch = (search: string) => {
    navigate(getUrl(search, pageData.sort, pageData.filter));
  };

  const doSort = (sort: Sort) => {
    navigate(
      getUrl(
        pageData.search,
        {
          property: sort.property,
          order: sort.order,
        },
        pageData.filter,
      ),
    );
  };

  const doFilter = (owner: string) => {
    navigate(getUrl(pageData.search, pageData.sort, { owner }));
  };

  const onEdit = (id: string) => {
    navigate(`${id}`);
  };

  const onAddNew = () => {
    navigate("new");
  };

  const columns: ColumnsType<AlertGroupPageRecord> = [
    {
      title: t("org-groups.owner"),
      key: "owner",
      dataIndex: "owner",
    },
    {
      title: t("alert-groups.table.name"),
      key: "name",
      sorter: true,
      defaultSortOrder: "ascend",
      render: ({ name, highlight }) =>
        highlight?.name ? (
          <Highlight dangerouslySetInnerHTML={{ __html: highlight?.name }} />
        ) : (
          name
        ),
    },
    {
      title: t("alert-groups.table.members"),
      dataIndex: "membersCount",
      key: "membersCount",
      width: 100,
      align: "center",
    },
    {
      key: "actions",
      width: 100,
      render: ({ id }) => (
        <div className="flex gap-2 items-center justify-center">
          <Popconfirm
            title={t("alert-groups.delete-confirm")}
            onConfirm={() => onDelete(id)}
          >
            <Button
              aria-label={t("alert-groups.table.delete")}
              icon={<DeleteOutlined />}
              danger
            />
          </Popconfirm>
          <Button
            onClick={() => onEdit(id)}
            icon={<EditOutlined />}
            aria-label={t("alert-groups.table.edit")}
          />
        </div>
      ),
    },
  ];

  const onChangeTable = (
    _: any,
    __: any,
    sorter: SorterResult<AlertGroupPageRecord>,
  ) => {
    if (!sorter.columnKey || !sorter.order) return;

    doSort({
      property: sorter.columnKey as string,
      order: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  const { height: windowHeight } = useWindowSize();

  const rootAssets = useRootAssets();
  const ownerOptions = useMemo(
    () =>
      [...new Set(rootAssets.map((asset) => asset.metadata?.owner))]
        .filter(Boolean)
        .sort()
        .map((owner) => ({
          value: owner,
        })),
    [rootAssets],
  );

  return (
    <FullLayout
      hideSearchInput
      pageName={t("org-groups.title")}
      headerRight={[
        <Input.Search
          placeholder={t("alert-groups.search")}
          defaultValue={pageData.search}
          data-testid="search-for-alert-groups"
          key="search"
          allowClear
          style={{ width: 300 }}
          onChange={(event) => doSearch(event.target.value)}
          aria-label={t("alert-groups.search")}
        />,
        <Select
          key="owner"
          showSearch
          allowClear
          className="w-40"
          value={pageData.filter?.owner}
          onChange={(value) => {
            doFilter(value);
          }}
          options={ownerOptions}
          placeholder={t("org-incidents.filter-by-owner")}
        />,
        <Button
          key="new"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddNew}
          aria-label={t("alert-groups.add-group")}
        >
          {t("alert-groups.add-group")}
        </Button>,
      ]}
    >
      {windowHeight && (
        <TableInfiniteScroll<AlertGroupPageRecord>
          columns={columns}
          dataSource={groups}
          height={windowHeight - 64}
          onReachedEndOfPage={onLoadMore}
          rowKey="id"
          loading={isLoading}
          onChange={
            onChangeTable as TableProps<AlertGroupPageRecord>["onChange"]
          }
        />
      )}
    </FullLayout>
  );
};

OrgGroupsPage.loader = ({
  request,
  defaultSort = DEFAULT_SORT,
}: LoaderFunctionArgs & {
  defaultSort?: Sort;
}) => {
  const urlParams = new URL(request.url).searchParams;
  const search = urlParams.get("search");

  const property = urlParams.get("sort");
  const order = urlParams.get("order");

  const sort: Sort =
    property && order
      ? {
          property,
          order: order as "asc" | "desc",
        }
      : defaultSort;

  const owner = urlParams.get("owner");

  const filter = owner
    ? {
        owner,
      }
    : undefined;

  return {
    search,
    sort,
    filter,
  } as OrgAlertGroupsLoader;
};
