import { MessageKey, useTranslations } from "@properate/translations";
import { Button, Divider, Form, FormInstance, Select } from "antd";
import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  AlarmRule,
  getUserNameWithEmail,
  IncidentClientSide,
} from "@properate/common";
import dayjs from "@properate/dayjs";
import { BasicAccordionItem } from "@properate/ui";
import { ExportOutlined } from "@ant-design/icons";
import useSWR from "swr";
import { useUser } from "@properate/auth";
import { AlarmSummary } from "@/pages/alarms/details/AlarmDeviationsPage/AlarmSummary";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { listUsers } from "@/eepApi";
import { selectOptionRenderer } from "@/features/alarms";
import { useIncidentRelatedDatapoints, Comment } from "./utils";
import { IncidentTimer } from "./IncidentTimer";
import { IncidentActivity } from "./IncidentActivity";

type Props = {
  assignButtons: ReactNode[];
  data: IncidentClientSide;
  name: string;
  refetchIncident: () => void;
  onUpdateIncident: (values: Partial<IncidentClientSide>) => Promise<void>;
  form: FormInstance;
  alarmRule?: AlarmRule;
  commentsData: {
    comments: Comment[];
    isLoadingComments: boolean;
    onSendComment: (
      comment: string,
      callback?: VoidFunction,
      shouldUpdateIncidentLastComment?: boolean,
    ) => Promise<void>;
    isLoadingEvent: boolean;
  };
};

export const IncidentInfo = ({
  assignButtons,
  data,
  name,
  refetchIncident,
  onUpdateIncident,
  form,
  alarmRule,
  commentsData,
}: Props) => {
  const t = useTranslations();
  const currentBuildingId = useCurrentBuildingId();
  const currentUser = useUser();
  const { data: users } = useSWR("listUsers", () => listUsers());

  const filteredUserEmails = useMemo(
    () =>
      users?.reduce(
        (acc, user) => {
          if (user.email) {
            acc.push({ label: user.name || "", value: user.email });
          }
          return acc;
        },
        [] as { label: string; value: string }[],
      ) || [],
    [users],
  );

  const [assignedUser, setAssignedUser] = useState<string | undefined>(
    data.assigned?.user.email,
  );

  useEffect(() => {
    if (data.assigned?.user.email) {
      setAssignedUser(data.assigned?.user.email);
    }
  }, [data.assigned?.user.email]);

  useIncidentRelatedDatapoints(data.start, alarmRule);

  const isAssigned = !!data.assigned?.user;
  const canChangeStatus = data.status !== "resolved";

  const statusSelectOptions = [
    {
      label: t("incident.statuses.unresolved"),
      value: "unresolved",
    },
    {
      label: t("incident.statuses.in_progress"),
      value: "in_progress",
    },
    {
      label: t("incident.statuses.resolved"),
      value: "resolved",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const { comments, isLoadingComments, isLoadingEvent, onSendComment } =
    commentsData;

  const onFinish = async ({
    status,
  }: {
    status: IncidentClientSide["status"];
  }) => {
    let newData: {
      last_comment?: string;
      status?: IncidentClientSide["status"];
      assigned?: IncidentClientSide["assigned"];
    } = {};
    const comments = [];
    if (status && data.status !== status) {
      const last_comment = t("incident.status-changed-message", {
        status: t(`incident.statuses.${status}` as MessageKey),
        user: getUserNameWithEmail(currentUser),
      });
      comments.push(last_comment);

      newData = {
        last_comment,
        status,
      };
    }

    if (assignedUser && data.assigned?.user.email !== assignedUser) {
      const assignedToUser = users?.find((user) => user.email === assignedUser);
      const last_comment = t("incident.assigned-to-user-message", {
        assignedToUser: getUserNameWithEmail(assignedToUser),
        assignedByUser: getUserNameWithEmail(currentUser),
      });
      comments.push(last_comment);
      newData = {
        ...newData,
        last_comment,
        assigned: {
          user: {
            email: assignedUser,
            name: assignedToUser?.name || "",
          },
          timestamp: Date.now(),
        },
      };
    }

    await onUpdateIncident(newData);

    if (comments.length) {
      await Promise.all(
        comments.map((comment) => onSendComment(comment, undefined, false)),
      );
    }
  };

  const assignToUserSelect = (
    <Select
      value={assignedUser}
      onChange={setAssignedUser}
      showSearch
      allowClear
      className="w-80"
      placeholder={t("incident.select-user")}
      options={filteredUserEmails}
      optionRender={(props) =>
        selectOptionRenderer({
          label: props.label as string,
          value: props.value as string,
        })
      }
      labelRender={(props) =>
        selectOptionRenderer({
          label: props.label as string,
          value: props.value as string,
        })
      }
    />
  );

  return (
    <div className="flex flex-col">
      <Form
        form={form}
        id="incidents-form"
        initialValues={{
          status: data.status,
        }}
        onFinish={onFinish}
      >
        <div className="flex gap-3 items-center">
          <h2 className="mb-0">{t("incident.incident-information")} </h2>
          {!!data.has_active_event && (
            <div className="w-3 h-3 bg-red-500 rounded-full" />
          )}
        </div>
        <Divider className="my-4" />
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">{assignButtons}</div>
          {!isAssigned && (
            <>
              <div className="flex gap-2 items-center">
                <div>{t("incident.assign-to-user")}</div>
                {assignToUserSelect}
              </div>
              {data.alert_next_at && (
                <IncidentTimer
                  refetchIncident={refetchIncident}
                  sendToNextAt={data.alert_next_at}
                />
              )}
              <div className="flex gap-2">
                <div>{t("incident.responsible")}:</div>
                <div className="font-bold">
                  {getUserNameWithEmail(data.responsible?.user) ||
                    (data.notified_people?.user.length
                      ? data.notified_people?.user
                          .map((user) => getUserNameWithEmail(user))
                          .join(", ")
                      : t("incident.no-responsible-people"))}
                </div>
              </div>
            </>
          )}

          {isAssigned && (
            <div className="flex gap-2 items-center">
              <div>{t("incident.assigned-to")}:</div>
              {assignToUserSelect}
            </div>
          )}
        </div>

        <div className="my-6 flex flex-col gap-2">
          <div className="flex gap-2">
            <div>{t("incident.name")}:</div>
            <div className="font-bold">{name}</div>
          </div>
          <div className="flex gap-2">
            <div>{t("incident.reported-at")}:</div>
            <div>{dayjs(data.start).format("DD/MM YYYY HH:mm")}</div>
          </div>
          <div className="flex gap-2">
            <div>{t("incident.status")}:</div>
            {canChangeStatus ? (
              <Form.Item name="status">
                <Select className="w-40" options={statusSelectOptions} />
              </Form.Item>
            ) : (
              t(`incident.statuses.${data.status}` as MessageKey)
            )}
          </div>
        </div>
      </Form>
      <Divider className="my-4" />
      <BasicAccordionItem
        hasPaddings={false}
        titleClassName="text-xl"
        openClassName=""
        open={isOpen}
        setOpen={setIsOpen}
        htmlId="incident_alarm"
        title={
          <>
            {t("incident.alarm")}
            <Button
              onClick={() => {
                window.open(
                  `/asset/${currentBuildingId}/newAlarms/${data.alarm_rule_id}`,
                  "_blank",
                  "noopener,noreferrer",
                );
              }}
              type="link"
              icon={<ExportOutlined />}
            />
          </>
        }
        primaryOpenContent={<AlarmSummary />}
      />
      <Divider className="my-4" />
      <IncidentActivity
        comments={comments}
        isLoadingComments={isLoadingComments}
        isLoadingEvent={isLoadingEvent}
        sendComment={onSendComment}
      />
    </div>
  );
};
