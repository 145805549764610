import useSWR from "swr";
import { useProperateApiClient } from "@/context/ProperateApiContext";
import { useFloorPlanPin } from "../FloorPlanMapPinContext";

export function useFloorPlanPinStatus() {
  const pin = useFloorPlanPin();
  const properateApiClient = useProperateApiClient();

  return useSWR(
    pin.type === "set-point-general" && pin.externalId
      ? ["set-point", pin.externalId]
      : null,
    async ([_, externalId]) => properateApiClient.getSetPointStatus(externalId),
    { revalidateOnFocus: false },
  );
}
