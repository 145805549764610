import { useUser } from "@properate/auth";
import { Key, MouseEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Modal,
  App,
  Row,
  Space,
  Tag,
  Popover,
  Tooltip,
} from "antd";
import { useTranslationsWithFallback } from "@properate/translations";
import {
  DeleteOutlined,
  DownloadOutlined,
  UndoOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import dayjs from "@properate/dayjs";
import prettyBytes from "pretty-bytes";
import { ProperateFile } from "@properate/file-viewer/src/components/types";
import { useNavigate } from "react-router-dom";
import Highlight from "@/components/Highlight";
import { Thumbnail } from "@/components/Thumbnail";
import { LABEL_KEYS } from "@/pages/showFileType/fileLabels";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { Sort, UpdateFile } from "@/pages/fileType/types";
import { useCogniteClient } from "@/context/CogniteClientContext";
import AddSystemModal from "@/pages/fileType/AddSystemModal";
import { MessageKey } from "@/utils/types";
import { getSystemTranslationKey } from "@/utils/tfm";
import { FILE_LABELS } from "@/pages/fileType/utils";
import { TableWithActions, DisplayBlock, Name } from "./elements";
import { FileTableGroupActions } from "./FileTableGroupActions";

const ESTIMATED_TABLE_ACTIONS_HEIGHT = 36;
const ESTIMATED_TABLE_HEADER_HEIGHT = 29;

interface Props {
  selectedRowKeys: number[];
  onSelectedRowsChange: (selectedRowKeys: number[]) => void;
  id: number;
  data: ProperateFile[];
  selectedLabels?: string[];
  selectedResource?: string;
  onDeleteFiles?: (ids: number[]) => void;
  onUpdateFiles?: (changes: UpdateFile[]) => void;
  onPurgeFiles?: (ids: number[]) => void;
  onRestoreFiles?: (ids: number[]) => void;
  sort: Sort;
  onSort: (sort: Sort) => void;
}

export const FileTable = ({
  selectedRowKeys,
  onSelectedRowsChange,
  id,
  data,
  onDeleteFiles,
  onUpdateFiles,
  onPurgeFiles,
  onRestoreFiles,
  sort,
  onSort,
}: Props) => {
  const { client } = useCogniteClient();
  const t = useTranslationsWithFallback();
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const [addLabelsTo, setAddLabelsTo] = useState<Key[]>();
  const [addResourcesTo, setAddResourcesTo] = useState<number[]>();
  const { height: windowHeight } = useWindowSize();
  const user = useUser();

  const [labelsForSelection, setLabelsForSelection] = useState<
    Record<string, { checked?: boolean; indeterminate?: boolean }>
  >({});

  useEffect(() => {
    if (addLabelsTo) {
      const labelCount = data
        .filter((row) => addLabelsTo.includes(row.id))
        .reduce<Record<string, number>>((acc, row) => {
          (row.labels || []).forEach((label) => {
            if (!acc[label.externalId]) {
              acc[label.externalId] = 1;
            } else {
              acc[label.externalId]++;
            }
          });
          return acc;
        }, {});

      setLabelsForSelection(
        Object.entries(labelCount)
          .map(([label, count]) => ({
            [label]: {
              checked: count > 0,
              indeterminate: count > 0 && count < addLabelsTo.length,
            },
          }))
          .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      );
    }
  }, [data, addLabelsTo]);

  const tableHeight =
    windowHeight -
    PAGE_LAYOUT_HEIGHT -
    ESTIMATED_TABLE_ACTIONS_HEIGHT -
    ESTIMATED_TABLE_HEADER_HEIGHT;

  function download(fileUrls: string[]) {
    fileUrls.forEach((fileUrl) => window.open(fileUrl));
  }

  function showConfirmationDialog(fileId: number) {
    if (onPurgeFiles) {
      modal.confirm({
        icon: null,
        title: t("files.remove-files-permanently", {
          filesCount: 1,
        }),
        content: (
          <>
            <p>
              {t("files.remove-files-permanently-description", {
                filesCount: 1,
              })}
            </p>
            <p>
              {t("files.are-you-sure-to-remove-files-permanently", {
                filesCount: 1,
              })}
            </p>
          </>
        ),
        okText: t("files.delete"),
        okType: "danger",
        onOk: () => onPurgeFiles([fileId]),
      });
    }
  }

  async function handleClickOnName(
    event: MouseEvent<HTMLSpanElement>,
    item: ProperateFile,
  ) {
    event.stopPropagation();
    const pathname = window.location.pathname.toString();
    navigate(
      `${pathname.endsWith("/") ? pathname : pathname + "/"}preview/${item.id}${
        window.location.search
      }`,
    );
  }

  function handleClickAddLabel(event: MouseEvent<HTMLElement>, id: number) {
    event.stopPropagation();
    setAddLabelsTo([id]);
  }

  function handleClickAddLabels() {
    setAddLabelsTo(selectedRowKeys);
  }

  function handleClickAddResources() {
    setAddResourcesTo(selectedRowKeys);
  }

  function handleClickDeleteFiles() {
    onDeleteFiles?.(selectedRowKeys);
  }

  async function handleClickDownloadFiles() {
    const files = await client.files.getDownloadUrls(
      selectedRowKeys.map((selectedRowKey) => ({
        id: selectedRowKey as number,
      })),
    );
    download(files.map((file) => file.downloadUrl));
  }

  function handleClickRestoreFiles() {
    onRestoreFiles?.(selectedRowKeys);
  }

  function handleClickPurgeFiles() {
    modal.confirm({
      icon: null,
      title: t("files.remove-files-permanently", {
        filesCount: selectedRowKeys.length,
      }),
      content: (
        <>
          <p>
            {t("files.remove-files-permanently-description", {
              filesCount: selectedRowKeys.length,
            })}
          </p>
          <p>
            {t("files.are-you-sure-to-remove-files-permanently", {
              filesCount: selectedRowKeys.length,
            })}
          </p>
        </>
      ),
      okText: t("files.delete"),
      okType: "danger",
      onOk: () => {
        onPurgeFiles?.(selectedRowKeys);
      },
    });
  }

  return (
    <>
      <TableWithActions>
        <FileTableGroupActions
          onClickAddLabels={onPurgeFiles ? undefined : handleClickAddLabels}
          onClickAddResources={
            onPurgeFiles ? undefined : handleClickAddResources
          }
          onClickDeleteFiles={onPurgeFiles ? undefined : handleClickDeleteFiles}
          onClickDownloadFiles={
            onPurgeFiles ? undefined : handleClickDownloadFiles
          }
          onClickRestoreFiles={
            onPurgeFiles ? handleClickRestoreFiles : undefined
          }
          onClickPurgeFiles={onPurgeFiles ? handleClickPurgeFiles : undefined}
          style={{
            alignSelf: "end",
            visibility: selectedRowKeys.length > 0 ? "visible" : "hidden",
          }}
        />
        <TableWithoutDefaultSort
          data-testid="files-table"
          dataSource={data}
          pagination={false}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys) =>
              onSelectedRowsChange(selectedRowKeys as number[]),
            getCheckboxProps(rowData) {
              return { name: rowData.id.toString() };
            },
          }}
          rowIsHoverable
          size="small"
          onRow={({ id }) => ({
            onClick: () => {
              if (selectedRowKeys.includes(id)) {
                return onSelectedRowsChange(
                  selectedRowKeys.filter(
                    (selectedRowKey) => selectedRowKey !== id,
                  ),
                );
              }
              onSelectedRowsChange(selectedRowKeys.concat(id));
            },
          })}
          scroll={{
            y: tableHeight,
            x: "max-content",
          }}
          onChange={(_pagination, _filters, sorter) => {
            if (Array.isArray(sorter)) {
              onSort({
                property: sorter[0].field as string,
                order: sorter[0].order === "ascend" ? "asc" : "desc",
              });
            } else {
              onSort({
                property: sorter.field as string,
                order: sorter.order === "ascend" ? "asc" : "desc",
              });
            }
          }}
          columns={[
            {
              width: 64,
              dataIndex: ["item"],
              render: (_, rowData) => {
                return (
                  <div style={{ width: "24px", height: "24px" }}>
                    <Thumbnail
                      urn={
                        rowData.metadata?.urn
                          ? btoa(rowData.metadata?.urn)
                          : undefined
                      }
                      fileId={rowData.id}
                      fileName={rowData.name}
                    />
                  </div>
                );
              },
            },
            {
              title: t("files.table.name"),
              dataIndex: "name",
              width: 300,
              render: (name, rowData) => {
                return (
                  <>
                    <Name
                      data-testid={`file-name/${name}`}
                      onClick={(event) => handleClickOnName(event, rowData)}
                      dangerouslySetInnerHTML={{
                        __html: rowData.highlight?.name
                          ? rowData.highlight?.name[0]
                          : name,
                      }}
                    />
                    {rowData.highlight?.content ? <br /> : null}
                    {rowData.highlight?.content ? <br /> : null}
                    {rowData.highlight?.content ? (
                      <Highlight
                        dangerouslySetInnerHTML={{
                          __html: rowData.highlight.content[0],
                        }}
                      />
                    ) : null}
                    {rowData.highlight?.content ? <br /> : null}
                    {rowData.highlight?.content &&
                    rowData.highlight?.content.length > 1 ? (
                      <Tooltip
                        title={
                          <>
                            {rowData.highlight?.content
                              .slice(1)
                              .map((content) => (
                                <>
                                  <Highlight
                                    dangerouslySetInnerHTML={{
                                      __html: content,
                                    }}
                                  />
                                  <br />
                                </>
                              ))}
                          </>
                        }
                      >
                        <span>
                          {t("files.table.more-hints", {
                            count: rowData.highlight?.content.length - 1,
                          })}
                        </span>
                      </Tooltip>
                    ) : null}
                  </>
                );
              },
              sortOrder: sort.property.includes("name")
                ? sort.order === "asc"
                  ? "ascend"
                  : "descend"
                : undefined,
              sorter: true,
            },
            {
              title: t("files.table.changed"),
              dataIndex: "modifiedTime",
              width: 150,
              sortOrder: sort.property.includes("modifiedTime")
                ? sort.order === "asc"
                  ? "ascend"
                  : "descend"
                : undefined,
              sorter: true,
              render: (modifiedTime) => {
                return (
                  <p>{dayjs(modifiedTime).format("DD. MMM YYYY HH:mm")}</p>
                );
              },
            },
            {
              title: t("files.table.size"),
              dataIndex: "size",
              width: 100,
              align: "right",
              sortOrder: sort.property.includes("size")
                ? sort.order === "asc"
                  ? "ascend"
                  : "descend"
                : undefined,
              sorter: true,
              render: (size) =>
                size
                  ? `${prettyBytes(size, {
                      locale: "nb",
                    })}`
                  : "",
            },
            {
              title: t("files.table.type"),
              dataIndex: "type",
              width: 100,
              sortOrder: sort.property.includes("type")
                ? sort.order === "asc"
                  ? "ascend"
                  : "descend"
                : undefined,
              sorter: true,
              render: (type) => t(`file-types.${type}` as any),
            },
            {
              title: t("files.table.labels"),
              dataIndex: "labels",
              width: 250,
              render: (labels) => (
                <DisplayBlock>
                  {labels?.map((label: { externalId: string }) => {
                    return (
                      <Tag
                        color={DEFAULT_GLOBAL_THEME.color.YELLOW}
                        key={label.externalId}
                      >
                        {LABEL_KEYS.includes(label.externalId)
                          ? t(
                              `file_labels.${label.externalId}` as MessageKey,
                            )?.toUpperCase()
                          : label.externalId.toUpperCase()}
                      </Tag>
                    );
                  })}
                </DisplayBlock>
              ),
            },
            {
              title: t("files.table.system"),
              dataIndex: "metadata",
              width: 250,
              render: (metadata: Record<string, string>) => (
                <DisplayBlock>
                  {metadata?.systems
                    ? metadata.systems
                        .split(",")
                        .filter((system) => system !== "")
                        .map((system) => (
                          <Tag
                            color={DEFAULT_GLOBAL_THEME.color.LIGHT_BLUE}
                            key={`system-${system}`}
                          >
                            {`${system}: ${t.fallback([
                              getSystemTranslationKey(system) as MessageKey,
                            ])}`}
                          </Tag>
                        ))
                    : null}
                </DisplayBlock>
              ),
            },
            {
              width: 60,
              dataIndex: "id",
              render: (id: number) => (
                <Space
                  direction="horizontal"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {!onPurgeFiles && (
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={async (_event) => {
                        const files = await client.files.getDownloadUrls([
                          { id },
                        ]);
                        download(files.map((file) => file.downloadUrl));
                      }}
                    />
                  )}
                  {onDeleteFiles && (
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={(_event) => {
                        onDeleteFiles([id]);
                      }}
                      danger
                      disabled={user.isViewer}
                    />
                  )}
                  {onRestoreFiles && (
                    <Button
                      name="Gjenopprett"
                      icon={<UndoOutlined />}
                      danger
                      onClick={async (_event) => {
                        onRestoreFiles([id]);
                      }}
                      disabled={user.isViewer}
                    />
                  )}
                  {onPurgeFiles && (
                    <Button
                      icon={<DeleteOutlined />}
                      danger
                      onClick={(_event) => {
                        showConfirmationDialog(id);
                      }}
                      disabled={user.isViewer}
                    />
                  )}
                  {!onPurgeFiles && (
                    <Popover
                      content={
                        <Space direction="vertical">
                          <Button
                            type="link"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClickAddLabel(event, id);
                            }}
                            disabled={user.isViewer}
                          >
                            {t("files.table.add-label")}
                          </Button>
                          <Button
                            type="link"
                            onClick={(event) => {
                              event.stopPropagation();
                              setAddResourcesTo([id]);
                            }}
                            disabled={user.isViewer}
                          >
                            {t("files.table.add-system")}
                          </Button>
                        </Space>
                      }
                      trigger="hover"
                    >
                      <Button icon={<MoreOutlined />} type="link" />
                    </Popover>
                  )}
                </Space>
              ),
            },
          ]}
          rowKey={(row) => row.id}
        />
      </TableWithActions>
      {addLabelsTo && onUpdateFiles && (
        <Modal
          title={t("files.add-labels")}
          open
          onCancel={() => setAddLabelsTo(undefined)}
          footer={[
            <Button
              key="cancel"
              onClick={() => setAddLabelsTo(undefined)}
              style={{ marginRight: 8 }}
            >
              {t("files.cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                const selectedFiles = (data || []).filter(
                  (file: any) => addLabelsTo?.includes(file.id),
                );

                onUpdateFiles(
                  selectedFiles.map((row) => {
                    const add = Object.entries(labelsForSelection)
                      .filter(
                        ([, { checked, indeterminate }]) =>
                          checked && !indeterminate,
                      )
                      .filter(
                        ([externalId]) =>
                          (row.labels || []).find(
                            (label: any) => label.externalId === externalId,
                          ) === undefined,
                      )
                      .map(([label]) => ({ externalId: label }));

                    const remove = (row.labels || []).filter(
                      (label: any) =>
                        !labelsForSelection[label.externalId]?.checked,
                    );

                    return {
                      id: row.id,
                      update: {
                        labels: {
                          add,
                          remove,
                        },
                      },
                    };
                  }),
                );
                setAddLabelsTo(undefined);
              }}
            >
              {t("files.add")}
            </Button>,
          ]}
        >
          <Row>
            {FILE_LABELS.toSorted((a, b) =>
              t(`file_labels.${a}`).localeCompare(t(`file_labels.${b}`)),
            ).map((label) => (
              <Col span={8} key={label}>
                <Checkbox
                  value={label}
                  checked={labelsForSelection[label]?.checked}
                  indeterminate={labelsForSelection[label]?.indeterminate}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      setLabelsForSelection({
                        ...labelsForSelection,
                        [label]: {
                          checked,
                          indeterminate: false,
                        },
                      });
                    } else {
                      const copy = Object.assign({}, labelsForSelection);
                      delete copy[label];
                      setLabelsForSelection(copy);
                    }
                  }}
                >
                  {t(`file_labels.${label}`)}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Modal>
      )}
      {addResourcesTo && onUpdateFiles && (
        <AddSystemModal
          id={id}
          onHide={() => setAddResourcesTo(undefined)}
          files={(data || []).filter(
            (file) => addResourcesTo?.includes(file.id),
          )}
          onUpdateFiles={onUpdateFiles}
        />
      )}
    </>
  );
};
