import { useCallback, useEffect, useRef, useState } from "react";
import { produce } from "immer";
import { Alert, App, Button } from "antd";
import { Timeseries } from "@cognite/sdk";
import { SchemaType, SensorInfo, SensorList } from "@properate/common";
import { CustomDndProvider, useSidebarValues } from "@properate/ui";
import { mutate } from "swr";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { parseError } from "@/pages/common/utils";
import { SetPointStatusJs } from "@/context/api/apiBatch";
import { SetPointSettings } from "@/pages/common/SchemaView/TechnicalSchema/SetPointSettings";
import { ProperateApiProvider } from "@/context/ProperateApiContext";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useProperateCogniteClient } from "@/context/ProperateCogniteClientContext";
import { TimeseriesSelectionModal } from "@/features/timeseries";
import { updateSetPoint } from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import {
  NotesAssetFilterMode,
  NoteSource,
  NotesSidebar,
} from "@/features/notes";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useCogniteImage } from "@/useCogniteImage";
import RoomInfo from "../../RoomInfo";
import GraphModal from "../../SchemaGraph";
import { SchemaViewPanZoom } from "../SchemaViewPanZoom";
import { ItemTypes } from "./ItemTypes";
import { Overlay } from "./Overlay";
import { Container, Main } from "./elements";
import { useSensorCategoryOptions } from "./sensorQueries";

interface Props {
  image: HTMLElement;
  edit: boolean;
  snapshotId: string;
  disableZoom: boolean;
  selectedSystem?: string;
  technicalSchema: SchemaType;
  onChangeTechnicalSchema: (value: Partial<SchemaType>) => unknown;
  selectedBuilding?: string;
  availableSensors?: JSX.Element[];
  type?: string;
}

interface Position {
  x: number;
  y: number;
}

interface SettingState {
  timeseriesInfo: SensorInfo[];
  type: string;
  id: string;
  position?: Position;
  removable?: boolean;
  max?: number;
}

interface SetPointSettingState extends SettingState {
  setPointStatus: SetPointStatusJs;
}

function getPosition(
  rect: Position & {
    height: number;
    width: number;
  },
  position: string,
) {
  if (position === "right") {
    return { x: rect.x, y: rect.y + rect.height / 2 };
  }
  if (position === "left") {
    return { x: rect.x + rect.width, y: rect.y + rect.height / 2 };
  }
  if (position === "top") {
    return { x: rect.x + rect.width / 2, y: rect.y + rect.height };
  }
  if (position === "bottom") {
    return { x: rect.x + rect.width / 2, y: rect.y };
  }
  if (position === "center") {
    return { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };
  }
}

export const TechnicalSchema = ({
  edit,
  image,
  technicalSchema,
  onChangeTechnicalSchema,
  snapshotId,
  disableZoom,
  type,
}: Props) => {
  const properateCogniteClient = useProperateCogniteClient();
  const { message } = App.useApp();
  const { client } = useCogniteClient();
  const building = useCurrentBuilding();
  const navigate = useNavigate();
  const t = useTranslations();

  const [showSettingFor, setShowSettingFor] = useState<SettingState>();
  const [showSetPointSettingFor, setShowSetPointSettingFor] =
    useState<SetPointSettingState>();
  const [showDetailsFor, setShowDetailsFor] = useState<{
    timeseriesInfo: SensorInfo;
    key: string;
  }>();
  const [showRoomInfoFor, setShowRoomInfoFor] = useState<
    | {
        id: number;
        sensors: Record<string, SensorList>;
      }
    | undefined
  >();
  const [overlays, setOverlays] = useState<any[]>();
  const [hover, setHover] = useState<string>();
  const [editableOverlays, setEditableOverlays] = useState<string[]>([]);
  const container = useRef<HTMLElement | null>(null);
  const [notFoundTimeseriesList, setNotFoundTimeseriesList] = useState<
    { id: number; key: string }[]
  >([]);
  const [trTimeseries, setTrTimeseries] = useState<Timeseries[]>([]);

  const addPoint = (
    position: Position,
    type: string,
    id: string,
    currentImage?: HTMLElement,
  ) => {
    const svg = container.current!.querySelector("svg") as SVGSVGElement;
    const pt = svg.createSVGPoint();
    pt.x = position.x;
    pt.y = position.y;
    const svgP = pt.matrixTransform(svg.getScreenCTM()!.inverse());
    const component = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g",
    );

    component.setAttribute("id", id);
    component.setAttribute("class", "component");
    component.setAttribute("data-position", "center");
    component.setAttribute("data-type", type);

    // status
    const status = document.createElementNS("http://www.w3.org/2000/svg", "g");
    status.setAttribute("class", "status");
    const circle = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle",
    );
    circle.setAttribute("cx", svgP.x.toString());
    circle.setAttribute("cy", svgP.y.toString());
    circle.setAttribute("r", "1");
    circle.setAttribute("fill", "transparent");

    status.appendChild(circle);

    component.appendChild(status);

    const imageFinal = currentImage ?? image;

    imageFinal.appendChild(component);

    return imageFinal.outerHTML;
  };
  const removePoint = (id: string) => {
    const el = image.querySelector("#" + id);
    if (el) {
      image.removeChild(el);
    }
    return image.outerHTML;
  };

  const openSettings = useCallback(
    (event: Event) => {
      if (event.target instanceof SVGElement) {
        const item = event.target.closest(".item")!;
        if (item instanceof SVGElement) {
          setShowSettingFor({
            timeseriesInfo:
              (technicalSchema.sensors[item.id] &&
                technicalSchema.sensors[item.id].timeseriesInfo) ||
              [],
            type: item.dataset.type!,
            id: item.id,
            max: item.dataset.type?.startsWith("set-point") ? 1 : undefined,
          });
        }
      }
    },
    [setShowSettingFor, technicalSchema.sensors],
  );

  const onHover = useCallback(
    (event: any) => {
      const item = event.target.closest(".item");
      if (item) {
        setHover(item.id);
      }
    },
    [setHover],
  );

  const onHoverLeave = useCallback(() => {
    setHover(undefined);
  }, [setHover]);
  const [, drop] = CustomDndProvider.useDrop(
    () => ({
      accept: [ItemTypes.SENSOR, ItemTypes.OVERLAY],
      drop: async (item: any, monitor) => {
        // if type is OVERLAY
        if (typeof item.x === "number") {
          const xy = monitor.getDifferenceFromInitialOffset();

          const parseXml = new window.DOMParser().parseFromString(
            removePoint(item.id),
            "image/svg+xml",
          );

          const [type] = item.id.split("_");

          const c = container.current!.getBoundingClientRect();
          const image = addPoint(
            {
              x: item.x + xy!.x + c.left,
              y: item.y + xy!.y + c.top,
            },
            type,
            item.id,
            parseXml.documentElement,
          );
          onChangeTechnicalSchema({
            ...technicalSchema,
            image,
          });
        } else {
          const id = crypto.randomUUID();
          const xy = monitor.getSourceClientOffset();

          setShowSettingFor({
            timeseriesInfo: [],
            type: item.type,
            id: item.type + "_" + id,
            position: { x: xy!.x + 20, y: xy!.y + 41 },
          });
        }
        return { name: "Schema" };
      },
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [image, technicalSchema],
  );
  useEffect(() => {
    if (edit) {
      Array.from(document.getElementsByClassName("item")).forEach((el) => {
        el.addEventListener("click", openSettings);
      });

      return () =>
        Array.from(document.getElementsByClassName("item")).forEach((el) => {
          el.removeEventListener("click", openSettings);
        });
    }
  }, [image, openSettings, edit]);

  useEffect(() => {
    Array.from(document.getElementsByClassName("value")).forEach((el) => {
      el.addEventListener("mouseover", onHover);
      el.addEventListener("mouseleave", onHoverLeave);
    });

    return () =>
      Array.from(document.getElementsByClassName("value")).forEach((el) => {
        el.removeEventListener("mouseover", onHover);
        el.removeEventListener("mouseleave", onHoverLeave);
      });
  }, [image, onHover, onHoverLeave]);

  useEffect(() => {
    // find the nodes with relationships to set points, these can be marked as editableOverlays
    const get = async () => {
      const timeseriesInfoIdsAndKey = [
        ...new Set(
          Object.entries(technicalSchema.sensors).flatMap(([key, sensor]) =>
            sensor.timeseriesInfo.map((tsi) => {
              return { id: tsi.id, key };
            }),
          ),
        ),
      ];

      const timeseries = await properateCogniteClient.getTimeseriesMany(
        timeseriesInfoIdsAndKey.map((timeseriesInfo) => timeseriesInfo.id),
      );
      const timeseriesIds = timeseries.map((ts) => ts.id);

      setNotFoundTimeseriesList(
        timeseriesInfoIdsAndKey.filter((ts) => !timeseriesIds.includes(ts.id)),
      );
      const targetExternalIds = timeseries.map((ts) => {
        return ts.externalId!;
      });
      const externalIdToSensorKey = timeseries.reduce<Record<string, string[]>>(
        (acc, ts) => ({
          ...acc,
          [ts.externalId!]: [
            ...new Set([
              ...(acc[ts.externalId!] || []),
              ...(Object.keys(technicalSchema.sensors || {}).filter((key) =>
                technicalSchema.sensors[key].timeseriesInfo.find(
                  (tsi) => tsi.id === ts.id,
                ),
              ) || []),
            ]),
          ],
        }),
        {},
      );
      const relationships =
        targetExternalIds.length > 0
          ? await client.relationships
              .list({
                filter: {
                  targetExternalIds,
                  labels: {
                    containsAll: [{ externalId: "rel_setpt_realval_gen" }],
                  },
                  confidence: { min: 0.3, max: 1 },
                },
              })
              .autoPagingToArray({ limit: -1 })
          : [];

      setEditableOverlays([
        ...new Set(
          relationships
            .map((rel) => externalIdToSensorKey[rel.targetExternalId])
            .flat(),
        ),
      ]);
    };

    get();
  }, [technicalSchema.sensors, properateCogniteClient, client.relationships]);

  const sidebarValues = useSidebarValues();

  useEffect(() => {
    let mounted = true;
    const layout = () => {
      const elements: any[] = [];

      Object.keys(technicalSchema.sensors || {}).forEach((key) => {
        const item = document.querySelector("#" + key) as SVGGraphicsElement;

        const c = container.current?.getBoundingClientRect();
        const panZoomContainer = document.querySelector(".react-panzoom__in");

        let scale = 1;

        if (panZoomContainer) {
          const panZoomStyles = getComputedStyle(panZoomContainer);
          const zoom = parseFloat(panZoomStyles.getPropertyValue("--zoom"));

          if (Number.isFinite(zoom)) {
            scale = zoom;
          }
        }

        if (c && item) {
          const status = item.querySelector(".status") as SVGGraphicsElement;
          if (status) {
            const rect = {
              width: status.getBoundingClientRect().width,
              height: status.getBoundingClientRect().height,
              x: status.getBoundingClientRect().x - c.x,
              y: status.getBoundingClientRect().y - c.y,
            };
            const bb = getPosition(rect, item.dataset.position!);

            const adjustY = ["left", "right"].includes(item.dataset.position!)
              ? (technicalSchema.sensors[key].timeseriesInfo.length * 34) / 2
              : 0;

            elements.push({
              id: key,
              x: bb!.x / scale,
              y: (bb!.y + adjustY) / scale,
              timeseriesInfo: technicalSchema.sensors[key].timeseriesInfo,
              position: item.dataset.position,
              key,
              type: item.dataset.type,
            });
          }
        }
      });
      setOverlays(elements);
    };

    const resizeObserver = new ResizeObserver(() => {
      if (mounted) {
        layout();
      }
    });
    layout();
    resizeObserver.observe(container.current!);
    return () => {
      mounted = false;
      resizeObserver.disconnect();
    };
  }, [technicalSchema.sensors, image, sidebarValues.isOpen]);

  function handleOK(timeseriesList: Timeseries[]) {
    if (showSettingFor) {
      const sensor = technicalSchema.sensors[showSettingFor.id];
      if (sensor) {
        const oldSelectedSensors = Array.isArray(
          technicalSchema.selectedSensors,
        )
          ? technicalSchema.selectedSensors.filter(
              (sensor) => sensor.id === showSettingFor.id,
            )
          : [];

        const removed = sensor.timeseriesInfo.filter(
          (info) =>
            timeseriesList.find((timeseries) => timeseries.id === info.id) ===
            undefined,
        );

        const selectedSensors = oldSelectedSensors.filter(
          (sensor) =>
            removed.find(
              (r) =>
                r.id === sensor.timeseries && showSettingFor.id === sensor.id,
            ) === undefined,
        );

        const sensors = produce(technicalSchema.sensors || {}, (draft) => {
          if (timeseriesList.length === 0) {
            delete draft[showSettingFor.id];
            // if this was a point added with drag and drop we should also remove the id from the image
            if (showSettingFor.removable) {
              removePoint(showSettingFor.id);
            }
          } else {
            draft[showSettingFor.id] = {
              timeseriesInfo: timeseriesList.map((timeseries) => {
                const otherInfo =
                  sensor.timeseriesInfo.find(
                    (info) => info.id === timeseries.id,
                  ) || {};

                return {
                  ...otherInfo,
                  id: timeseries.id,
                  view: "value",
                };
              }),
            };
          }
        });

        onChangeTechnicalSchema({ sensors, selectedSensors });
      } else {
        if (showSettingFor.position) {
          if (type === "floorPlan") {
            if (timeseriesList.length === 0) {
              return;
            }
            let image = technicalSchema.image;
            const CONTENT_LAYOUT_ON_THE_RIGHT_WIDTH = 30;
            const SENSOR_WIDTH = 72;
            const SENSOR_RAW_LENGTH = 6;

            let startPositionX = showSettingFor.position.x;
            let startPositionY = showSettingFor.position.y;

            let totalWidth =
              window.innerWidth -
              startPositionX -
              CONTENT_LAYOUT_ON_THE_RIGHT_WIDTH;

            let counter = 0;
            const sensorsWidth = timeseriesList.length * SENSOR_WIDTH;

            const results = timeseriesList.map((ts) => {
              const sensorId = `${showSettingFor.type}_${crypto.randomUUID()}`;

              if (SENSOR_RAW_LENGTH === counter || sensorsWidth >= totalWidth) {
                startPositionY += 35;
                startPositionX = showSettingFor.position!.x;
                totalWidth =
                  window.innerWidth -
                  startPositionX -
                  CONTENT_LAYOUT_ON_THE_RIGHT_WIDTH;
                counter = 0;
              }

              const newSensorList = {
                [sensorId]: {
                  timeseriesInfo: [
                    {
                      id: ts.id,
                      view: "value",
                    },
                  ],
                },
              };

              const sensorsList = {
                ...technicalSchema.sensors,
                ...newSensorList,
              };

              image = addPoint(
                {
                  x: startPositionX,
                  y: startPositionY,
                },
                showSettingFor.type,
                sensorId,
              );
              startPositionX += 70;
              totalWidth -= 70;
              counter++;
              return { sensorsList };
            });
            const sensors = results.reduce(
              (acc, result) => ({
                ...acc,
                ...result.sensorsList,
              }),
              {},
            );

            onChangeTechnicalSchema({ sensors, image });
          } else {
            const image = addPoint(
              {
                x: showSettingFor.position.x,
                y: showSettingFor.position.y,
              },
              showSettingFor.type,
              showSettingFor.id,
            );
            const sensors: Record<string, SensorList> = {
              ...technicalSchema.sensors,
              [showSettingFor.id]: {
                ...technicalSchema.sensors[showSettingFor.id],
                timeseriesInfo: timeseriesList.map((timeseries) => ({
                  id: timeseries.id,
                  view: "value",
                })),
              },
            };
            onChangeTechnicalSchema({ sensors, image });
          }
        } else {
          const sensors: Record<string, SensorList> = {
            ...technicalSchema.sensors,
            [showSettingFor.id]: {
              ...technicalSchema.sensors[showSettingFor.id],
              timeseriesInfo: timeseriesList.map((timeseries) => ({
                id: timeseries.id,
                view: "value",
              })),
            },
          };
          onChangeTechnicalSchema({ sensors });
        }
      }
    }
  }

  function handleSensorViewChange(value: SensorInfo) {
    if (showDetailsFor) {
      const sensors = produce(technicalSchema.sensors || {}, (draft) => {
        const sensor = draft[showDetailsFor.key];
        const existingIndex = sensor.timeseriesInfo.findIndex(
          (ts) => ts.id === value.id,
        );

        if (existingIndex !== -1) {
          sensor.timeseriesInfo[existingIndex] = value;
        } else {
          sensor.timeseriesInfo.push(value);
        }
      });
      onChangeTechnicalSchema({ sensors });
      setShowDetailsFor({
        timeseriesInfo: value,
        key: showDetailsFor.key,
      });
    }
  }

  const timeseriesIds: number[] = overlays
    ? overlays
        .filter(({ timeseriesInfo }) => timeseriesInfo?.length > 0)
        .flatMap(({ timeseriesInfo }) =>
          timeseriesInfo.map(
            (tsInfo: { id: number; value?: string }) => tsInfo.id,
          ),
        )
    : [];

  // check for TR timeseries
  useEffect(() => {
    const checkSensors = async (sensors: SensorList[]) => {
      const ids = [
        ...new Set(
          sensors.flatMap((sensor) =>
            sensor.timeseriesInfo.map((info) => info.id),
          ),
        ),
      ];

      if (ids.length) {
        const timeseries = await client.timeseries.retrieve(
          ids.map((id) => ({ id })),
          { ignoreUnknownIds: true },
        );
        setTrTimeseries(
          timeseries.filter((ts) => ts.externalId!.startsWith("TR")),
        );
      }

      setTrTimeseries([]);
    };
    checkSensors(Object.values(technicalSchema.sensors));
  }, [client, technicalSchema.sensors]);

  const handleUpdateTrSensors = async () => {
    const tsTimeseries = await client.timeseries.retrieve(
      trTimeseries.map((ts) => ({
        externalId: ts.externalId!.replace(/^TR_/, "TS_"),
      })),
    );
    const map = trTimeseries.reduce<Record<string, Timeseries>>(
      (acc, tr) => ({
        ...acc,
        [tr.id]: tsTimeseries.find(
          (ts) => ts.externalId === tr.externalId!.replace(/^TR_/, "TS_"),
        )!,
      }),
      {},
    );
    // loop trought the sensors and update the timeseries where needed
    const sensors = Object.entries(technicalSchema.sensors).reduce(
      (acc, [key, sensor]) => ({
        ...acc,
        [key]: {
          timeseriesInfo: sensor.timeseriesInfo.map((tsInfo) => {
            const ts = map[tsInfo.id];
            if (ts) {
              return {
                ...tsInfo,
                id: ts.id,
                unit: ts.unit,
              };
            }
            return tsInfo;
          }),
        },
      }),
      {},
    );

    await onChangeTechnicalSchema({ sensors });
    setTrTimeseries([]);
    navigate(".");
  };

  const { data: src } = useCogniteImage(technicalSchema.cogniteFileId);

  const categoryOptions = useSensorCategoryOptions();

  async function removeTimeseriesIdsFromSchemaMissingInCDF(
    missingIds: { id: number }[],
  ) {
    const selectedSensors = technicalSchema?.selectedSensors || [];
    const newSelectedSensors = selectedSensors?.filter(
      (sensor) => !missingIds.some(({ id }) => id === sensor?.timeseries),
    );

    const newSensors = Object.entries(technicalSchema.sensors).reduce(
      (acc, [key, value]) => {
        const { timeseriesInfo } = value;
        const newTimeseriesInfo = timeseriesInfo?.filter(
          (ts) => !missingIds.some(({ id }) => id === ts.id),
        );

        if (newTimeseriesInfo?.length > 0) {
          acc[key] = { timeseriesInfo: newTimeseriesInfo };
        }
        return acc;
      },
      {} as { [key: string]: { timeseriesInfo: SensorInfo[] } },
    );

    await onChangeTechnicalSchema({
      sensors: newSensors,
      selectedSensors: newSelectedSensors,
    });
  }

  return (
    <>
      {trTimeseries.length > 0 && (
        <Alert
          onClick={handleUpdateTrSensors}
          className="cursor-pointer"
          message={t.rich("floor-plan.tr-timeseries-alert.message", {
            p: (text) => <p>{text}</p>,
          })}
          type="error"
        />
      )}
      {notFoundTimeseriesList.length > 0 && (
        <Alert
          message={`
                ${t("floor-plan.timeseries-not-found.message", {
                  id: notFoundTimeseriesList
                    .map((ts) => `${ts.id} (${ts.key})`)
                    .join(", "),
                })}`}
          description={
            <div>
              <p>{t("floor-plan.timeseries-not-found.description")}</p>
              <div className="mb-0 flex">
                <Button
                  size="small"
                  onClick={() =>
                    removeTimeseriesIdsFromSchemaMissingInCDF(
                      notFoundTimeseriesList,
                    )
                  }
                  danger
                  ghost
                >
                  {t("floor-plan.timeseries-not-found.action")}
                </Button>
              </div>
            </div>
          }
          type="warning"
          showIcon
          closable
        />
      )}
      <div ref={drop}>
        <SchemaViewPanZoom
          type={type === "technicalSchema" ? "technicalSchema" : "floorPlan"}
          isEditing={edit}
          disableZoom={disableZoom}
          schema={technicalSchema}
          snapshotId={snapshotId}
        >
          <Main
            className={edit ? "edit" : "view"}
            style={{ position: "relative" }}
            ref={container}
            data-testid="technical-schema-container"
          >
            {image && (
              <SpinnerWithDelay
                isLoading={src === undefined || !image.outerHTML}
              >
                <Container
                  style={{
                    display: "grid",
                  }}
                >
                  <img
                    data-testid="technical-schema-image"
                    src={src}
                    alt={""}
                    width={"100%"}
                    height={"auto"}
                    style={{ gridArea: "1 / 1" }}
                  />
                  <div
                    style={{ gridArea: "1 / 1" }}
                    dangerouslySetInnerHTML={{ __html: image.outerHTML }}
                  />
                </Container>
              </SpinnerWithDelay>
            )}
            <ProperateApiProvider>
              {overlays?.map((overlay) => (
                <Overlay
                  key={overlay.id}
                  setPoint={overlay.id.startsWith("set-point")}
                  highlight={overlay.id === hover}
                  view={!edit}
                  draggable={
                    edit && typeof technicalSchema.cogniteFileId === "number"
                  }
                  openSettings={(max?: number) => {
                    setShowSettingFor({
                      timeseriesInfo:
                        (technicalSchema.sensors[overlay.key] &&
                          technicalSchema.sensors[overlay.key]
                            .timeseriesInfo) ||
                        [],
                      type: overlay.type,
                      id: overlay.key,
                      max,
                      removable:
                        typeof technicalSchema.cogniteFileId === "number",
                    });
                  }}
                  openSetPointSettings={(setPointStatus: SetPointStatusJs) =>
                    setShowSetPointSettingFor({
                      timeseriesInfo:
                        (technicalSchema.sensors[overlay.key] &&
                          technicalSchema.sensors[overlay.key]
                            .timeseriesInfo) ||
                        [],
                      setPointStatus,
                      type: overlay.type,
                      id: overlay.key,
                      max: 1,
                      removable:
                        typeof technicalSchema.cogniteFileId === "number",
                    })
                  }
                  openGraph={(ts: number) =>
                    setShowDetailsFor({
                      timeseriesInfo: overlay.timeseriesInfo.find(
                        (ti: any) => ti.id === ts,
                      ),
                      key: overlay.key,
                    })
                  }
                  type={overlay.type}
                  id={overlay.id}
                  x={overlay.x}
                  y={overlay.y}
                  timeseriesInfo={overlay.timeseriesInfo}
                  position={overlay.position}
                  mouseEnter={() => {
                    const el = document.getElementById(overlay.key);
                    if (el) {
                      el.classList.add("hover");
                    } else {
                      console.error(`${overlay.key} not found`);
                    }
                  }}
                  editable={editableOverlays.includes(overlay.key)}
                  mouseLeave={() => {
                    const el = document.getElementById(overlay.key);
                    if (el) {
                      el.classList.remove("hover");
                    } else {
                      console.error(`${overlay.key} not found`);
                    }
                  }}
                  notFoundTimeseriesList={notFoundTimeseriesList}
                />
              ))}
              <NotesSidebar
                noteSource={
                  type === "floorPlan"
                    ? NoteSource.WEB_FLOOR_PLAN
                    : NoteSource.WEB_SCHEMA_VIEW
                }
                assetFilterMode={NotesAssetFilterMode.TimeseriesList}
                idSet={new Set(timeseriesIds)}
                buildings={[
                  { id: building.dataSetId as number, name: building.name },
                ]}
              />
            </ProperateApiProvider>
            {showRoomInfoFor && (
              <RoomInfo
                {...showRoomInfoFor}
                hide={() => setShowRoomInfoFor(undefined)}
                openGraph={async (ts: number) => {
                  const timeseriesInfo = Object.keys(technicalSchema.sensors)
                    .map((key) => technicalSchema.sensors[key].timeseriesInfo)
                    .flat()
                    .find((ti: any) => ti.id === ts);
                  if (timeseriesInfo) {
                    const key = Object.keys(technicalSchema.sensors).find(
                      (key) =>
                        technicalSchema.sensors[key].timeseriesInfo.find(
                          (ti: any) => ti.id === ts,
                        ),
                    )!;
                    setShowDetailsFor({
                      timeseriesInfo: timeseriesInfo,
                      key,
                    });
                  } else {
                    await onChangeTechnicalSchema({
                      ...technicalSchema,
                      sensors: {
                        ...technicalSchema.sensors,
                        other: {
                          timeseriesInfo: [
                            ...(
                              technicalSchema.sensors.other || {
                                timeseriesInfo: [],
                              }
                            ).timeseriesInfo,
                            { id: ts },
                          ],
                        },
                      },
                    });
                    setShowDetailsFor({
                      timeseriesInfo: { id: ts },
                      key: "other",
                    });
                  }
                }}
              />
            )}
            <TimeseriesSelectionModal
              open={showSettingFor !== undefined}
              onHide={() => setShowSettingFor(undefined)}
              selectedIds={
                showSettingFor
                  ? showSettingFor.timeseriesInfo.map(({ id }) => id)
                  : []
              }
              categoryOptions={categoryOptions}
              hiddenFilters={["building"]}
              max={showSettingFor?.max}
              initialFilters={{
                ...(typeof technicalSchema.subBuilding === "string"
                  ? { subBuilding: technicalSchema.subBuilding }
                  : null),
                ...(typeof technicalSchema.system === "string"
                  ? { system: technicalSchema.system }
                  : null),
                ...(showSettingFor !== undefined
                  ? { category: showSettingFor.type }
                  : null),
              }}
              onOk={handleOK}
            />
            {showDetailsFor && (
              <GraphModal
                timeseriesInfo={showDetailsFor.timeseriesInfo}
                setTimeseriesInfo={handleSensorViewChange}
                deleteTimeseries={() => {
                  const sensors = produce(
                    technicalSchema.sensors || {},
                    (draft) => {
                      draft[showDetailsFor.key].timeseriesInfo = draft[
                        showDetailsFor.key
                      ].timeseriesInfo.filter(
                        (ts) => ts.id !== showDetailsFor.timeseriesInfo.id,
                      );
                      if (
                        draft[showDetailsFor.key].timeseriesInfo.length === 0
                      ) {
                        delete draft[showDetailsFor.key];
                      }
                    },
                  );
                  onChangeTechnicalSchema({
                    sensors,
                    selectedSensors: (
                      technicalSchema.selectedSensors ?? []
                    ).filter(
                      (sensor) =>
                        !(
                          sensor.timeseries ===
                            showDetailsFor.timeseriesInfo.id &&
                          sensor.id === showDetailsFor.key
                        ),
                    ),
                  });
                  setShowDetailsFor(undefined);
                }}
                showAlerts
                showSetPoints
                hide={() => setShowDetailsFor(undefined)}
                showSettings={() => {
                  const overlay = overlays?.find(
                    (overlay) => overlay.key === showDetailsFor.key,
                  );
                  setShowDetailsFor(undefined);
                  setShowSettingFor({
                    timeseriesInfo:
                      (technicalSchema.sensors &&
                        technicalSchema.sensors[overlay.key] &&
                        technicalSchema.sensors[overlay.key].timeseriesInfo) ||
                      [],
                    type: overlay.type,
                    id: overlay.key,
                  });
                }}
                openRoomInfo={(id) => {
                  setShowDetailsFor(undefined);
                  setShowRoomInfoFor({ id, sensors: technicalSchema.sensors });
                }}
                showDocuments
                buildingId={technicalSchema.rootAssetId}
              />
            )}
            {showSetPointSettingFor && (
              <SetPointSettings
                open
                onHide={() => setShowSetPointSettingFor(undefined)}
                timeseriesInfo={showSetPointSettingFor.timeseriesInfo[0]}
                setPriority={async (externalId: string, priority: number) => {
                  try {
                    await updateSetPoint({
                      external_id: externalId,
                      audit_source: "web/technicalSchema",
                      priority: priority,
                      value: showSetPointSettingFor.setPointStatus.value,
                    });
                    await mutate(`setpoint-status-${externalId}`, {
                      ...showSetPointSettingFor.setPointStatus,
                      priority,
                    });
                  } catch (error) {
                    console.error(error);
                    const errorMessage = parseError(error);
                    message.error({
                      type: "error",
                      content: t("floor-plan.error-updating-set-point", {
                        errorMessage,
                      }),
                      duration: 7,
                    });
                  }
                }}
                removeTimeseries={() => {
                  const sensors = produce(
                    technicalSchema.sensors || {},
                    (draft) => {
                      delete draft[showSetPointSettingFor.id];
                    },
                  );
                  onChangeTechnicalSchema({ sensors });
                  setShowSetPointSettingFor(undefined);
                }}
                setPointStatus={showSetPointSettingFor.setPointStatus}
                showSettings={() => {
                  setShowSettingFor(showSetPointSettingFor);
                  setShowSetPointSettingFor(undefined);
                }}
              />
            )}
          </Main>
        </SchemaViewPanZoom>
      </div>
    </>
  );
};
